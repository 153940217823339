import React from 'react'
import "./Home.css"
import DRGLogo from 'assets/images/DRGLogo.png'
import MokupImg from 'assets/images/home-decor-1.jpg'
import Ahura from 'assets/images/At-Logo.png'
import { Link, NavLink } from 'react-router-dom'
import Footer from 'examples/Footer'
import HomeLayout from 'layouts/authentication/components/HomeLayout'
import { isLogin } from 'Token'
import { Modal, Row, Text } from '@nextui-org/react'
import { Button } from '@material-ui/core'
import mailImg from 'assets/images/mail-dv.png'
import phoneImg from 'assets/images/Phone.png'

function Home() {
    const trustLogin = isLogin()

    // Edit trust Password model Variables
    const [visibleSupport, setVisibleSupport] = React.useState(false);
    const handlerSupport = () => {
        setVisibleSupport(true)
    };
    const closeHandlerSupport = () => {
        setVisibleSupport(false)
    };

    return (
        <HomeLayout >
            <div className='homePage'>
                <div className='page-container'>
                    <div className='home'>
                        <div className='right mokup'>
                            <img src={DRGLogo} className='logo' width={"80%"} alt="" />
                        </div>
                        <div className='left'>
                            <h1>Streamline Your Donations, Empower Lives</h1>
                            <p>Welcome to Trust Panel, a seamless platform designed to simplify your donation management process. With our user-friendly interface, you can easily log in, track donations, and generate professional receipts. Experience efficiency and make a greater impact with our intuitive tools.</p>
                            <div className='storeLinks'>
                                {trustLogin.trustLogin ?
                                    <NavLink className="common-btn" to="/dashboard">Go to Dashboard</NavLink> :
                                    <NavLink className="common-btn" to="/trust/login">Login</NavLink>}
                                <NavLink className="common-btn" onClick={handlerSupport} to="#">Contact / Support</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className='contact-details'>
                        <div className="page-container">
                            <Footer AtLogo={Ahura} />
                        </div>
                    </div>
                </div>
            </div>
            {/* Chamge Trust Password Model */}
            <Modal closeButton blur aria-labelledby="modal-title" className="AddDataModel" width={600} open={visibleSupport} onClose={closeHandlerSupport} >
                <Modal.Header justify="start">
                    <Text id="modal-title" size={24}>
                        <Text b size={24}> COntact / Support </Text>
                    </Text>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <div className="supportPage home">
                            <p>If you have any doubts about the panel, or change your Trust Login password. Additionally, if you find any bugs in the panel, please Contact us.</p>
                            <div className="contact-info">
                                <div className='supportblock'>
                                    <div className='supportItem'>
                                        <h1>
                                            <span className="info-title">
                                                <img alt="" src={phoneImg} className="info-icon" />&nbsp;<Link to="tel:+918141832384">+91 81418 32384</Link>
                                            </span>
                                        </h1>
                                    </div>
                                    <div className='supportItem'>
                                        <h1>
                                            <span className="info-title">
                                                <img alt="" src={mailImg} className="info-icon" />&nbsp;<Link to="mailto:kartikeybshah@gmail.com">kartikeybshah@gmail.com</Link>
                                            </span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button auto className="common-btn light" color="error" onClick={closeHandlerSupport}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </HomeLayout>
    )
}

export default Home