// @mui material components
import Grid from "@mui/material/Grid";

// Donation Receipt Generator React components
import MDBox from "components/MDBox";

// Donation Receipt Generator React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { getTrustList } from "Api";
import { userToken } from "Token";
import { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, FormControlLabel, IconButton } from "@mui/material";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaReceipt, FaUserEdit } from "react-icons/fa";
import { TbCoinRupee } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import { AiFillEye, AiOutlineOrderedList } from "react-icons/ai";
import { DataGrid } from "@mui/x-data-grid";
import { CiExport } from "react-icons/ci";
import { getTrustDetailsPatch } from "Api";
import { getTrustDonationList } from "Api";
import { formatDateForAddDonation } from "layouts/AdminTrustList/Errors/Errors";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;

  const columns = [
    { field: 'srno', headerName: 'ID', width: 90 },
    { field: 'DonorName', headerName: 'Donor name', width: 250, editable: false, },
    { field: 'DonationAmount', headerName: 'Amount', width: 200, editable: false, },
    { field: 'DateofDonation', headerName: 'Date of donation', width: 250, editable: false, },
    { field: 'formattedDate', headerName: 'Created At', width: 250, editable: false, },
    { field: 'DonationType', headerName: 'Donation Type', width: 200, editable: false, },
    { field: 'PaymentType', headerName: 'Payment Type', width: 200, editable: false, },
    // {
    //   field: 'action', headerName: 'Action', with: 150, editable: false,
    //   renderCell: (prevent) => {
    //     return (
    //       <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }} >
    //         <MatEdit index={prevent.row?._id} />
    //         <MatDelete index={prevent.row?._id} />
    //       </div>
    //     );
    //   },
    // }
  ];

  const token = userToken()
  const [trustList, setTrustList] = useState([])
  const [totalDonations, setTotalDonations] = useState("")

  // Get Trust Details
  const [trustDetail, setTrustDetail] = useState({})
  const [ArchiveId, setArchiveId] = useState("")
  const [fetched, setFetched] = useState(false)
  const fetchTrustDetails = () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.patch(getTrustDetailsPatch)
      .then((res) => {
        setFetched(true)
        setTrustDetail(res.data.user)
        setArchiveId(res.data.archiveId)
      })
  }
  useEffect(() => {
    if(token !== "" && token !== undefined) {
      fetchTrustDetails()
    }
  }, [token])

  // Handle Donation Type
  const donationTypes = [
    { value: 1, label: 'Specific grant' },
    { value: 2, label: 'Corpus' },
    { value: 3, label: 'Others' },
  ]

  // handle payment type
  const transactionTypes = [
    { value: 1, label: 'Cash' },
    { value: 2, label: 'Kind' },
    { value: 3, label: 'Cheque' },
    { value: 4, label: 'Online transaction' },
    { value: 5, label: 'Others' },
  ]

  // Get All Donation List
  const [donationList, setDonationList] = useState([])
  const [totalDonationAmount, setTotalDonationAmount] = useState("")
  const [LastMonthDonations, setLastMonthDonations] = useState("")
  const fetchdonationList = () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.get(`${getTrustDonationList}/${trustDetail?._id}?ArchiveId=${ArchiveId}`)
      .then((res) => {
        setTotalDonations(res.data.result.totalDocs)
        setTotalDonationAmount(res.data.result.totolDonation)
        setLastMonthDonations(res.data.result.totalAmountLast30Days)
        res.data.result.docs.map((ele, i) => {
          ele.id = i + 1;
          ele.srno = (res.data.result.page * 10) + (i + 1) - 10;
          const donationType = donationTypes.find((item) => {
            if (typeof ele.TypeDonation == "object") {
              return item.value == ele.TypeDonation.DonationType
            } else {
              return item.value == ele.TypeDonation
            }
          })
          ele.DonationType = donationType?.label

          const transactionType = transactionTypes.find((item) => {
            return item.value == ele.TransactionType
          })
          ele.PaymentType = transactionType?.label
          ele.formattedDate = formatDateForAddDonation(ele.createdAt);

        })
        setDonationList(res.data.result.docs)
      })
  }
  useEffect(() => {
    if (trustDetail?._id != undefined) {
      fetchdonationList()
    }
  }, [trustDetail])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={4}>
            <MDBox mb={1.5} className="font-xl">
              <ComplexStatisticsCard
                color="dark"
                icon={<FaReceipt />}
                title="Total Donations"
                count={totalDonations ? totalDonations : 0}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <MDBox mb={1.5} className="font-xl">
              <ComplexStatisticsCard
                color="dark"
                icon={<TbCoinRupee />}
                title="Total Donation Amount"
                count={totalDonationAmount ? totalDonationAmount : 0}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <MDBox mb={1.5} className="font-xl">
              <ComplexStatisticsCard
                color="dark"
                icon="leaderboard"
                title="Total Donation In last 30 Days"
                count={LastMonthDonations ? LastMonthDonations : 0}
              />
            </MDBox>
          </Grid>
        </Grid>

        <MDBox mb={3} >
          <div className="title_text roles-header dashboard-tital">
            <h4>Recent Trust</h4>
            <div className="button-for-trust-actions">
              <NavLink to="/donation-list" variant="contained" className="fillter_btn common-btn" startIcon={<AiOutlineOrderedList />} >
                View All
              </NavLink>
            </div>
          </div>
          <Box sx={{ height: 580, width: '100%', }} >
            <DataGrid
              rows={donationList}
              columns={columns}
              pageSize={10}
            />
          </Box>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
