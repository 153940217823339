export const isValidPAN = (panNumber) => {
    if (typeof panNumber !== 'string') {
        return false;
    }

    panNumber = panNumber.trim().toUpperCase();

    if (panNumber.length !== 10) {
        return false;
    }

    var panRegex = /^([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}$/;
    if (!panRegex.test(panNumber)) {
        return false;
    }

    return true;
};



export const isValidGST = (gstNumber) => {
    gstNumber = gstNumber.replace(/\s/g, '');

    if (gstNumber.length !== 15) {
        return false;
    }

    var gstRegex = /^([0-9]){2}([A-Z]){5}([0-9]){4}([A-Z]){1}([0-9A-Z]){3}$/;
    if (!gstRegex.test(gstNumber)) {
        return false;
    }

    return true;
};


export const isValidURN = (urnNumber) => {
    if (typeof urnNumber !== 'string') {
        return false;
    }

    urnNumber = urnNumber.trim().toLowerCase();

    if (urnNumber.length !== 16) {
        return false;
    }

    var urnRegex = /^[0-9a-z]{16}$/;
    if (!urnRegex.test(urnNumber)) {
        return false;
    }

    return true;
};




export const isValidAadhaar = (aadhaarNumber) => {
    aadhaarNumber = aadhaarNumber.replace(/\s/g, '');

    if (aadhaarNumber.length !== 12) {
        return false;
    }

    var aadhaarRegex = /^[0-9]{12}$/;
    if (!aadhaarRegex.test(aadhaarNumber)) {
        return false;
    }

    return true;
};


export const isValidTIN = (tinNumber) => {
    tinNumber = tinNumber.replace(/\s/g, '');

    if (tinNumber.length !== 11) {
        return false;
    }

    var tinRegex = /^\d{11}$/;
    if (!tinRegex.test(tinNumber)) {
        return false;
    }

    return true;
};


export const isValidPassport = (passportNumber) => {
    passportNumber = passportNumber.replace(/\s/g, '');

    if (passportNumber.length < 6 || passportNumber.length > 10) {
        return false;
    }

    var passportRegex = /^[A-Z0-9]+$/;
    if (!passportRegex.test(passportNumber)) {
        return false;
    }

    return true;
};


// export const isValidElectorID = (electorID) => {
//     electorID = electorID.replace(/\s/g, '');

//     if (electorID.length !== 10) {
//         return false;
//     }

//     var electorIDRegex = /^[a-zA-Z]{3}\d{7}$/;
//     if (!electorIDRegex.test(electorID)) {
//         return false;
//     }

//     return true;
// };

export const isValidElectorID = (electorID) => {
    const cleanedElectorID = electorID.replace(/\s/g, '').toUpperCase();
    return cleanedElectorID.length === 10 && /^[A-Z]{3}\d{7}$/.test(cleanedElectorID);
};


export const isValidDrivingLicense = (drivingLicenseNumber) => {
    drivingLicenseNumber = drivingLicenseNumber.replace(/\s/g, '');

    if (drivingLicenseNumber.length < 6 || drivingLicenseNumber.length > 20) {
        return false;
    }

    var drivingLicenseRegex = /^[A-Z0-9]+$/;
    if (!drivingLicenseRegex.test(drivingLicenseNumber)) {
        return false;
    }

    return true;
};


export const isValidRationCard = (rationCardNumber) => {
    rationCardNumber = rationCardNumber.replace(/\s/g, '');

    if (rationCardNumber.length < 8 || rationCardNumber.length > 15) {
        return false;
    }

    var rationCardRegex = /^[A-Z0-9]+$/;
    if (!rationCardRegex.test(rationCardNumber)) {
        return false;
    }

    return true;
};


export const convertEditDateFormat = (dateStr) => {
    const parts = dateStr.split('/');
    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    return formattedDate;
}

export const newformatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
}


// // PAN (Permanent Account Number) - India
// export const isValidPAN = (panNumber) => {
//     const cleanedPanNumber = panNumber.trim().toUpperCase();
//     return cleanedPanNumber.length === 10 && /^([A-Z]){5}\d{4}[A-Z]$/.test(cleanedPanNumber);
// };

// // GST (Goods and Services Tax) - India
// export const isValidGST = (gstNumber) => {
//     const cleanedGstNumber = gstNumber.replace(/\s/g, '').toUpperCase();
//     return cleanedGstNumber.length === 15 && /^\d{2}[A-Z]{5}\d{4}[A-Z]\d{1}[Z][A-Z\d]{2}$/.test(cleanedGstNumber);
// };

// // URN (Universal Resource Name) - Global
// export const isValidURN = (urn) => {
//     const cleanedUrn = urn.trim();
//     return /^[a-z]{1,32}:[a-z0-9()+,\\.:=@;$_!*'%/?#-]{1,255}$/.test(cleanedUrn);
// };

// // Aadhaar - India
// export const isValidAadhaar = (aadhaarNumber) => {
//     const cleanedAadhaarNumber = aadhaarNumber.replace(/\s/g, '');
//     return cleanedAadhaarNumber.length === 12 && /^\d{12}$/.test(cleanedAadhaarNumber);
// };

// // TIN (Tax Identification Number) - Varies Globally
// export const isValidTIN = (tinNumber, countryCode) => {
//     const cleanedTinNumber = tinNumber.replace(/\s/g, '');

//     // Basic length check
//     if (cleanedTinNumber.length < 8 || cleanedTinNumber.length > 14) return false;

//     // Country-specific validation (add more as needed)
//     switch (countryCode) {
//         case "IN": // India
//             return /^\d{11}$/.test(cleanedTinNumber);
//         case "US": // USA
//             return /^\d{9}$/.test(cleanedTinNumber);
//         // ... add more cases for other countries
//         default:
//             return true; // No specific validation available
//     }
// };

// // Passport - Global
// export const isValidPassport = (passportNumber) => {
//     const cleanedPassportNumber = passportNumber.replace(/\s/g, '');
//     return /^[A-Z0-9]{6,10}$/.test(cleanedPassportNumber);
// };

// // Elector ID (Voter ID) - India
// export const isValidElectorID = (electorID) => {
//     const cleanedElectorID = electorID.replace(/\s/g, '').toUpperCase();
//     return cleanedElectorID.length === 10 && /^[A-Z]{3}\d{7}$/.test(cleanedElectorID);
// };

// // Driving License - Varies Globally (add country-specific validation)
// export const isValidDrivingLicense = (licenseNumber, countryCode) => {
//     const cleanedLicenseNumber = licenseNumber.replace(/\s/g, '');

//     // Basic length check (adjust as needed)
//     if (cleanedLicenseNumber.length < 6 || cleanedLicenseNumber.length > 20) return false;

//     // Country-specific validation (add more as needed)
//     switch (countryCode) {
//         case "IN":
//             return /^[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/.test(cleanedLicenseNumber);
//         // ... add more cases for other countries
//         default:
//             return /^[A-Z0-9]+$/.test(cleanedLicenseNumber); // Basic alphanumeric check
//     }
// };

// // Ration Card - India
// export const isValidRationCard = (rationCardNumber) => {
//     const cleanedRationCardNumber = rationCardNumber.replace(/\s/g, '').toUpperCase();
//     return cleanedRationCardNumber.length >= 8 && cleanedRationCardNumber.length <= 15 && /^[A-Z0-9]+$/.test(cleanedRationCardNumber);
// };
