export const userToken = () => {
    let token = '';

    const localStorageType = localStorage.getItem('type');
    const sessionStorageType = sessionStorage.getItem('type');

    if (localStorageType === 'ADMIN') {
        token = localStorage.getItem('adminToken');
    } else if (sessionStorageType === 'ADMIN') {
        token = sessionStorage.getItem('adminToken');
    } else if (localStorageType === 'TRUST') {
        token = localStorage.getItem('trustToken');
    } else if (sessionStorageType === 'TRUST') {
        token = sessionStorage.getItem('trustToken');
    }

    return token || '';
};

export const isLogin = () => {
    const localStorageAdminToken = localStorage.getItem('adminToken');
    const sessionStorageAdminToken = sessionStorage.getItem('adminToken');
    const localStorageTrustToken = localStorage.getItem('trustToken');
    const sessionStorageTrustToken = sessionStorage.getItem('trustToken');

    const adminLogin = Boolean(localStorageAdminToken || sessionStorageAdminToken);
    const trustLogin = Boolean(localStorageTrustToken || sessionStorageTrustToken);

    return {
        adminLogin: adminLogin,
        trustLogin: trustLogin
    };
};


export const getUserType = () => {
    let userType = '';

    const localStorageType = localStorage.getItem('type');
    const sessionStorageType = sessionStorage.getItem('type');

    if (localStorageType) {
        userType = localStorageType;
    } else if (sessionStorageType) {
        userType = sessionStorageType;
    }

    return userType;
};

export const getAdminEmail = () => {
    let adminEmail = '';

    const localStorageType = localStorage.getItem('adminEmail');
    const sessionStorageType = sessionStorage.getItem('adminEmail');

    if (localStorageType) {
        adminEmail = localStorageType;
    } else if (sessionStorageType) {
        adminEmail = sessionStorageType;
    }

    return adminEmail;
};

export const getAdminarchiveId = () => {
    let archiveId = '';

    const localStorageType = localStorage.getItem('archiveId');
    const sessionStorageType = sessionStorage.getItem('archiveId');

    if (localStorageType) {
        archiveId = localStorageType;
    } else if (sessionStorageType) {
        archiveId = sessionStorageType;
    }

    return archiveId;
};


export const isValidEmailAddress = (emailAddress) => {
    emailAddress = emailAddress.trim();
    var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(emailAddress);
};