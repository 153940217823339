import { getTrustDetailsPatch } from 'Api';
import axios from 'axios';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function TrustAuth({ page }) {
    const navigate = useNavigate()

    let token;
    if (localStorage.getItem("trustToken") != null) {
        token = localStorage.getItem('trustToken');
    } else if (sessionStorage.getItem('trustToken') != null) {
        token = sessionStorage.getItem('trustToken')
    }

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        axios.patch(getTrustDetailsPatch)
            .then((res) => {

            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    sessionStorage.clear();
                    navigate("/trust/login");
                }
            });
    }, [token])

    return token ? page : navigate('/trust/login');
}

export default TrustAuth