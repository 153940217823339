import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Donation Receipt Generator React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";


import { getAdminLogin } from "Api";
import { Crypt } from 'hybrid-crypto-js';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getTrustLogin } from "Api";
import Ahura from 'assets/images/At-Logo.png'
import Footer from "examples/Footer";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}


function Basic() {
  var crypt = new Crypt();
  const navigate = useNavigate()

  // Variables
  const [rememberMe, setRememberMe] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [loginInfo, setLoginInfo] = useState({
    Email: "",
    Password: ""
  })


  // Get Login Data
  const handleLoginData = (e) => {
    setLoginInfo({
      ...loginInfo,
      [e.target.name]: e.target.value
    })
  }

  // Show Hide PAssword
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Login API
  const handleLogin = (e) => {

    let encryptedPassword = crypt.encrypt(process.env.REACT_APP_KEY, loginInfo.Password);

    const loginData = new FormData();
    loginData.append('Email', loginInfo.Email);
    loginData.append('Password', encryptedPassword);

    axios.post(getTrustLogin, loginData)
      .then((res) => {
        if (rememberMe) {
          localStorage.setItem("trustToken", res.data.token);
          localStorage.setItem("type", res.data.type);
        } else {
          sessionStorage.setItem("trustToken", res.data.token);
          sessionStorage.setItem("type", res.data.type);
        }

        if (localStorage.getItem("adminToken")) {
          localStorage.removeItem("adminToken");
        } else if (sessionStorage.getItem("adminToken")) {
          sessionStorage.removeItem("adminToken");
        }
        navigate("/dashboard")
        toast.success(res.data.message, toastStyle);
      })
      .catch((err) => {
        toast.error(err.response.data.message, toastStyle);
      })
  }

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white">
            Trust Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="email" label="Email" fullWidth name="Email" onChange={(e) => handleLoginData(e)} />
            </MDBox>
            <MDBox mb={2} className="passBox">
              <MDInput type={showPassword ? "text" : "password"} label="Password" fullWidth name="Password" onChange={(e) => handleLoginData(e)} />
              <MDButton variant="outline-secondary" className="hideButton" onClick={toggleShowPassword}>{!showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}</MDButton>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={() => handleLogin()}>
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
          <div className='contact-details'>
            <div className="page-container">
              <Footer AtLogo={Ahura} />
            </div>
          </div>
        </MDBox>
      </Card>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </BasicLayout>
  );
}

export default Basic;
