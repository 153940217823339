import { isValidAadhaar, isValidDrivingLicense, isValidElectorID, isValidPAN, isValidPassport, isValidRationCard, isValidTIN } from "./Validation";

export const AddDonationErrors = (data, step) => {

    let stepForm1Error = {
        DateOfDonationError: "",
        DonationToError: "",
        receiptNoError: "",
        DonationTypeError: "",
        DonationNoteError: "",
        DonationAmountError: "",
        DonationAmountInWordError: ""
    }
    let stepForm2Error = {
        DonorNameError: "",
        DonorMobileError: "",
        DonorAddressError: "",
        DonorPincodeError: "",
        DonorIdTypeError: "",
        DonorIdNoError: ""
    }
    let stepForm3Error = {
        TransactionTypeError: "",
        BankNameError: "",
        ChequeNoError: "",
        ChequeDateError: "",
        KindTextError: "",
        OnlineTransactionTypeError: "",
        TransactionIdError: "",
        OthersPayTextError: "",
        DemandDraftNoError: "",
        DemandDraftDateError: "",
    }

    if (step === 2) {
        if (data.DateOfDonation === "") {
            stepForm1Error.DateOfDonationError = "Please Enter Date Of Donation";
        } else {
            stepForm1Error.DateOfDonationError = "";
        }

        if (data.DonationTo === "") {
            stepForm1Error.DonationToError = "Please Enter Donation To";
        } else {
            stepForm1Error.DonationToError = "";
        }

        if (data.receiptNo === "") {
            stepForm1Error.receiptNoError = "Please Enter Donation Receipt Generator No";
        } else {
            stepForm1Error.receiptNoError = "";
        }

        if (data.DonationType === "") {
            console.log(data.DonationType);
            stepForm1Error.DonationTypeError = "Please Select Donation Type";
        } else {
            stepForm1Error.DonationTypeError = "";
        }

        // if (data.DonationType == 3) {
        //     if (data.DonationNote === "") {
        //         stepForm1Error.DonationNoteError = "Please Enter Donation Note";
        //     } else {
        //         stepForm1Error.DonationNoteError = "";
        //     }
        // }

        if (data.DonationAmount === "") {
            stepForm1Error.DonationAmountError = "Please Enter Donation Amount";
        } else if (parseFloat(data.DonationAmount) < 0) {
            stepForm1Error.DonationAmountError = "Donation Amount cannot be negative";
        } else {
            stepForm1Error.DonationAmountError = "";
        }


        if (data.DonationAmountInWord === "") {
            stepForm1Error.DonationAmountInWordError = "Please Enter Donation Amount in words";
        } else {
            stepForm1Error.DonationAmountInWordError = "";
        }

        return stepForm1Error;
    }

    if (step === 1) {
        if (data.DonorName === "") {
            stepForm2Error.DonorNameError = "Please Enter Donor Name";
        } else {
            stepForm2Error.DonorNameError = "";
        }

        if (!data.DonorMobile) {
            stepForm2Error.DonorMobileError = "Please enter Donor Mobile number";
        } else if (data.DonorMobile.length !== 10 || !/^\d+$/.test(data.DonorMobile)) {
            stepForm2Error.DonorMobileError = "Please enter a valid 10-digit Donor Mobile number";
        } else {
            stepForm2Error.DonorMobileError = "";
        }

        if (data.DonorAddress === "") {
            stepForm2Error.DonorAddressError = "Please Select Donor Address";
        } else {
            stepForm2Error.DonorAddressError = "";
        }
        
        if (!data.DonorPincode) {
            stepForm2Error.DonorPincodeError = "Please enter Donor Pincode";
        } else if (data?.DonorPincode?.length !== 6 || !/^\d+$/.test(data.DonorPincode)) {
            stepForm2Error.DonorPincodeError = "Please enter a valid 6-digit Donor Pincode";
        } else {
            stepForm2Error.DonorPincodeError = "";
        }

        if (!data.DonorIdType) {
            stepForm2Error.DonorIdTypeError = "Please Select Donor Id Type";
        } else {
            stepForm2Error.DonorIdTypeError = "";
        }

        if (data.DonorIdNo === "") {
            stepForm2Error.DonorIdNoError = "Please Enter Donor Id No";
        } else {
            stepForm2Error.DonorIdNoError = "";
        }

        if (data.DonorIdType === "1") {
            if (data.DonorIdNo === "") {
                stepForm2Error.DonorIdNoError = "Please Enter Permanent Account Number (PAN)";
            } else if (!isValidPAN(data.DonorIdNo)) {
                stepForm2Error.DonorIdNoError = "Please Enter a valid Permanent Account Number (PAN)";
            } else {
                stepForm2Error.DonorIdNoError = "";
            }
        } else if (data.DonorIdType === "2") {
            if (data.DonorIdNo === "") {
                stepForm2Error.DonorIdNoError = "Please Enter Aadhaar Number";
            } else if (!isValidAadhaar(data.DonorIdNo)) {
                stepForm2Error.DonorIdNoError = "Please Enter a valid Aadhaar Number";
            } else {
                stepForm2Error.DonorIdNoError = "";
            }
        } else if (data.DonorIdType === "3") {
            if (data.DonorIdNo === "") {
                stepForm2Error.DonorIdNoError = "Please Enter Tax Identification Number (TIN)";
            } else if (!isValidTIN(data.DonorIdNo)) {
                stepForm2Error.DonorIdNoError = "Please Enter a valid Tax Identification Number (TIN)";
            } else {
                stepForm2Error.DonorIdNoError = "";
            }
        } else if (data.DonorIdType === "4") {
            if (data.DonorIdNo === "") {
                stepForm2Error.DonorIdNoError = "Please Enter Passport Number";
            } else if (!isValidPassport(data.DonorIdNo)) {
                stepForm2Error.DonorIdNoError = "Please Enter a valid Passport Number";
            } else {
                stepForm2Error.DonorIdNoError = "";
            }
        } else if (data.DonorIdType === "5") {
            if (data.DonorIdNo === "") {
                stepForm2Error.DonorIdNoError = "Please Enter Elector's Photo Identity Number";
            } else if (!isValidElectorID(data.DonorIdNo)) {
                stepForm2Error.DonorIdNoError = "Please Enter a valid Elector's Photo Identity Number";
            } else {
                stepForm2Error.DonorIdNoError = "";
            }
        } else if (data.DonorIdType === "6") {
            if (data.DonorIdNo === "") {
                stepForm2Error.DonorIdNoError = "Please Enter Driving License Number";
            } else if (!isValidDrivingLicense(data.DonorIdNo)) {
                stepForm2Error.DonorIdNoError = "Please Enter a valid Driving License Number";
            } else {
                stepForm2Error.DonorIdNoError = "";
            }
        } else if (data.DonorIdType === "7") {
            if (data.DonorIdNo === "") {
                stepForm2Error.DonorIdNoError = "Please Enter Ration Card Number";
            } else if (!isValidRationCard(data.DonorIdNo)) {
                stepForm2Error.DonorIdNoError = "Please Enter a valid Ration Card Number";
            } else {
                stepForm2Error.DonorIdNoError = "";
            }
        }

        return stepForm2Error;
    }

    if (step === 3) {
        if (!data.TransactionType) {
            stepForm3Error.TransactionTypeError = "Please Select Donation Payment Method"
        } else {
            stepForm3Error.TransactionTypeError = ""
            if (data.TransactionType === 3) {
                if (data.BankName === "") {
                    stepForm3Error.BankNameError = "Please Enter Bank Name";
                } else {
                    stepForm3Error.BankNameError = "";
                }

                if (data.ChequeNo === "") {
                    stepForm3Error.ChequeNoError = "Please Enter Cheque No.";
                } else {
                    stepForm3Error.ChequeNoError = "";
                }

                if (data.ChequeDate === "") {
                    stepForm3Error.ChequeDateError = "Please Enter Cheque Date";
                } else {
                    stepForm3Error.ChequeDateError = "";
                }
            } else if (data.TransactionType === 2) {
                if (data.KindText === "") {
                    stepForm3Error.KindTextError = "Please Enter Donation Kind";
                } else {
                    stepForm3Error.KindTextError = "";
                }
            } else if (data.TransactionType === 4) {
                if (data.OnlineTransactionType === "" || data.OnlineTransactionType === undefined) {
                    stepForm3Error.OnlineTransactionTypeError = "Please Select Transaction Type";
                } else {
                    stepForm3Error.OnlineTransactionTypeError = "";
                }

                if (data.TransactionId === "") {
                    stepForm3Error.TransactionIdError = "Please Enter Transaction ID";
                } else {
                    stepForm3Error.TransactionIdError = "";
                }
            } else if (data.TransactionType === 5) {
                if (data.OthersPayText === "") {
                    stepForm3Error.OthersPayTextError = "Please Enter Other Payment Method";
                } else {
                    stepForm3Error.OthersPayTextError = "";
                }
            } else if (data.TransactionType === 6) {
                if (data.BankName === "") {
                    stepForm3Error.BankNameError = "Please Enter Bank Name";
                } else {
                    stepForm3Error.BankNameError = "";
                }

                if (data.DemandDraftNo === "") {
                    stepForm3Error.DemandDraftNoError = "Please Enter Demand Draft No.";
                } else {
                    stepForm3Error.DemandDraftNoError = "";
                }

                if (data.DemandDraftDate === "") {
                    stepForm3Error.DemandDraftDateError = "Please Enter Demand Draft Date";
                } else {
                    stepForm3Error.DemandDraftDateError = "";
                }
            } else {
                stepForm3Error.BankNameError = "";
                stepForm3Error.ChequeNoError = "";
                stepForm3Error.ChequeDateError = "";
                stepForm3Error.DemandDraftNoError = "";
                stepForm3Error.DemandDraftDateError = "";
                stepForm3Error.KindTextError = "";
                stepForm3Error.OnlineTransactionTypeError = "";
                stepForm3Error.TransactionIdError = "";
                stepForm3Error.OthersPayTextError = "";
            }
        }

        return stepForm3Error
    }
}

export const EditDonationErrors = (data, step) => {

    let stepForm1ErrorEdit = {
        DateOfDonationError: "",
        DonationToError: "",
        receiptNoError: "",
        DonationTypeError: "",
        DonationNoteError: "",
        DonationAmountError: "",
        DonationAmountInWordError: ""
    }
    let stepForm2ErrorEdit = {
        DonorNameError: "",
        DonorMobileError: "",
        DonorAddressError: "",
        DonorPincodeError: "",
        DonorIdTypeError: "",
        DonorIdNoError: ""
    }
    let stepForm3ErrorEdit = {
        TransactionTypeError: "",
        BankNameError: "",
        ChequeNoError: "",
        ChequeDateError: "",
        KindTextError: "",
        OnlineTransactionTypeError: "",
        TransactionIdError: "",
        OthersPayTextError: "",
        DemandDraftNoError: "",
        DemandDraftDateError: ""
    }

    if (step === 2) {
        if (data.DateOfDonation === "") {
            stepForm1ErrorEdit.DateOfDonationError = "Please Enter Date Of Donation";
        } else {
            stepForm1ErrorEdit.DateOfDonationError = "";
        }

        if (data.DonationTo === "") {
            stepForm1ErrorEdit.DonationToError = "Please Enter Donation To";
        } else {
            stepForm1ErrorEdit.DonationToError = "";
        }

        if (data.receiptNo === "") {
            stepForm1ErrorEdit.receiptNoError = "Please Enter Donation Receipt No";
        } else {
            stepForm1ErrorEdit.receiptNoError = "";
        }

        if (data.DonationType === "" || data.DonationType === undefined) {
            stepForm1ErrorEdit.DonationTypeError = "Please Select Donation Type";
        } else {
            stepForm1ErrorEdit.DonationTypeError = "";
        }

        // if (data.DonationType == 3) {
        //     if (data.DonationNote === "") {
        //         stepForm1ErrorEdit.DonationNoteError = "Please Enter Donation Note";
        //     } else {
        //         stepForm1ErrorEdit.DonationNoteError = "";
        //     }
        // } else {
        //     stepForm1ErrorEdit.DonationNoteError = "";
        // }

        if (data.DonationAmount === "") {
            stepForm1ErrorEdit.DonationAmountError = "Please Enter Donation Amount";
        } else if (parseFloat(data.DonationAmount) < 0) {
            stepForm1ErrorEdit.DonationAmountError = "Donation Amount cannot be negative";
        } else {
            stepForm1ErrorEdit.DonationAmountError = "";
        }

        if (data.DonationAmountInWord === "") {
            stepForm1ErrorEdit.DonationAmountInWordError = "Please Enter Donation Amount in words";
        } else {
            stepForm1ErrorEdit.DonationAmountInWordError = "";
        }

        return stepForm1ErrorEdit;
    }

    if (step === 1) {
        if (data.DonorName === "") {
            stepForm2ErrorEdit.DonorNameError = "Please Enter Donor Name";
        } else {
            stepForm2ErrorEdit.DonorNameError = "";
        }

        if (!data.DonorMobile) {
            stepForm2ErrorEdit.DonorMobileError = "Please enter Donor Mobile number";
        } else if (data.DonorMobile.length !== 10 || !/^\d+$/.test(data.DonorMobile)) {
            stepForm2ErrorEdit.DonorMobileError = "Please enter a valid 10-digit Donor Mobile number";
        } else {
            stepForm2ErrorEdit.DonorMobileError = "";
        }

        if (data.DonorAddress === "") {
            stepForm2ErrorEdit.DonorAddressError = "Please Select Donor Address";
        } else {
            stepForm2ErrorEdit.DonorAddressError = "";
        }

        if (!data.DonorPincode) {
            stepForm2ErrorEdit.DonorPincodeError = "Please enter Donor Pincode";
        } else if (data.DonorPincode?.length !== 6 || !/^\d+$/.test(data.DonorPincode)) {
            stepForm2ErrorEdit.DonorPincodeError = "Please enter a valid 6-digit Donor Pincode";
        } else {
            stepForm2ErrorEdit.DonorPincodeError = "";
        }

        if (!data.DonorIdType) {
            stepForm2ErrorEdit.DonorIdTypeError = "Please Select Donor Id Type";
        } else {
            stepForm2ErrorEdit.DonorIdTypeError = "";
        }

        if (data.DonorIdNo === "") {
            stepForm2ErrorEdit.DonorIdNoError = "Please Enter Donor ID No";
        } else {
            stepForm2ErrorEdit.DonorIdNoError = "";
        }

        if (data.DonorIdType === 1) {
            if (data.DonorIdNo === "") {
                stepForm2ErrorEdit.DonorIdNoError = "Please Enter Permanent Account Number (PAN)";
            } else if (!isValidPAN(data.DonorIdNo)) {
                stepForm2ErrorEdit.DonorIdNoError = "Please Enter a valid Permanent Account Number (PAN)";
            } else {
                stepForm2ErrorEdit.DonorIdNoError = "";
            }
        } else if (data.DonorIdType === 2) {
            if (data.DonorIdNo === "") {
                stepForm2ErrorEdit.DonorIdNoError = "Please Enter Aadhaar Number";
            } else if (!isValidAadhaar(data.DonorIdNo)) {
                stepForm2ErrorEdit.DonorIdNoError = "Please Enter a valid Aadhaar Number";
            } else {
                stepForm2ErrorEdit.DonorIdNoError = "";
            }
        } else if (data.DonorIdType === 3) {
            if (data.DonorIdNo === "") {
                stepForm2ErrorEdit.DonorIdNoError = "Please Enter Tax Identification Number (TIN)";
            } else if (!isValidTIN(data.DonorIdNo)) {
                stepForm2ErrorEdit.DonorIdNoError = "Please Enter a valid Tax Identification Number (TIN)";
            } else {
                stepForm2ErrorEdit.DonorIdNoError = "";
            }
        } else if (data.DonorIdType === 4) {
            if (data.DonorIdNo === "") {
                stepForm2ErrorEdit.DonorIdNoError = "Please Enter Passport Number";
            } else if (!isValidPassport(data.DonorIdNo)) {
                stepForm2ErrorEdit.DonorIdNoError = "Please Enter a valid Passport Number";
            } else {
                stepForm2ErrorEdit.DonorIdNoError = "";
            }
        } else if (data.DonorIdType === 5) {
            if (data.DonorIdNo === "") {
                stepForm2ErrorEdit.DonorIdNoError = "Please Enter Elector's Photo Identity Number";
            } else if (!isValidElectorID(data.DonorIdNo)) {
                stepForm2ErrorEdit.DonorIdNoError = "Please Enter a valid Elector's Photo Identity Number";
            } else {
                stepForm2ErrorEdit.DonorIdNoError = "";
            }
        } else if (data.DonorIdType === 6) {
            if (data.DonorIdNo === "") {
                stepForm2ErrorEdit.DonorIdNoError = "Please Enter Driving License Number";
            } else if (!isValidDrivingLicense(data.DonorIdNo)) {
                stepForm2ErrorEdit.DonorIdNoError = "Please Enter a valid Driving License Number";
            } else {
                stepForm2ErrorEdit.DonorIdNoError = "";
            }
        } else if (data.DonorIdType === 7) {
            if (data.DonorIdNo === "") {
                stepForm2ErrorEdit.DonorIdNoError = "Please Enter Ration Card Number";
            } else if (!isValidRationCard(data.DonorIdNo)) {
                stepForm2ErrorEdit.DonorIdNoError = "Please Enter a valid Ration Card Number";
            } else {
                stepForm2ErrorEdit.DonorIdNoError = "";
            }
        }

        return stepForm2ErrorEdit;
    }

    if (step === 3) {
        if (data.TransactionType === "") {
            stepForm3ErrorEdit.TransactionTypeError = "Please Select Donation Payment Method"
        } else {
            stepForm3ErrorEdit.TransactionTypeError = ""
            if (data.TransactionType === 3) {
                if (data.BankName === "") {
                    stepForm3ErrorEdit.BankNameError = "Please Enter Bank Name";
                } else {
                    stepForm3ErrorEdit.BankNameError = "";
                }

                if (data.ChequeNo === "") {
                    stepForm3ErrorEdit.ChequeNoError = "Please Enter Cheque No.";
                } else {
                    stepForm3ErrorEdit.ChequeNoError = "";
                }

                if (data.ChequeDate === "") {
                    stepForm3ErrorEdit.ChequeDateError = "Please Enter Cheque Date";
                } else {
                    stepForm3ErrorEdit.ChequeDateError = "";
                }
            } else if (data.TransactionType === 2) {
                if (data.KindText === "") {
                    stepForm3ErrorEdit.KindTextError = "Please Enter Donation Kind";
                } else {
                    stepForm3ErrorEdit.KindTextError = "";
                }
            } else if (data.TransactionType === 4) {
                if (data.OnlineTransactionType === "" || data.OnlineTransactionType === undefined) {
                    stepForm3ErrorEdit.OnlineTransactionTypeError = "Please Select Transaction Type";
                } else {
                    stepForm3ErrorEdit.OnlineTransactionTypeError = "";
                }

                if (data.TransactionId === "") {
                    stepForm3ErrorEdit.TransactionIdError = "Please Enter Transaction ID";
                } else {
                    stepForm3ErrorEdit.TransactionIdError = "";
                }
            } else if (data.TransactionType === 5) {
                if (data.OthersPayText === "") {
                    stepForm3ErrorEdit.OthersPayTextError = "Please Enter Other Payment Method";
                } else {
                    stepForm3ErrorEdit.OthersPayTextError = "";
                }
            } else if (data.TransactionType === 3) {
                if (data.BankName === "") {
                    stepForm3ErrorEdit.BankNameError = "Please Enter Bank Name";
                } else {
                    stepForm3ErrorEdit.BankNameError = "";
                }

                if (data.DemandDraftNo === "") {
                    stepForm3ErrorEdit.DemandDraftNoError = "Please Enter Demand Draft No.";
                } else {
                    stepForm3ErrorEdit.DemandDraftNoError = "";
                }

                if (data.DemandDraftDate === "") {
                    stepForm3ErrorEdit.DemandDraftDateError = "Please Enter Demand Draft Date";
                } else {
                    stepForm3ErrorEdit.DemandDraftDateError = "";
                }
            } else {
                stepForm3ErrorEdit.BankNameError = "";
                stepForm3ErrorEdit.ChequeNoError = "";
                stepForm3ErrorEdit.ChequeDateError = "";
                stepForm3ErrorEdit.DemandDraftNoError = "";
                stepForm3ErrorEdit.DemandDraftDateError = "";
                stepForm3ErrorEdit.KindTextError = "";
                stepForm3ErrorEdit.OnlineTransactionTypeError = "";
                stepForm3ErrorEdit.TransactionIdError = "";
                stepForm3ErrorEdit.OthersPayTextError = "";
            }
        }

        return stepForm3ErrorEdit
    }
}

export const numberToWords = (num) => {
    const units = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    const convertThreeDigit = (num) => {
        let word = '';

        if (num > 99) {
            word += units[Math.floor(num / 100)] + ' hundred ';
            num %= 100;
        }

        if (num > 19) {
            word += tens[Math.floor(num / 10)] + ' ';
            num %= 10;
        }

        if (num > 0) {
            word += units[num] + ' ';
        }

        return word.trim();
    };

    if (num === 0) {
        return 'zero';
    }

    let words = '';
    let crores = Math.floor(num / 10000000);
    num %= 10000000;

    let lakhs = Math.floor(num / 100000);
    num %= 100000;

    let thousands = Math.floor(num / 1000);
    num %= 1000;

    let hundreds = Math.floor(num / 100);
    num %= 100;

    if (crores) {
        words += convertThreeDigit(crores) + ' crore ';
    }

    if (lakhs) {
        words += convertThreeDigit(lakhs) + ' lakh ';
    }

    if (thousands) {
        words += convertThreeDigit(thousands) + ' thousand ';
    }

    if (hundreds) {
        words += convertThreeDigit(hundreds) + ' hundred ';
    }

    if (num) {
        words += convertThreeDigit(num);
    }

    let newWord = words.charAt(0).toUpperCase() + words.slice(1)

    return newWord.trim() + ' rupees only';
};


export const numberToWords2 = (number) => {
    const units = [
        "Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine",
        "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"
    ];
    const tens = [
        "", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"
    ];
    const magnitudes = ["", "Thousand", "Million", "Billion", "Trillion"];

    if (number === 0) {
        return units[0];
    }
    const numStr = String(number);

    const numGroups = [];
    for (let i = numStr.length; i > 0; i -= 3) {
        numGroups.push(numStr.substring(Math.max(0, i - 3), i));
    }

    const words = [];
    for (let i = 0; i < numGroups.length; i++) {
        const groupNumber = parseInt(numGroups[i], 10);

        if (groupNumber > 0) {
            const groupWords = [];

            const hundreds = Math.floor(groupNumber / 100);
            if (hundreds > 0) {
                groupWords.push(units[hundreds] + " Hundred");
            }

            const tensUnits = groupNumber % 100;
            if (tensUnits > 0) {
                if (tensUnits < 20) {
                    groupWords.push(units[tensUnits]);
                } else {
                    const tensPlace = Math.floor(tensUnits / 10);
                    const unitsPlace = tensUnits % 10;
                    groupWords.push(tens[tensPlace]);
                    if (unitsPlace > 0) {
                        groupWords.push(units[unitsPlace]);
                    }
                }
            }

            if (i > 0) {
                groupWords.push(magnitudes[i]);
            }

            words.unshift(...groupWords);
        }
    }

    return words.join(" ");
}


export const formatDateForAddDonation = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-GB', options);

    const [day, month, year] = formattedDate.split(' ');
    const formattedDateString = `${day}/${month}/${year}`;

    return formattedDateString;
}

export const formatDateFilter = (dateString) => {
    const decodedString = decodeURIComponent(dateString);
    const date = new Date(decodedString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}

export const formatDateEditDonation = (dateString) => {
    const [day, month, year] = dateString.split('/');
    const formattedDate = new Date(`${year}-${month}-${day}`);
    return formattedDate.toISOString().split('T')[0];
}