const baseUrl = 'https://thedonationreceipt.in:3000/api'
export const imageUrl = 'https://thedonationreceipt.in:3000'
// const baseUrl = 'https://dev.ahura.xyz:3000/api'
// export const imageUrl = 'https://dev.ahura.xyz:3000'
// const baseUrl = 'http://192.168.1.16:3000/api'
// export const imageUrl = 'http://192.168.1.16:3000'
// const baseUrl = 'http://3.6.85.24:3000/api'
// export const imageUrl = 'http://3.6.85.24:3000'

export const getAdminLogin = `${baseUrl}/admin/adminLogin`
export const getAddTrust = `${baseUrl}/trust/addTrust`
export const getUpdateTrust = `${baseUrl}/trust/updateTrust`
export const getDeleteTrust = `${baseUrl}/trust/deleteTrust`
export const getTrustList = `${baseUrl}/trust/getTrustList`
export const getTrustDetails = `${baseUrl}/trust/getTrustDetails`
export const getTrustUpdatePassword = `${baseUrl}/trust/updateTrustpassword`
export const getChangeTrustpassword = `${baseUrl}/trust/changeTrustpassword`
export const getTrustDonationListAdmin = `${baseUrl}/donation/getDonationListForAdmin`
export const getDonationDetailsForAdmin = `${baseUrl}/donation/getDonationDetailsForAdmin`
export const getDownloadAdminCSV = `${baseUrl}/donation/downloadCsvForAdmin`
export const getTrustDelete = `${baseUrl}/donation/DeleteDonation`
export const getArchiveDonationListForAdmin = `${baseUrl}/donation/getArchiveDonationListForAdmin`
export const getDownloadDonationReceiptAdmin = `${baseUrl}/donation/downloadDonationReceiptForAdmin`
export const getDeleteDonationReceiptAdmin = `${baseUrl}/donation/deleteDonationReceiptForAdmin`

export const getDownloadTrustCSV = `${baseUrl}/donation/downloadCsv`
export const getTrustLogin = `${baseUrl}/trust/trustLogin`
export const getAddDonation = `${baseUrl}/donation/addDonation`
export const getUpdateDonation = `${baseUrl}/donation/updateDonation`
export const getTrustDetailsPatch = `${baseUrl}/trust/patchTrustDetails`
export const getTrustDonationList = `${baseUrl}/donation/getDonationList`
export const getTrustDonationDetail = `${baseUrl}/donation/getDonationDetails`
export const getDonationDelete = `${baseUrl}/donation/DeleteDonation`
export const getArchiveDonationList = `${baseUrl}/donation/getArchiveDonationList`
export const getEditTrust = `${baseUrl}/trust/editTrust`
export const getDownloadDonationReceipt = `${baseUrl}/donation/downloadDonationReceipt`
export const getDeleteDonationReceipt = `${baseUrl}/donation/deleteDonationReceipt`
export const getDeletedDonationList = `${baseUrl}/donation/getDeletedDonationList`

export const getImageUpload = `${baseUrl}/image/uploadImage`