import React from "react";
import { Page, View, Text, Image, Document, StyleSheet } from "@react-pdf/renderer";
import LebronStretch from "assets/images/DRGLogo.png";
import { Font } from '@react-pdf/renderer';
import MyCustomFont from 'fonts/Mukta-Bold.ttf';

Font.register({
  family: 'AntonFamily',
  src: MyCustomFont
})

const styles = StyleSheet.create({
  container: {
    width: 900,
    margin: 'auto',
    border: '1px solid #000',
    textAlign: 'center',
    padding: 25,
  },
  trustLogo: {
    width: 55,
    height: 55,
    objectFit: 'contain',
  },
  trustTitle: {
    marginTop: 10,
  },
  trustTitleText: {
    fontSize: 16,
    fontFamily: 'system-ui',
    lineHeight: '1.6em',
  },
  trustFormContent: {
    border: '1px solid #000',
    padding: '2% 0 0',
    marginTop: '3%',
    margin: '3% 3% 0',
  },
  trustedRow: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #000000',
    padding: '0% 3% 0',
  },
  trustInputs: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  trustInputsLabel: {
    fontSize: 14,
  },
  trustInputsText: {
    fontSize: 16,
  },
  field: {
    margin: 0,
    fontSize: 16,
    fontWeight: 'bold',
    display: 'inline-block',
    width: 'max-content',
    borderBottom: '2px dotted #666',
    paddingBottom: 2,
    minWidth: 110,
  },
  desc: {
    padding: '15px 0',
  },
  descText: {
    border: 'none',
    textDecorationStyle: 'dotted',
    fontWeight: 'normal',
    lineHeight: 2,
  },
  descTextSpan: {
    display: 'inline',
    fontWeight: 'bold',
    borderBottom: '2px dotted #555',
  },
  signator: {
    textAlign: 'center',
  },
  signatorImg: {
    width: 200,
    height: 80,
    objectFit: 'contain',
  },
  note: {
    fontSize: 16,
    textAlign: 'center',
  },
});

const PDFFile = ({ data }) => {
  const pages = [
    { ...data },
  ]


  return (
    <>
      <Document>
        <Page size="A4">
          <View style={styles.container}>
            <View>
              <Image style={styles.trustLogo} src={pages.trustLogo} alt="Trust" />
            </View>
            <View style={styles.trustTitle}>
              <Text>{pages.TrustName}</Text>
            </View>
            <View style={styles.trustTitle}>
              <Text style={styles.trustTitleText}>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sapiente dolores accusamus assumenda, iusto dolor
                quisquam inventore? Ratione sapiente, nihil facilis vitae voluptatum distinctio, optio neque totam laboriosam
                quas delectus officia?
              </Text>
            </View>
          </View>
          <View style={styles.trustFormContent}>
            <View style={styles.trustedRow}>
              <View style={styles.trustInputs}>
                <Text style={styles.trustInputsLabel}>Receipt No.</Text>
                <Text style={styles.trustInputsText}><b>01/2023-24</b></Text>
              </View>
              <View style={styles.trustInputs}>
                <Text style={styles.trustInputsLabel}>Date</Text>
                <Text style={styles.trustInputsText}><b>21/05/2020</b></Text>
              </View>
            </View>
            <View style={styles.trustedRow}>
              <View>
                <Text style={styles.trustInputsText}>Received with thanks from M/s. / Shri / Smt.</Text>
              </View>
              <View>
                <Text style={styles.field}>MohanDad Karamchand Gandhi</Text>
              </View>
            </View>
            <View style={styles.trustedRow}>
              <View>
                <Text>
                  <Text style={styles.checkMark}>&#x2610;</Text> Cash
                </Text>
              </View>
              <View>
                <Text>
                  <Text style={styles.checkMark}>&#x2611;</Text> Demand Draft No.
                  <Text style={styles.field}>624296914</Text>
                </Text>
              </View>
              <View>
                <Text>
                  <Text style={styles.checkMark}>&#x2610;</Text> Cheque No.
                </Text>
              </View>
              <View>
                <Text>Dated: <b>21/05/2020</b></Text>
              </View>
            </View>
            <View style={styles.trustedRow}>
              <View>
                <Text style={styles.field}><span>Rs.</span> 500000</Text>
              </View>
              <View>
                <Text style={styles.field}><span>Rupees.</span> Five Lakh Rupees only</Text>
              </View>
            </View>
            <View style={styles.trustedRow}>
              <View>
                <Text style={styles.field}>
                  <span>Drawn on <br />(Bank & Branch)</span> State Bank of India, Bopal
                </Text>
              </View>
            </View>
            <View style={styles.trustedRow}>
              <View style={styles.desc}>
                <Text style={styles.field}>
                  As Donation towards <Text style={styles.descText}>Lorem ipsum dolor sit amet consectetur, adipisicing
                    elit. Sapiente dolores accusamus assumenda, iusto dolor quisquam inventore? Ratione sapiente, nihil facilis
                    vitae voluptatum distinctio, optio neque totam laboriosam quas delectus officia?</Text>
                </Text>
              </View>
            </View>
            <View style={styles.trustedRow}>
              <View style={styles.desc}>
                <Text>
                  Trust is registered under Bombay Public Trust Act, 1950 vide Regn. No. A/G 402 B.K. PAN NO. ABCDE1234F
                  I.T (Exemptions) Approval under Form No. 10AD with URN: 4682164848449249
                  Granted under sub-clause 11-Clause(i) of First proviso to sub-section (5) of Section 35(1)(iia) on 23
                  June, 2020. Valid from A.Y. 2015-16 to A.Y. 2030-31
                </Text>
              </View>
              <View style={styles.signator}>
                <Text>For Ahura Technosoft LLP, Ahmedabad</Text>
                <Image style={styles.signatorImg} src="./signature.png" alt="" />
                <Text>Trustee / Authorised Signatory</Text>
              </View>
            </View>
            <Text style={styles.note}>Donations to the trust are exempt from Income-Tax</Text>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default PDFFile;