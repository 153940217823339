import React, { useState, useEffect } from "react";
import './Style/trust.css'
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { FormControlLabel, IconButton } from "@material-ui/core";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { bindMenu } from 'material-ui-popup-state/hooks'
import { bindTrigger } from 'material-ui-popup-state/hooks'
import { CiSearch } from 'react-icons/ci'
import InputBase from '@material-ui/core/InputBase';
import { AiOutlineEye, AiOutlinePlus } from "react-icons/ai";
import Menu from "@mui/material/Menu";
import { CiExport } from "react-icons/ci"
import PopupState from "material-ui-popup-state";
import { MdOutlineLocalPrintshop, MdTune } from 'react-icons/md';
import { TiFolderOpen } from 'react-icons/ti';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { addDays, startOfDay } from 'date-fns';
import { Modal, Pagination, Row, Switch, Text } from "@nextui-org/react";
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { userToken } from "Token";
import { getTrustDonationList } from "Api";
import axios from "axios";
import { getTrustDetailsPatch } from "Api";
import { AddDonationErrors, EditDonationErrors, formatDateEditDonation, formatDateFilter, formatDateForAddDonation, numberToWords } from "./Errors/Errors";
import { getAddDonation } from "Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getTrustDonationDetail } from "Api";
import { getUpdateDonation } from "Api";
import { getDownloadTrustCSV } from "Api";
import { getDonationDelete } from "Api";
// import { ReceiptPDF } from "layouts/DonationReceipt";
import { BsDownload } from "react-icons/bs";
// import PdfDocument from "layouts/DonationReceipt";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import PDFFile from "layouts/DonationReceipt";
import { getDownloadDonationReceipt } from "Api";
import { imageUrl } from "Api";
import { saveAs } from 'file-saver';
import { getDeleteDonationReceipt } from "Api";

const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
}

function Trust() {
    const token = userToken();
    const columns = [
        { field: 'srno', headerName: 'ID', width: 90 },
        { field: 'DonorName', headerName: 'Donor name', width: 250, },
        { field: 'DonationAmount', headerName: 'Amount', width: 200, },
        { field: 'DateofDonation', headerName: 'Date of donation', width: 200, },
        { field: 'formattedDate', headerName: 'Created At', width: 200, },
        { field: 'DonationType', headerName: 'Donation Type', width: 200, },
        { field: 'PaymentType', headerName: 'Payment Type', width: 200, },
        {
            field: 'action', headerName: 'Action', width: 250,
            renderCell: (prevent) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }} >
                        <MatEdit index={prevent.row?._id} />
                        <MatView index={prevent.row} />
                        <MatDownload index={prevent.row} />
                        <MatPrint index={prevent.row} />
                        <MatDelete index={prevent.row} />
                    </div>
                );
            },
        }
    ];

    const [editId, setEditId] = useState("")
    const MatEdit = ({ index }) => {
        const handleEditClick = (e, i) => {
            setEditId(index)
            handlerEdit();
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            axios.get(`${getTrustDonationDetail}/${index}`)
                .then((res) => {
                    const editData = res.data.result
                    if (typeof editData.TypeDonation == "object") {
                        setDonationType(parseInt(editData.TypeDonation.DonationType))
                    } else {
                        setDonationType(parseInt(editData.TypeDonation))
                    }
                    setStep1DataEdit({
                        DateOfDonation: editData.DateofDonation,
                        // DonationTo: editData.DonationTo,
                        year: editData.year,
                        receiptNo: editData.receiptNo,
                        DonationNote: editData.TypeDonation.DonationNote,
                        DonationType: editData.TypeDonation,
                        DonationAmount: editData.DonationAmount,
                        DonationAmountInWord: numberToWords(editData.DonationAmount)
                    })
                    setStep2DataEdit({
                        DonorName: editData.DonorName,
                        DonorMobile: editData.DonorMobile,
                        DonorAddress: editData.DonorAddress,
                        DonorPincode: editData.DonorPincode,
                        DonorIdType: editData.DonorIdType,
                        DonorIdNo: editData.DonorIdNo
                    })
                    setStep3DataEdit({
                        TransactionType: editData?.TransactionType,
                        BankName: editData.TransactionDetails?.BankName,
                        ChequeNo: editData.TransactionDetails?.ChequeNo,
                        ChequeDate: editData.TransactionDetails?.ChequeDate,
                        DemandDraftNo: editData.TransactionDetails?.DemandDraftNo,
                        DemandDraftDate: editData.TransactionDetails?.DemandDraftDate,
                        KindText: editData.TransactionDetails,
                        OnlineTransactionType: editData.TransactionDetails?.TransactionType,
                        TransactionId: editData.TransactionDetails?.TransactionId,
                        OthersPayText: editData.OthersPayText
                    })
                    setDonorIdType(DonorIdTypes.find((item) => {
                        return item.value == editData.DonorIdType
                    }))
                    const payType = transactionTypes.find((item) => {
                        return item.value === editData.TransactionType
                    })
                    setPaymentType(payType?.value)
                    const transType = onlineTransactionType.find((item) => {
                        return item.value == editData.TransactionDetails?.OnlineTransactionType
                    })
                    setOnlineTransType(transType?.value)
                })
        }

        return (
            <FormControlLabel
                control={
                    <IconButton color="secondary" aria-label="add an alarm" onClick={(e) => handleEditClick(e, index)} >
                        <ModeEditOutlineOutlinedIcon />
                    </IconButton>
                }
            />
        );
    };

    const [viewDonation, setViewDonation] = useState({})
    const MatView = ({ index }) => {
        const handleViewClick = (e) => {
            handlerView();

            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            axios.get(`${getTrustDonationDetail}/${index?._id}`)
                .then((res) => {
                    let ele = res.data.result;
                    if (typeof ele.TypeDonation == "object") {
                        ele.DonationType = donationTypes.find((item) => {
                            return item.value == (parseInt(ele.TypeDonation.DonationType))
                        })
                    } else {
                        ele.DonationType = donationTypes.find((item) => {
                            return item.value == (parseInt(ele.TypeDonation))
                        })
                    }
                    ele.Donor = DonorIdTypes.find((item) => {
                        return item.value == ele.DonorIdType
                    })
                    ele.paymentType = transactionTypes.find((item) => {
                        return item.value === ele.TransactionType
                    })
                    ele.onlineTransactionType = onlineTransactionType.find((item) => {
                        return item.value == ele.TransactionDetails?.OnlineTransactionType
                    })
                    ele.DonorIdType = DonorIdTypes.find((item) => {
                        return item.value == ele.DonorIdType
                    })
                    setViewDonation(res.data.result)
                })
        }
        return (
            <FormControlLabel
                control={
                    <IconButton color="secondary" aria-label="add an alarm" onClick={(e) => handleViewClick(e)} >
                        <AiOutlineEye />
                    </IconButton>
                }
            />
        );
    };

    const MatPrint = ({ index }) => {
        const handleViewClick = (e) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.get(`${getDownloadDonationReceipt}/${index?._id}`)
                .then((res) => {
                    const pdfUrl = `${imageUrl}/docs/${res.data.filename}`;
                    const printWindow = window.open(pdfUrl);
                    printWindow.onload = () => {
                        printWindow.print();
                    };

                    // axios.get(`${getDeleteDonationReceipt}/${res.data.filename}`)
                })
        }
        return (
            <FormControlLabel
                control={
                    <IconButton color="secondary" aria-label="add an alarm" onClick={(e) => handleViewClick(e)} >
                        <TiFolderOpen />
                    </IconButton>
                }
            />
        );
    };

    const MatDownload = ({ index }) => {
        const handleViewClick = () => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

            axios.get(`${getDownloadDonationReceipt}/${index?._id}`)
                .then((res) => {
                    const url = `${imageUrl}/docs/${res.data.filename}`;
                    const fileName = `${index.receiptNo}.pdf`;
                    saveAs(url, fileName)
                    axios.get(`${getDeleteDonationReceipt}/${res.data.filename}`)
                })
        };


        return (
            <FormControlLabel
                control={
                    <>
                        <IconButton color="secondary" aria-label="add an alarm" onClick={(e) => handleViewClick(e)} >
                            <BsDownload />
                        </IconButton>
                    </>
                }
            />
        );
    };

    const MatDelete = ({ index, i }) => {
        const [visible, setVisible] = React.useState(false);
        const handler = () => {
            setVisible(true)
        };
        const closeHandler = () => {
            setVisible(false);
            fetchdonationList()
        };
        const handleDeleteDonation = (e) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            axios.delete(`${getDonationDelete}/${index?._id}`)
                .then((res) => {
                    toast.success(res.data.message, toastStyle);
                    closeHandler()
                })
                .catch((err) => {
                    toast.error(err.response.data.message, toastStyle);
                })
        }

        return (
            <FormControlLabel
                control={
                    <>
                        <div className="ActionButtons">
                            <IconButton color="secondary" aria-label="add an alarm" onClick={(e) => handler(e)} className="dyFlextIcon" >
                                <DeleteOutlinedIcon />
                            </IconButton>
                            <div className="delete-popup">
                                <Modal closeButton aria-labelledby="modal-title" className="AddDataModel" width={700} open={visible} onClose={closeHandler} >
                                    <Modal.Header justify="start">
                                        <Text id="modal-title" size={28}>
                                            Are You <Text b size={28}> Sure? </Text>
                                        </Text>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Text id="modal-title" size={28}>
                                            You Want to Delete Donation by <Text b size={28}> {index.DonorName} </Text> Record?
                                        </Text>
                                        <Text id="modal-title" size={24}>
                                            Do you really want to delete these records? You can't view this in your list anymore if you delete!
                                        </Text>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button auto flat color="error" className="common-btn light" onClick={closeHandler}>
                                            No, Keep it
                                        </Button>
                                        <Button auto className="common-btn" onClick={(e) => handleDeleteDonation(e)}>
                                            Yes, Delete it
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </>
                }
            />
        );
    };


    // Get Trust Details
    const [trustDetail, setTrustDetail] = useState({})
    const [ArchiveId, setArchiveId] = useState("")
    const [fetched, setFetched] = useState(false)
    const fetchTrustDetails = () => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        axios.patch(getTrustDetailsPatch)
            .then((res) => {
                setFetched(true)
                setTrustDetail(res.data.user)
                setArchiveId(res.data.archiveId)
            })
    }
    useEffect(() => {
        if (token !== "" && token !== undefined) {
            fetchTrustDetails()
        }
    }, [token])

    // Variables
    const [donationList, setDonationList] = useState([])
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState({
        dateStart: "",
        dateEnd: ""
    })

    // Date Filter
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);

    const handleDateFilter = () => {
        setFilter({
            ...filter,
            dateStart: formatDateFilter(new Date(state[0].startDate)),
            dateEnd: formatDateFilter(new Date(state[0].endDate))
        })
    }

    // Pagination
    const [pageState, setPageState] = useState({
        total: 0,
        totalPages: 0,
        page: 1,
        pageSize: 10
    })
    const handlePageChange = (selectedPage) => {
        setPageState((prevState) => ({ ...prevState, page: selectedPage }));
        // fetchdonationList()
    };
    const ResultInfo = () => {
        const start = (pageState.page - 1) * pageState.pageSize + 1;
        const end = Math.min(start + pageState.pageSize - 1, pageState.total);

        return (
            <div className="result-info">
                Showing <span>{start}-{end}</span> of <span>{pageState.total}</span> Trusts
            </div>
        );
    };

    // Reset Filter
    const handleResetFilter = () => {
        setFilter({
            dateStart: "",
            dateEnd: ""
        })
        setState([
            {
                startDate: startOfDay(new Date()),
                endDate: addDays(startOfDay(new Date()), 0),
                key: 'selection'
            }
        ]);
    }

    // Search 
    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    // Nest Donation Receipt no.
    const [nextReceiptNo, setNextReceiptNo] = useState("")
    const [totalDonation, setTotalDonation] = useState("")

    // Get All Donation List
    const fetchdonationList = () => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        axios.get(`${getTrustDonationList}/${trustDetail?._id}?ArchiveId=${ArchiveId}&page=${pageState.page}&limit=${pageState.pageSize}&search=${search}&filterType=${filterType}&dateStart=${filter.dateStart}&dateEnd=${filter.dateEnd}`)
            .then((res) => {
                const json = res.data.result;
                setPageState((prevState) => ({ ...prevState, total: json.totalDocs, totalPages: json.totalPages }));
                setNextReceiptNo(json.nextReceiptno)
                setTotalDonation(json.totolDonation)

                res.data.result.docs.map((ele, i) => {
                    ele.id = i + 1;
                    ele.srno = (res.data.result.page * 10) + (i + 1) - 10;
                    const donationType = donationTypes.find((item) => {
                        if (typeof ele.TypeDonation == "object") {
                            return item.value == ele.TypeDonation.DonationType
                        } else {
                            return item.value == ele.TypeDonation
                        }
                    })
                    ele.DonationType = donationType?.label

                    const transactionType = transactionTypes.find((item) => {
                        return item.value == ele.TransactionType
                    })
                    ele.PaymentType = transactionType?.label
                    ele.formattedDate = formatDateForAddDonation(ele.createdAt);
                    ele.DateofDonation = formatDateForAddDonation(ele.DateofDonation);
                })
                setDonationList(res.data.result.docs)
            })
    }
    useEffect(() => {
        if (trustDetail?._id != undefined && ArchiveId !== undefined) {
            fetchdonationList()
        }
    }, [search, filter, trustDetail, pageState.page, pageState.pageSize, ArchiveId])

    // Handle Filter by
    const [filterType, setFilterType] = useState(1)

    const handleFilterType = (e) => {
        setFilterType(parseInt(e.target.value))
    }

    // handle payment type
    const transactionTypes = [
        { value: 1, label: 'Cash' },
        { value: 2, label: 'Kind' },
        { value: 3, label: 'Cheque' },
        { value: 4, label: 'Online transaction' },
        { value: 5, label: 'Others' },
        { value: 6, label: 'Demand Draft' },
    ]
    const [paymentType, setPaymentType] = useState()

    const handlePaymentType = (e) => {
        setPaymentType(parseInt(e.target.value))
        // setNextButtonClicked(false);
    }

    // Handle Online Transaction Type
    const onlineTransactionType = [
        { value: 1, label: 'UPI' },
        { value: 2, label: 'NEFT' },
        { value: 3, label: 'RTGS' },
        { value: 4, label: 'IMPS' },
        { value: 5, label: 'ECS' },
        { value: 6, label: 'MICR' },
        { value: 7, label: 'Others' },
    ]
    const [onlineTransType, setOnlineTransType] = useState()

    const handleOnlineTransType = (e) => {
        setOnlineTransType(parseInt(e.target.value))
    }

    // Handle Donation Type
    const donationTypes = [
        { value: 1, label: 'Specific grant' },
        { value: 2, label: 'Corpus' },
        { value: 3, label: 'Others' },
    ]
    const [donationType, setDonationType] = useState(3)

    const handleDonationType = (e) => {
        setDonationType(parseInt(e.target.value))
    }

    // Handle Donor ID Type
    const [DonorIdTypes, setDonorIdTypes] = useState([
        { value: '1', label: 'Permanent Account Number' },
        { value: '2', label: 'Aadhaar Number' },
        { value: '3', label: 'Tax Identification Number' },
        { value: '4', label: 'Passport number' },
        { value: '5', label: "Elector's photo identity number" },
        { value: '6', label: 'Driving License number' },
        { value: '7', label: 'Ration card number' },
    ])
    const [DonorIdType, setDonorIdType] = useState();
    const handleDonorIdType = (e) => {
        setDonorIdType(DonorIdTypes.find((ele) => {
            return ele.value === e.value
        }))
    }

    // Add Donation Popup
    const [visible, setVisible] = React.useState(false);
    const handler = () => {
        setVisible(true)
        setStep(1)
    };
    const closeHandler = () => {
        setVisible(false);
        setNextButtonClicked(false);
        setTimeout(() => {
            setStep(1)
            setAddedDonation({});
            setDonationType(3);
        }, 2000)
        setDonorIdType([])
        setDonationType("")
        setOnlineTransType("")
        setPaymentType("")
        setStep1Data({
            DateOfDonation: new Date().toISOString().split('T')[0],
            DonationTo: "",
            receiptNo: "",
            DonationType: 3,
            DonationNote: "",
            DonationAmount: "",
            DonationAmountInWord: ""
        })
        setStep2Data({
            DonorName: "",
            DonorMobile: "",
            DonorAddress: "",
            DonorPincode: '',
            DonorIdType: "",
            DonorIdNo: ""
        })
        setStep3Data({
            TransactionType: "",
            BankName: "",
            ChequeNo: "",
            ChequeDate: "",
            KindText: "",
            OnlineTransactionType: "",
            TransactionId: "",
            OthersPayText: "",
            DemandDraftNo: "",
            DemandDraftDate: ""
        })
        setError1(true)
        setError2(true)
        setError3(true)
    };

    // Edit Donation Popup
    const [visibleEdit, setVisibleEdit] = React.useState(false);
    const handlerEdit = () => {
        setVisibleEdit(true)
        setStep(1)
        setDonorIdType()
        setDonationType(3)
        setOnlineTransType("")
        setPaymentType("")
        setStep1DataEdit({
            DateOfDonation: new Date().toISOString().split('T')[0],
            DonationTo: "",
            receiptNo: "",
            DonationType: "",
            DonationNote: "",
            DonationAmount: "",
            DonationAmountInWord: ""
        })
        setStep2DataEdit({
            DonorName: "",
            DonorMobile: "",
            DonorAddress: "",
            DonorPincode: "",
            DonorIdType: "",
            DonorIdNo: ""
        })
        setStep3DataEdit({
            TransactionType: "",
            BankName: "",
            ChequeNo: "",
            ChequeDate: "",
            KindText: "",
            OnlineTransactionType: "",
            TransactionId: "",
            OthersPayText: "",
            DemandDraftNo: "",
            DemandDraftDate: ""
        })
    };
    const closeHandlerEdit = () => {
        setVisibleEdit(false)
        setNextButtonClicked(false)
        setTimeout(() => {
            setStep(1)
        }, 2000)
    };

    // Add Donation Form Variables
    const [step1Data, setStep1Data] = useState({
        DateOfDonation: new Date().toISOString().split('T')[0],
        receiptNo: "",
        year: "",
        DonationType: 3,
        DonationNote: "",
        DonationAmount: "",
        DonationAmountInWord: ""
    })
    const [step2Data, setStep2Data] = useState({
        DonorName: "",
        DonorMobile: "",
        DonorAddress: "",
        DonorPincode: '',
        DonorIdType: "",
        DonorIdNo: ""
    })
    const [step3Data, setStep3Data] = useState({
        TransactionType: "",
        BankName: "",
        ChequeNo: "",
        ChequeDate: "",
        KindText: "",
        OnlineTransactionType: "",
        TransactionId: "",
        OthersPayText: "",
        DemandDraftNo: "",
        DemandDraftDate: ""
    })
    const [step1DataEdit, setStep1DataEdit] = useState({
        DateOfDonation: "",
        // DonationTo: "",
        year: "",
        receiptNo: "",
        DonationType: 3,
        DonationNote: "",
        DonationAmount: "",
        DonationAmountInWord: ""
    })
    const [step2DataEdit, setStep2DataEdit] = useState({
        DonorName: "",
        DonorMobile: "",
        DonorAddress: "",
        DonorPincode: "",
        DonorIdType: "",
        DonorIdNo: ""
    })
    const [step3DataEdit, setStep3DataEdit] = useState({
        TransactionType: "",
        BankName: "",
        ChequeNo: "",
        ChequeDate: "",
        KindText: "",
        OnlineTransactionType: "",
        TransactionId: "",
        OthersPayText: "",
        DemandDraftNo: "",
        DemandDraftDate: ""
    })
    const [step, setStep] = useState(1);

    // handle Add Donation Data
    const handleDonationData = (e, data) => {
        if (step === 2) {
            setStep1Data({
                ...step1Data,
                [e.target.name]: e.target.value
            })
        }
        if (data === "Amount") {
            const regex = /^[0-9]*$/;
            if (e.target.value === '') {
                const words = numberToWords(0);
                setStep1Data({
                    ...step1Data,
                    [e.target.name]: 0,
                    DonationAmountInWord: words.charAt(0).toUpperCase() + words.slice(1)
                })
            } else if (regex.test(e.target.value) || e.target.value !== '') {
                const words = numberToWords(parseInt(e.target.value));
                setStep1Data({
                    ...step1Data,
                    [e.target.name]: parseInt(e.target.value),
                    DonationAmountInWord: words.charAt(0).toUpperCase() + words.slice(1)
                })
            }
        }
        if (step === 1) {
            setStep2Data({
                ...step2Data,
                [e.target.name]: e.target.value
            })
        }
        if (step === 3) {
            setStep3Data({
                ...step3Data,
                [e.target.name]: e.target.value
            })
        }
    }

    // Handle Edit Donation Data
    const handleDonationDataEdit = (e, data) => {
        if (step === 2) {
            setStep1DataEdit({
                ...step1DataEdit,
                [e.target.name]: e.target.value
            })
        }
        if (data === "Amount") {
            const regex = /^[0-9]*$/;
            if (e.target.value === '') {
                const words = numberToWords(0);
                setStep1DataEdit({
                    ...step1DataEdit,
                    [e.target.name]: 0,
                    DonationAmountInWord: words.charAt(0).toUpperCase() + words.slice(1)
                })
            } else if (regex.test(e.target.value) || e.target.value !== '') {
                const words = numberToWords(parseInt(e.target.value));
                setStep1DataEdit({
                    ...step1DataEdit,
                    [e.target.name]: parseInt(e.target.value),
                    DonationAmountInWord: words.charAt(0).toUpperCase() + words.slice(1)
                })
            }
        }
        if (step === 1) {
            setStep2DataEdit({
                ...step2DataEdit,
                [e.target.name]: e.target.value
            })
        }
        if (step === 3) {
            setStep3DataEdit({
                ...step3DataEdit,
                [e.target.name]: e.target.value
            })
        }
    }

    useEffect(() => {
        setStep1Data({
            ...step1Data,
            DonationType: donationType
        })
        setStep2Data({
            ...step2Data,
            DonorIdType: DonorIdType?.value
        })
        setStep3Data({
            ...step3Data,
            TransactionType: paymentType,
            OnlineTransactionType: onlineTransType
        })
        setStep1DataEdit({
            ...step1DataEdit,
            DonationType: donationType
        })
        setStep2DataEdit({
            ...step2DataEdit,
            DonorIdType: DonorIdType?.value
        })
        setStep3DataEdit({
            ...step3DataEdit,
            TransactionType: paymentType,
            OnlineTransactionType: onlineTransType
        })
    }, [donationType, DonorIdType, paymentType, onlineTransType])

    // Check Errors
    const [stepForm1Error, setStepForm1Error] = useState({})
    const [stepForm2Error, setStepForm2Error] = useState({})
    const [stepForm3Error, setStepForm3Error] = useState({})

    const [stepForm1ErrorEdit, setStepForm1ErrorEdit] = useState({})
    const [stepForm2ErrorEdit, setStepForm2ErrorEdit] = useState({})
    const [stepForm3ErrorEdit, setStepForm3ErrorEdit] = useState({})
    useEffect(() => {
        setStepForm1Error(AddDonationErrors(step1Data, step))
        setStepForm2Error(AddDonationErrors(step2Data, step))
        setStepForm3Error(AddDonationErrors(step3Data, step))

        setStepForm1ErrorEdit(EditDonationErrors(step1DataEdit, step))
        setStepForm2ErrorEdit(EditDonationErrors(step2DataEdit, step))
        setStepForm3ErrorEdit(EditDonationErrors(step3DataEdit, step))
    }, [step1Data, step2Data, step3Data, step1DataEdit, step2DataEdit, step3DataEdit, step])


    const [nextButtonClicked, setNextButtonClicked] = useState(false);
    const handleNextEdit = () => {
        setNextButtonClicked(true);

        if (
            (step === 2 &&
                (stepForm1ErrorEdit.DateOfDonationError !== "" ||
                    stepForm1ErrorEdit.DonationAmountError !== "" ||
                    stepForm1ErrorEdit.DonationAmountInWordError !== "" ||
                    stepForm1ErrorEdit.receiptNoError !== "" ||
                    // stepForm1ErrorEdit.DonationToError !== "" ||
                    // stepForm1ErrorEdit.DonationNoteError !== "" ||
                    stepForm1ErrorEdit.DonationTypeError !== "")) ||
            (step === 1 &&
                (stepForm2ErrorEdit.DonorNameError !== "" ||
                    // stepForm2ErrorEdit.DonorMobileError !== "" ||
                    stepForm2ErrorEdit.DonorAddressError !== "" ||
                    stepForm2ErrorEdit.DonorPincodeError !== "" ||
                    stepForm2ErrorEdit.DonorIdTypeError !== "" ||
                    stepForm2ErrorEdit.DonorIdNoError !== "")) ||
            (step === 3 &&
                (stepForm3ErrorEdit.TransactionTypeError !== "" ||
                    stepForm3ErrorEdit.BankNameError !== "" ||
                    stepForm3ErrorEdit.ChequeNoError !== "" ||
                    stepForm3ErrorEdit.ChequeDateError !== "" ||
                    stepForm3ErrorEdit.DemandDraftNoError !== "" ||
                    stepForm3ErrorEdit.DemandDraftDateError !== "" ||
                    stepForm3ErrorEdit.OnlineTransactionTypeError !== "" ||
                    // stepForm3ErrorEdit.TransactionIdError !== "" ||
                    stepForm3ErrorEdit.OthersPayTextError !== ""))
        ) {
            return;
        }

        setStep(step + 1);
        setNextButtonClicked(false);
    };

    const handleNext = () => {
        setNextButtonClicked(true);
        if (
            (step === 2 &&
                (stepForm1Error.DateOfDonationError !== "" ||
                    stepForm1Error.DonationAmountError !== "" ||
                    stepForm1Error.DonationAmountInWordError !== "" ||
                    // stepForm1Error.receiptNoError !== "" ||
                    // stepForm1Error.DonationToError !== "" ||
                    // stepForm1Error.DonationNoteError !== "" ||
                    stepForm1Error.DonationTypeError !== "")) ||
            (step === 1 &&
                (stepForm2Error.DonorNameError !== "" ||
                    // stepForm2Error.DonorMobileError !== "" ||
                    stepForm2Error.DonorAddressError !== "" ||
                    stepForm2Error.DonorPincodeError !== "" ||
                    stepForm2Error.DonorIdTypeError !== "" ||
                    stepForm2Error.DonorIdNoError !== "")) ||
            (step === 3 &&
                (stepForm3Error.TransactionTypeError !== "" ||
                    stepForm3Error.BankNameError !== "" ||
                    stepForm3Error.ChequeNoError !== "" ||
                    stepForm3Error.ChequeDateError !== "" ||
                    stepForm3Error.DemandDraftNoError !== "" ||
                    stepForm3Error.DemandDraftDateError !== "" ||
                    stepForm3Error.OnlineTransactionTypeError !== "" ||
                    // stepForm3Error.TransactionIdError !== "" ||
                    stepForm3Error.OthersPayTextError !== ""))
        ) {
            return;
        }
        setStep(step + 1);
        setNextButtonClicked(false);
    };

    const handleBack = () => {
        setStep(step - 1);
    };

    // Step Sidebar Error Add Trust
    const [error1, setError1] = useState(true);
    const [error2, setError2] = useState(true);
    const [error3, setError3] = useState(true);

    useEffect(() => {
        const updateStepFormError = (setError, data, step) => {
            let hasError = Object.entries(data).some(([key, value]) => {
                if (step === 2 && key === 'DonationNote') {
                    return false;
                }
                if (step === 2 && key === 'DonorMobile') {
                    return false;
                }
                if (step === 3) {
                    if (data.TransactionType === 1) {
                        return false;
                    } else if (data.TransactionType === 2 && data.KindText !== "") {
                        return false;
                    } else if (data.TransactionType === 3 && data.BankName !== "" && data.ChequeNo !== "" && data.ChequeDate !== "") {
                        return false;
                    } else if (data.TransactionType === 4 && data.OnlineTransactionType !== "" && data.OnlineTransactionType !== undefined) {
                        return false;
                    } else if (data.TransactionType === 5 && data.OthersPayText !== "") {
                        return false;
                    } else if (data.TransactionType === 6 && data.BankName !== "" && data.DemandDraftNo !== "" && data.DemandDraftDate !== "") {
                        return false;
                    } else {
                        return true;
                    }
                }

                return value === "" || value === undefined;
            });

            setError(hasError);
        };

        if (step === 1) {
            updateStepFormError(setError2, step2Data, step);
        } else if (step === 2) {
            updateStepFormError(setError1, step1Data, step);
        } else if (step === 3) {
            updateStepFormError(setError3, step3Data, step);
        }
    }, [step, step1Data, step2Data, step3Data]);

    // Step Sidebar Error Edit Trust
    const [errorE1, setErrorE1] = useState(true);
    const [errorE2, setErrorE2] = useState(true);
    const [errorE3, setErrorE3] = useState(true);

    useEffect(() => {
        const updateStepFormError = (setError, data) => {
            let hasError = Object.entries(data).some(([key, value]) => {
                if (key === 'DonationNote' && (value === '' || value === undefined)) {
                    return false; // Skip error check for DonationNote field
                }
                if (data.TransactionType === 1 && key !== 'TransactionType') {
                    return false;
                }
                if (
                    data.TransactionType === 2 && key === 'KindText' && (value !== '' && value !== undefined)
                ) {
                    return false;
                }
                if (
                    data.TransactionType === 3 &&
                    ((key === 'BankName' && value !== '') ||
                        (key === 'ChequeNo' && value !== '') ||
                        (key === 'ChequeDate' && value !== ''))
                ) {
                    return false;
                }
                if (
                    data.TransactionType === 6 &&
                    ((key === 'BankName' && value !== '') ||
                        (key === 'DemandDraftNo' && value !== '') ||
                        (key === 'DemandDraftDate' && value !== ''))
                ) {
                    return false;
                }
                if (
                    data.TransactionType === 4 &&
                    (key === 'OnlineTransactionType' || key === 'TransactionId') &&
                    (value !== '' && value !== undefined)
                ) {
                    return false;
                }
                if (
                    data.TransactionType === 5 && key === 'OthersPayText' && (value !== '' && value !== undefined)
                ) {
                    return false;
                }
                return value === '' || value === undefined;
            });

            setError(hasError);
        };

        updateStepFormError(setErrorE2, step2DataEdit);
        updateStepFormError(setErrorE1, step1DataEdit);
        updateStepFormError(setErrorE3, step3DataEdit);
    }, [step1DataEdit, step2DataEdit, step3DataEdit]);


    const handleSidebarStep = (e, value) => {
        e.preventDefault()
        setStep(value);
    };

    // Set Donation Receipt no.

    const getFinancialYear = (dateString) => {
        const date = new Date(dateString);
        if (date.getMonth() >= 3) {
            return `${date.getFullYear()}-${String(date.getFullYear() + 1).slice(2)}`;
        } else {
            return `${date.getFullYear() - 1}-${String(date.getFullYear()).slice(2)}`;
        }
    }
    useEffect(() => {
        setStep1Data({
            ...step1Data,
            receiptNo: `${nextReceiptNo}/${getFinancialYear(step1Data.DateOfDonation)}`,
            year: `${getFinancialYear(step1Data.DateOfDonation)}`
        })
    }, [nextReceiptNo, step1Data.DateOfDonation])

    // Add Donation API
    const [addedDonation, setAddedDonation] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleAddDonation = (e) => {
        e.preventDefault()
        setNextButtonClicked(true)
        setIsSubmitting(true);

        const addDonationData = new FormData()
        addDonationData.append("DateOfDonation", step1Data.DateOfDonation)
        addDonationData.append("receiptNo", step1Data.receiptNo)
        addDonationData.append("year", step1Data.year)
        addDonationData.append("DonationType", step1Data.DonationType)
        addDonationData.append("DonationAmount", step1Data.DonationAmount)
        addDonationData.append("DonationAmountInWord", step1Data.DonationAmountInWord)
        addDonationData.append("DonorName", step2Data.DonorName)
        addDonationData.append("donorMobile", step2Data.DonorMobile)
        addDonationData.append("DonorIdType", step2Data.DonorIdType)
        addDonationData.append("DonorIdNo", step2Data.DonorIdNo)
        addDonationData.append("DonorAddress", step2Data.DonorAddress)
        addDonationData.append("DonorPincode", step2Data.DonorPincode)
        addDonationData.append("TransactionType", step3Data.TransactionType)
        addDonationData.append("DonationNote", step1Data.DonationNote)
        if (step3Data.TransactionType === 2) {
            addDonationData.append("KindText", step3Data.KindText)
        } else if (step3Data.TransactionType === 3) {
            addDonationData.append("BankName", step3Data.BankName)
            addDonationData.append("ChequeNo", step3Data.ChequeNo)
            addDonationData.append("ChequeDate", step3Data.ChequeDate)
        } else if (step3Data.TransactionType === 4) {
            addDonationData.append("OnlineTransactionType", step3Data.OnlineTransactionType)
            addDonationData.append("TransactionId", step3Data.TransactionId)
        } else if (step3Data.TransactionType === 5) {
            addDonationData.append("OthersPayText", step3Data.OthersPayText)
        } else if (step3Data.TransactionType === 6) {
            addDonationData.append("BankName", step3Data.BankName)
            addDonationData.append("DemandDraftNo", step3Data.DemandDraftNo)
            addDonationData.append("DemandDraftDate", step3Data.DemandDraftDate)
        }


        stepForm1Error.TransactionTypeError = "";
        stepForm2Error.TransactionTypeError = "";
        if (
            Object.values(stepForm1Error).filter(ele => ele !== "").length === 0 &&
            Object.values(stepForm2Error).filter(ele => ele !== "").length === 0 &&
            Object.values(stepForm3Error).filter(ele => ele !== "").length === 0
        ) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            axios.post(`${getAddDonation}/${trustDetail?._id}`, addDonationData)
                .then((res) => {
                    setStep(step + 1);
                    setDonationType(3);
                    fetchdonationList();
                    toast.success(res.data.message, toastStyle);
                    setAddedDonation(res.data.result);
                    setTimeout(() => {
                        setIsSubmitting(false);
                    }, 500);
                })
                .catch((err) => {
                    toast.error(err.response.data.message, toastStyle);
                    setTimeout(() => {
                        setIsSubmitting(false);
                    }, 500);
                })
        }
    };

    // Edit Donation API
    const handleEditDonation = (e) => {
        e.preventDefault()
        setNextButtonClicked(true)

        const editDonationData = new FormData()
        editDonationData.append("DateOfDonation", step1DataEdit.DateOfDonation)
        editDonationData.append("receiptNo", step1DataEdit.receiptNo)
        editDonationData.append("year", step1DataEdit.year)
        editDonationData.append("DonationType", step1DataEdit.DonationType)
        editDonationData.append("DonationAmount", step1DataEdit.DonationAmount)
        editDonationData.append("DonationAmountInWord", step1DataEdit.DonationAmountInWord)
        editDonationData.append("DonorName", step2DataEdit.DonorName)
        editDonationData.append("DonorMobile", step2DataEdit.DonorMobile)
        editDonationData.append("DonorPincode", step2DataEdit.DonorPincode)
        editDonationData.append("DonorIdType", step2DataEdit.DonorIdType)
        editDonationData.append("DonorIdNo", step2DataEdit.DonorIdNo)
        editDonationData.append("DonorAddress", step2DataEdit.DonorAddress)
        editDonationData.append("TransactionType", step3DataEdit.TransactionType)
        editDonationData.append("DonationNote", step1DataEdit.DonationNote)
        if (step3DataEdit.TransactionType === 2) {
            editDonationData.append("KindText", step3DataEdit.KindText)
        } else if (step3DataEdit.TransactionType === 3) {
            editDonationData.append("BankName", step3DataEdit.BankName)
            editDonationData.append("ChequeNo", step3DataEdit.ChequeNo)
            editDonationData.append("ChequeDate", step3DataEdit.ChequeDate)
        } else if (step3DataEdit.TransactionType === 4) {
            editDonationData.append("OnlineTransactionType", step3DataEdit.OnlineTransactionType)
            editDonationData.append("TransactionId", step3DataEdit.TransactionId)
        } else if (step3DataEdit.TransactionType === 5) {
            editDonationData.append("OthersPayText", step3DataEdit.OthersPayText)
        } else if (step3DataEdit.TransactionType === 6) {
            editDonationData.append("BankName", step3DataEdit.BankName)
            editDonationData.append("DemandDraftNo", step3DataEdit.DemandDraftNo)
            editDonationData.append("DemandDraftDate", step3DataEdit.DemandDraftDate)
        }



        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        axios.put(`${getUpdateDonation}/${editId}`, editDonationData)
            .then((res) => {
                setStep(4)
                setDonationType(3);
                fetchdonationList();
                toast.success(res.data.message, toastStyle);
            })
            .catch((err) => {
                toast.error(err.response.data.message, toastStyle);
            })
    }

    // Add Donation Form
    const renderForm = () => {
        switch (step) {
            case 2:
                return (
                    <div className="Step-form w-100">
                        <div className="Step form-row step-1">
                            <Form.Group className="form-control w-100" controlId="DateOfDonation">
                                <Form.Label>Date Of Donation <span className="reqired-star">*</span></Form.Label>
                                <div className='group'>
                                    <Form.Control type="date" value={step1Data.DateOfDonation} placeholder="Date Of Donation" name="DateOfDonation" max={new Date().toISOString().split('T')[0]} onChange={(e) => handleDonationData(e)} />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm1Error?.DateOfDonationError}</p>}
                            </Form.Group>
                            {/* <Form.Group className="form-control w-50" controlId="DonationTo">
                                <Form.Label>Donation To <span className="reqired-star">*</span></Form.Label>
                                <div className='group'>
                                    <Form.Control type="text" value={step1Data.DonationTo} placeholder="Donation To" name="DonationTo" onChange={(e) => handleDonationData(e)} />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm1Error?.DonationToError}</p>}
                            </Form.Group> */}
                            {/* <Form.Group className="form-control w-50" controlId="receiptNo">
                                <Form.Label>Donation Receipt No <span className="reqired-star">*</span></Form.Label>
                                <div className='group'>
                                    <Form.Control type="text" value={step1Data.receiptNo} placeholder="Donation Receipt No" name="receiptNo" disabled />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm1Error?.receiptNoError}</p>}
                            </Form.Group> */}
                        </div>
                        <div className="Step form-row step-1">
                            <Form.Group className="radiobox w-100" controlId="donationType">
                                <Form.Label>Donation Type <span className="reqired-star">*</span></Form.Label>
                                <div className="paymentMethod">
                                    <div className='radio'>
                                        <Form.Check type="radio" name="DonationType" value={1} checked={donationType === 1 ? true : false} id='specific-grant' label="Specific grant" onChange={(e) => handleDonationType(e)} />
                                    </div>
                                    <div className='radio'>
                                        <Form.Check type="radio" name="DonationType" value={2} checked={donationType === 2 ? true : false} id='corpus-fund' label="Corpus Fund" onChange={(e) => handleDonationType(e)} />
                                    </div>
                                    <div className='radio'>
                                        <Form.Check type="radio" name="DonationType" value={3} checked={donationType === 3 ? true : false} id='others' label="Others" onChange={(e) => handleDonationType(e)} />
                                    </div>
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm1Error?.DonationTypeError}</p>}
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-1">
                            <Form.Group className="radiobox w-100" controlId="DonationNote">
                                <Form.Label>Donation To</Form.Label>
                                <Form.Control as="textarea" value={step1Data.DonationNote} rows={3} placeholder="Donation To" name="DonationNote" onChange={(e) => handleDonationData(e)} />
                                {nextButtonClicked && <p className="errorMessage">{stepForm1Error?.DonationNoteError}</p>}
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-1">
                            <Form.Group className="form-control w-100" controlId="DonationAmount">
                                <Form.Label>Donation Amount <span className="reqired-star">*</span></Form.Label>
                                <div className='group'>
                                    <Form.Control type="text" pattern="[0-9]*" value={step1Data.DonationAmount} placeholder="Donation Amount" min={0} name="DonationAmount" onChange={(e) => handleDonationData(e, "Amount")} />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm1Error?.DonationAmountError}</p>}
                            </Form.Group>
                            <Form.Group className="form-control w-100" controlId="DonationAmountInWord">
                                <Form.Label>Donation Amount in Words <span className="reqired-star">*</span></Form.Label>
                                <div className='group'>
                                    <Form.Control type="text" value={step1Data.DonationAmountInWord} disabled placeholder="Donation Amount in Words" name="DonorAddress" />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm1Error?.DonationAmountInWordError}</p>}
                            </Form.Group>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className="Step-form w-100">
                        <div className="Step form-row step-2">
                            <Form.Group className="form-control w-50" controlId="DonorName">
                                <Form.Label>Donor Name <span className="reqired-star">*</span></Form.Label>
                                <div className='group'>
                                    <Form.Control type="text" value={step2Data.DonorName} placeholder="Donor Name" name="DonorName" onChange={(e) => handleDonationData(e)} />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm2Error?.DonorNameError}</p>}
                            </Form.Group>

                            <Form.Group className="form-control w-50" controlId="DonorMobile">
                                <Form.Label>Donor Mobile no.</Form.Label>
                                <div className='group'>
                                    <Form.Control type="number" value={step2Data.DonorMobile} placeholder="Donor Mobile no." name="DonorMobile" onChange={(e) => handleDonationData(e)} />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-2">
                            <Form.Group className="form-control w-100" controlId="DonorAddress">
                                <Form.Label>Donor Address <span className="reqired-star">*</span></Form.Label>
                                <Form.Control as="textarea" value={step2Data.DonorAddress} rows={3} placeholder="Donor Address" name="DonorAddress" onChange={(e) => handleDonationData(e)} />
                                {nextButtonClicked && <p className="errorMessage">{stepForm2Error?.DonorAddressError}</p>}
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-2">
                            <Form.Group className="form-control w-100" controlId="DonorAddress">
                                <Form.Label>Donor Pincode <span className="reqired-star">*</span></Form.Label>
                                <Form.Control type="text" value={step2Data.DonorPincode} rows={3} placeholder="Donor Pincode" name="DonorPincode" onChange={(e) => handleDonationData(e)} />
                                {nextButtonClicked && <p className="errorMessage">{stepForm2Error?.DonorPincodeError}</p>}
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-2">
                            <Form.Group className="form-control w-50" controlId="DonorIdTypes">
                                <Form.Label>Donor ID Type <span className="reqired-star">*</span></Form.Label>
                                <Select
                                    className='customSelect'
                                    closeMenuOnSelect={true}
                                    options={DonorIdTypes}
                                    value={DonorIdType}
                                    onChange={(e) => handleDonorIdType(e)}
                                    placeholder="Donor ID Type"
                                />
                                {nextButtonClicked && <p className="errorMessage">{stepForm2Error?.DonorIdTypeError}</p>}
                            </Form.Group>
                            <Form.Group className="form-control w-50" controlId="DonorIdNo">
                                <Form.Label>Donor ID No. <span className="reqired-star">*</span></Form.Label>
                                <div className='group'>
                                    <Form.Control type="text" value={step2Data.DonorIdNo} placeholder="Donor ID No." name="DonorIdNo" onChange={(e) => handleDonationData(e)} />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm2Error?.DonorIdNoError}</p>}
                            </Form.Group>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className="Step-form w-100">
                        <div className="Step form-row step-3">
                            <Form.Group className="radiobox" controlId="paymentType">
                                <Form.Label>Donation payment method <span className="reqired-star">*</span></Form.Label>
                                <div className="paymentMethod">
                                    <div className='radio'>
                                        <Form.Check type="radio" name="PaymentType" value={1} checked={paymentType === 1 ? true : false} id='cash' label="Cash" onChange={(e) => handlePaymentType(e)} />
                                    </div>
                                    <div className='radio'>
                                        <Form.Check type="radio" name="PaymentType" value={2} checked={paymentType === 2 ? true : false} id='kind' label="Kind" onChange={(e) => handlePaymentType(e)} />
                                    </div>
                                    <div className='radio'>
                                        <Form.Check type="radio" name="PaymentType" value={3} checked={paymentType === 3 ? true : false} id='cheque' label="Cheque" onChange={(e) => handlePaymentType(e)} />
                                    </div>
                                    <div className='radio'>
                                        <Form.Check type="radio" name="PaymentType" value={4} checked={paymentType === 4 ? true : false} id='online-transaction' label="Online Transaction" onChange={(e) => handlePaymentType(e)} />
                                    </div>
                                    <div className='radio'>
                                        <Form.Check type="radio" name="PaymentType" value={5} checked={paymentType === 5 ? true : false} id='other' label="Other" onChange={(e) => handlePaymentType(e)} />
                                    </div>
                                    <div className='radio'>
                                        <Form.Check type="radio" name="PaymentType" value={6} checked={paymentType === 6 ? true : false} id='demand-draft' label="Demand Draft" onChange={(e) => handlePaymentType(e)} />
                                    </div>
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm3Error?.TransactionTypeError}</p>}
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-3">
                            {
                                paymentType === 2 ?
                                    <Form.Group className="form-control w-50" controlId="KindText">
                                        <Form.Label>Donation Kind <span className="reqired-star">*</span></Form.Label>
                                        <div className='group'>
                                            <Form.Control type="text" value={step3Data.KindText} placeholder="Donation Kind" name="KindText" onChange={(e) => handleDonationData(e)} />
                                        </div>
                                        {nextButtonClicked && <p className="errorMessage">{stepForm3Error?.KindTextError}</p>}
                                    </Form.Group>
                                    : paymentType === 3 ?
                                        <>
                                            <Form.Group className="form-control w-50" controlId="BankName">
                                                <Form.Label>Bank Name <span className="reqired-star">*</span></Form.Label>
                                                <div className='group'>
                                                    <Form.Control type="text" value={step3Data.BankName} placeholder="Bank Name" name="BankName" onChange={(e) => handleDonationData(e)} />
                                                </div>
                                                {nextButtonClicked && <p className="errorMessage">{stepForm3Error?.BankNameError}</p>}
                                            </Form.Group>
                                            <Form.Group className="form-control w-50" controlId="ChequeNo">
                                                <Form.Label>Cheque No. <span className="reqired-star">*</span></Form.Label>
                                                <div className='group'>
                                                    <Form.Control type="text" value={step3Data.ChequeNo} placeholder="Cheque No." name="ChequeNo" onChange={(e) => handleDonationData(e)} />
                                                </div>
                                                {nextButtonClicked && <p className="errorMessage">{stepForm3Error?.ChequeNoError}</p>}
                                            </Form.Group>
                                            <Form.Group className="form-control w-50" controlId="ChequeDate">
                                                <Form.Label>Cheque Date. <span className="reqired-star">*</span></Form.Label>
                                                <div className='group'>
                                                    <Form.Control type="date" value={step3Data.ChequeDate} placeholder="Cheque Date." name="ChequeDate" onChange={(e) => handleDonationData(e)} />
                                                </div>
                                                {nextButtonClicked && <p className="errorMessage">{stepForm3Error?.ChequeDateError}</p>}
                                            </Form.Group>
                                        </>
                                        : paymentType === 4 ?
                                            <>
                                                <Form.Group className="form-control w-100" controlId="onlineTransType">
                                                    <Form.Label>Online transaction type <span className="reqired-star">*</span></Form.Label>
                                                    <div className="paymentMethod">
                                                        <div className='radio'>
                                                            <Form.Check type="radio" name="OnlineTransactionType" value={1} checked={onlineTransType === 1 ? true : false} id='UPI' label="UPI" onChange={(e) => handleOnlineTransType(e)} />
                                                        </div>
                                                        <div className='radio'>
                                                            <Form.Check type="radio" name="OnlineTransactionType" value={2} checked={onlineTransType === 2 ? true : false} id='NEFT' label="NEFT" onChange={(e) => handleOnlineTransType(e)} />
                                                        </div>
                                                        <div className='radio'>
                                                            <Form.Check type="radio" name="OnlineTransactionType" value={3} checked={onlineTransType === 3 ? true : false} id='RTGS' label="RTGS" onChange={(e) => handleOnlineTransType(e)} />
                                                        </div>
                                                        <div className='radio'>
                                                            <Form.Check type="radio" name="OnlineTransactionType" value={4} checked={onlineTransType === 4 ? true : false} id='IMPS' label="IMPS" onChange={(e) => handleOnlineTransType(e)} />
                                                        </div>
                                                        <div className='radio'>
                                                            <Form.Check type="radio" name="OnlineTransactionType" value={5} checked={onlineTransType === 5 ? true : false} id='ECS' label="ECS" onChange={(e) => handleOnlineTransType(e)} />
                                                        </div>
                                                        <div className='radio'>
                                                            <Form.Check type="radio" name="OnlineTransactionType" value={6} checked={onlineTransType === 6 ? true : false} id='MICR' label="MICR" onChange={(e) => handleOnlineTransType(e)} />
                                                        </div>
                                                        <div className='radio'>
                                                            <Form.Check type="radio" name="OnlineTransactionType" value={7} checked={onlineTransType === 7 ? true : false} id='Others' label="Others" onChange={(e) => handleOnlineTransType(e)} />
                                                        </div>
                                                    </div>
                                                    {nextButtonClicked && <p className="errorMessage">{stepForm3Error?.OnlineTransactionTypeError}</p>}
                                                </Form.Group>
                                                <Form.Group className="form-control w-100" controlId="TransactionId">
                                                    <Form.Label>Transaction Id</Form.Label>
                                                    <div className='group'>
                                                        <Form.Control type="text" value={step3Data.TransactionId} placeholder="Transaction Id" name="TransactionId" onChange={(e) => handleDonationData(e)} />
                                                    </div>
                                                </Form.Group>
                                            </>
                                            : paymentType === 5 ?
                                                <Form.Group className="form-control w-50" controlId="OthersPayText">
                                                    <Form.Label>Other Payment Method <span className="reqired-star">*</span></Form.Label>
                                                    <div className='group'>
                                                        <Form.Control type="text" value={step3Data.OthersPayText} placeholder="Other Payment Method" name="OthersPayText" onChange={(e) => handleDonationData(e)} />
                                                    </div>
                                                    {nextButtonClicked && <p className="errorMessage">{stepForm3Error?.OthersPayTextError}</p>}
                                                </Form.Group>
                                                : paymentType === 6 ?
                                                    <>
                                                        <Form.Group className="form-control w-50" controlId="BankName">
                                                            <Form.Label>Bank Name <span className="reqired-star">*</span></Form.Label>
                                                            <div className='group'>
                                                                <Form.Control type="text" value={step3Data.BankName} placeholder="Bank Name" name="BankName" onChange={(e) => handleDonationData(e)} />
                                                            </div>
                                                            {nextButtonClicked && <p className="errorMessage">{stepForm3Error?.BankNameError}</p>}
                                                        </Form.Group>
                                                        <Form.Group className="form-control w-50" controlId="DemandDraftNo">
                                                            <Form.Label>Demand Draft No. <span className="reqired-star">*</span></Form.Label>
                                                            <div className='group'>
                                                                <Form.Control type="text" value={step3Data.DemandDraftNo} placeholder="Demand Draft No." name="DemandDraftNo" onChange={(e) => handleDonationData(e)} />
                                                            </div>
                                                            {nextButtonClicked && <p className="errorMessage">{stepForm3Error?.DemandDraftNoError}</p>}
                                                        </Form.Group>
                                                        <Form.Group className="form-control w-50" controlId="DemandDraftDate">
                                                            <Form.Label>Demand Draft Date. <span className="reqired-star">*</span></Form.Label>
                                                            <div className='group'>
                                                                <Form.Control type="date" value={step3Data.DemandDraftDate} placeholder="Demand Draft Date." name="DemandDraftDate" onChange={(e) => handleDonationData(e)} />
                                                            </div>
                                                            {nextButtonClicked && <p className="errorMessage">{stepForm3Error?.DemandDraftDateError}</p>}
                                                        </Form.Group>
                                                    </> : ""
                            }
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className="Step-form successBlock w-100">
                        <div className="success-checkmark">
                            <div className="check-icon">
                                <span className="icon-line line-tip"></span>
                                <span className="icon-line line-long"></span>
                                <div className="icon-circle"></div>
                                <div className="icon-fix"></div>
                            </div>
                        </div>
                        <h3 className="center">Donation <b style={{ color: '#1A73E8' }}>{addedDonation && addedDonation.receiptNo}</b> Added Sucessfully</h3>
                    </div>
                );
            default:
                return null;
        }
    };

    const renderButtons = () => {
        if (step === 1) {
            return (
                <Modal.Footer>
                    <Button auto className="common-btn" onClick={handleNext}>
                        Next
                    </Button>
                </Modal.Footer>
            );
        } else if (step === 3) {
            return (
                <Modal.Footer>
                    <Button auto className="common-btn light" color="error" onClick={handleBack}>
                        Back
                    </Button>
                    <Button auto className="common-btn" disabled={isSubmitting} onClick={(e) => handleAddDonation(e)}>
                        Submit {isSubmitting ? <></> : ""}
                    </Button>
                </Modal.Footer>
            );
        } else if (step === 4) {
            return (
                <Modal.Footer>
                    <Button auto className="common-btn light" color="error" onClick={closeHandler}>
                        Close
                    </Button>
                </Modal.Footer>
            );
        } else {
            return (
                <Modal.Footer>
                    <Button auto className="common-btn light" color="error" onClick={handleBack}>
                        Back
                    </Button>
                    <Button auto className="common-btn" onClick={handleNext}>
                        Next
                    </Button>
                </Modal.Footer>
            );
        }
    };

    // Edit Donation Form
    const renderFormEdit = () => {
        switch (step) {
            case 2:
                return (
                    <div className="Step-form w-100">
                        <div className="Step form-row step-1">
                            <Form.Group className="form-control w-50" controlId="DateOfDonation">
                                <Form.Label>Date Of Donation <span className="reqired-star">*</span></Form.Label>
                                <div className='group'>
                                    <Form.Control type="date" value={step1DataEdit.DateOfDonation} placeholder="Date Of Donation" name="DateOfDonation" onChange={(e) => handleDonationDataEdit(e)} />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm1ErrorEdit?.DateOfDonationError}</p>}
                            </Form.Group>
                            {/* <Form.Group className="form-control w-50" controlId="DonationTo">
                                <Form.Label>Donation To</Form.Label>
                                <div className='group'>
                                    <Form.Control type="text" value={step1DataEdit.DonationTo} placeholder="Donation To" name="DonationTo" onChange={(e) => handleDonationDataEdit(e)} />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm1ErrorEdit?.DonationToError}</p>}
                            </Form.Group> */}
                            <Form.Group className="form-control w-50" controlId="receiptNo">
                                <Form.Label>Donation Receipt No <span className="reqired-star">*</span></Form.Label>
                                <div className='group'>
                                    <Form.Control type="text" value={step1DataEdit.receiptNo} placeholder="Donation Receipt Generator No" name="receiptNo" disabled />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm1ErrorEdit?.receiptNoError}</p>}
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-1">
                            <Form.Group className="radiobox w-100" controlId="donationType">
                                <Form.Label>Donation Type <span className="reqired-star">*</span></Form.Label>
                                <div className="paymentMethod">
                                    <div className='radio'>
                                        <Form.Check type="radio" name="DonationType" value={1} checked={donationType === 1 ? true : false} id='specific-grant' label="Specific grant" onChange={(e) => handleDonationType(e)} />
                                    </div>
                                    <div className='radio'>
                                        <Form.Check type="radio" name="DonationType" value={2} checked={donationType === 2 ? true : false} id='corpus-fund' label="Corpus Fund" onChange={(e) => handleDonationType(e)} />
                                    </div>
                                    <div className='radio'>
                                        <Form.Check type="radio" name="DonationType" value={3} checked={donationType === 3 ? true : false} id='others' label="Others" onChange={(e) => handleDonationType(e)} />
                                    </div>
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm1ErrorEdit?.DonationTypeError}</p>}
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-1">
                            <Form.Group className="radiobox w-100" controlId="DonationNote">
                                <Form.Label>Donation To </Form.Label>
                                <Form.Control as="textarea" value={step1DataEdit.DonationNote} rows={3} placeholder="Donation To" name="DonationNote" onChange={(e) => handleDonationDataEdit(e)} />
                                {nextButtonClicked && <p className="errorMessage">{stepForm1ErrorEdit?.DonationNoteError}</p>}
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-1">
                            <Form.Group className="form-control w-100" controlId="DonationAmount">
                                <Form.Label>Donation Amount <span className="reqired-star">*</span></Form.Label>
                                <div className='group'>
                                    <Form.Control type="text" pattern="[0-9]*" value={step1DataEdit.DonationAmount} placeholder="Donation Amount" name="DonationAmount" onChange={(e) => handleDonationDataEdit(e, "Amount")} />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm1ErrorEdit?.DonationAmountError}</p>}
                            </Form.Group>

                            <Form.Group className="form-control w-100" controlId="DonationAmountInWord">
                                <Form.Label>Donation Amount in Words <span className="reqired-star">*</span></Form.Label>
                                <div className='group'>
                                    <Form.Control type="text" value={step1DataEdit.DonationAmountInWord} disabled placeholder="Donation Amount in Words" name="DonorAddress" />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm1ErrorEdit?.DonationAmountInWordError}</p>}
                            </Form.Group>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className="Step-form w-100">
                        <div className="Step form-row step-2">
                            <Form.Group className="form-control w-50" controlId="DonorName">
                                <Form.Label>Donor Name <span className="reqired-star">*</span></Form.Label>
                                <div className='group'>
                                    <Form.Control type="text" value={step2DataEdit.DonorName} placeholder="Donor Name" name="DonorName" onChange={(e) => handleDonationDataEdit(e)} />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm2ErrorEdit?.DonorNameError}</p>}
                            </Form.Group>

                            <Form.Group className="form-control w-50" controlId="DonorMobile">
                                <Form.Label>Donor Mobile no.</Form.Label>
                                <div className='group'>
                                    <Form.Control type="number" value={step2DataEdit.DonorMobile} placeholder="Donor Mobile no." name="DonorMobile" onChange={(e) => handleDonationDataEdit(e)} />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-2">
                            <Form.Group className="form-control w-100" controlId="DonorAddress">
                                <Form.Label>Donor Address <span className="reqired-star">*</span></Form.Label>
                                <Form.Control as="textarea" value={step2DataEdit.DonorAddress} rows={3} placeholder="Donor Address" name="DonorAddress" onChange={(e) => handleDonationDataEdit(e)} />
                                {nextButtonClicked && <p className="errorMessage">{stepForm2ErrorEdit?.DonorAddressError}</p>}
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-2">
                            <Form.Group className="form-control w-100" controlId="DonorPincode">
                                <Form.Label>Donor Pincode <span className="reqired-star">*</span></Form.Label>
                                <Form.Control type="text" value={step2DataEdit.DonorPincode} rows={3} placeholder="Donor Pincode" name="DonorPincode" onChange={(e) => handleDonationDataEdit(e)} />
                                {nextButtonClicked && <p className="errorMessage">{stepForm2ErrorEdit?.DonorPincodeError}</p>}
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-2">
                            <Form.Group className="form-control w-50" controlId="DonorIdTypes">
                                <Form.Label>Donor ID Type <span className="reqired-star">*</span></Form.Label>
                                <Select
                                    className='customSelect'
                                    closeMenuOnSelect={true}
                                    options={DonorIdTypes}
                                    value={DonorIdType}
                                    onChange={(e) => handleDonorIdType(e)}
                                    placeholder="Donor ID Type"
                                />
                                {nextButtonClicked && <p className="errorMessage">{stepForm2ErrorEdit?.DonorIdTypeError}</p>}
                            </Form.Group>
                            <Form.Group className="form-control w-50" controlId="DonorIdNo">
                                <Form.Label>Donor ID No. <span className="reqired-star">*</span></Form.Label>
                                <div className='group'>
                                    <Form.Control type="text" value={step2DataEdit.DonorIdNo} placeholder="Donor ID No." name="DonorIdNo" onChange={(e) => handleDonationDataEdit(e)} />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm2ErrorEdit?.DonorIdNoError}</p>}
                            </Form.Group>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className="Step-form w-100">
                        <div className="Step form-row step-3">
                            <Form.Group className="radiobox" controlId="paymentType">
                                <Form.Label>Donation payment method <span className="reqired-star">*</span></Form.Label>
                                <div className="paymentMethod">
                                    <div className='radio'>
                                        <Form.Check type="radio" name="PaymentType" value={1} checked={paymentType === 1 ? true : false} id='cash' label="Cash" onChange={(e) => handlePaymentType(e)} />
                                    </div>
                                    <div className='radio'>
                                        <Form.Check type="radio" name="PaymentType" value={2} checked={paymentType === 2 ? true : false} id='kind' label="Kind" onChange={(e) => handlePaymentType(e)} />
                                    </div>
                                    <div className='radio'>
                                        <Form.Check type="radio" name="PaymentType" value={3} checked={paymentType === 3 ? true : false} id='cheque' label="Cheque" onChange={(e) => handlePaymentType(e)} />
                                    </div>
                                    <div className='radio'>
                                        <Form.Check type="radio" name="PaymentType" value={4} checked={paymentType === 4 ? true : false} id='online-transaction' label="Online Transaction" onChange={(e) => handlePaymentType(e)} />
                                    </div>
                                    <div className='radio'>
                                        <Form.Check type="radio" name="PaymentType" value={5} checked={paymentType === 5 ? true : false} id='other' label="Other" onChange={(e) => handlePaymentType(e)} />
                                    </div>
                                    <div className='radio'>
                                        <Form.Check type="radio" name="PaymentType" value={6} checked={paymentType === 6 ? true : false} id='demand-draft' label="Demand Draft" onChange={(e) => handlePaymentType(e)} />
                                    </div>
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{setStepForm3Error?.TransactionTypeError}</p>}
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-3">
                            {
                                paymentType === 2 ?
                                    <Form.Group className="form-control w-50" controlId="KindText">
                                        <Form.Label>Donation Kind <span className="reqired-star">*</span></Form.Label>
                                        <div className='group'>
                                            <Form.Control type="text" value={step3DataEdit.KindText} placeholder="Donation Kind" name="KindText" onChange={(e) => handleDonationDataEdit(e)} />
                                        </div>
                                        {nextButtonClicked && <p className="errorMessage">{stepForm3ErrorEdit?.KindTextError}</p>}
                                    </Form.Group>
                                    : paymentType === 3 ?
                                        <>
                                            <Form.Group className="form-control w-50" controlId="BankName">
                                                <Form.Label>Bank Name <span className="reqired-star">*</span></Form.Label>
                                                <div className='group'>
                                                    <Form.Control type="text" value={step3DataEdit.BankName} placeholder="Bank Name" name="BankName" onChange={(e) => handleDonationDataEdit(e)} />
                                                </div>
                                                {nextButtonClicked && <p className="errorMessage">{stepForm3ErrorEdit?.BankNameError}</p>}
                                            </Form.Group>
                                            <Form.Group className="form-control w-50" controlId="ChequeNo">
                                                <Form.Label>Cheque No. <span className="reqired-star">*</span></Form.Label>
                                                <div className='group'>
                                                    <Form.Control type="text" value={step3DataEdit.ChequeNo} placeholder="Cheque No." name="ChequeNo" onChange={(e) => handleDonationDataEdit(e)} />
                                                </div>
                                                {nextButtonClicked && <p className="errorMessage">{stepForm3ErrorEdit?.ChequeNoError}</p>}
                                            </Form.Group>
                                            <Form.Group className="form-control w-50" controlId="ChequeDate">
                                                <Form.Label>Cheque Date. <span className="reqired-star">*</span></Form.Label>
                                                <div className='group'>
                                                    <Form.Control type="date" value={step3DataEdit.ChequeDate} placeholder="Cheque Date." name="ChequeDate" onChange={(e) => handleDonationDataEdit(e)} />
                                                </div>
                                                {nextButtonClicked && <p className="errorMessage">{stepForm3ErrorEdit?.ChequeDateError}</p>}
                                            </Form.Group>
                                        </>
                                        : paymentType === 4 ?
                                            <>
                                                <Form.Group className="form-control w-100" controlId="onlineTransType">
                                                    <Form.Label>Online transaction type <span className="reqired-star">*</span></Form.Label>
                                                    <div className="paymentMethod">
                                                        <div className='radio'>
                                                            <Form.Check type="radio" name="OnlineTransactionType" value={1} checked={onlineTransType === 1 ? true : false} id='UPI' label="UPI" onChange={(e) => handleOnlineTransType(e)} />
                                                        </div>
                                                        <div className='radio'>
                                                            <Form.Check type="radio" name="OnlineTransactionType" value={2} checked={onlineTransType === 2 ? true : false} id='NEFT' label="NEFT" onChange={(e) => handleOnlineTransType(e)} />
                                                        </div>
                                                        <div className='radio'>
                                                            <Form.Check type="radio" name="OnlineTransactionType" value={3} checked={onlineTransType === 3 ? true : false} id='RTGS' label="RTGS" onChange={(e) => handleOnlineTransType(e)} />
                                                        </div>
                                                        <div className='radio'>
                                                            <Form.Check type="radio" name="OnlineTransactionType" value={4} checked={onlineTransType === 4 ? true : false} id='IMPS' label="IMPS" onChange={(e) => handleOnlineTransType(e)} />
                                                        </div>
                                                        <div className='radio'>
                                                            <Form.Check type="radio" name="OnlineTransactionType" value={5} checked={onlineTransType === 5 ? true : false} id='ECS' label="ECS" onChange={(e) => handleOnlineTransType(e)} />
                                                        </div>
                                                        <div className='radio'>
                                                            <Form.Check type="radio" name="OnlineTransactionType" value={6} checked={onlineTransType === 6 ? true : false} id='MICR' label="MICR" onChange={(e) => handleOnlineTransType(e)} />
                                                        </div>
                                                        <div className='radio'>
                                                            <Form.Check type="radio" name="OnlineTransactionType" value={7} checked={onlineTransType === 7 ? true : false} id='Others' label="Others" onChange={(e) => handleOnlineTransType(e)} />
                                                        </div>
                                                    </div>
                                                    {nextButtonClicked && <p className="errorMessage">{stepForm3ErrorEdit?.OnlineTransactionTypeError}</p>}
                                                </Form.Group>
                                                <Form.Group className="form-control w-100" controlId="TransactionId">
                                                    <Form.Label>Transaction Id</Form.Label>
                                                    <div className='group'>
                                                        <Form.Control type="text" value={step3DataEdit.TransactionId} placeholder="Transaction Id" name="TransactionId" onChange={(e) => handleDonationDataEdit(e)} />
                                                    </div>
                                                </Form.Group>
                                            </>
                                            : paymentType === 5 ?
                                                <Form.Group className="form-control w-50" controlId="OthersPayText">
                                                    <Form.Label>Other Payment Method <span className="reqired-star">*</span></Form.Label>
                                                    <div className='group'>
                                                        <Form.Control type="text" value={step3DataEdit.OthersPayText} placeholder="Other Payment Method" name="OthersPayText" onChange={(e) => handleDonationDataEdit(e)} />
                                                    </div>
                                                    {nextButtonClicked && <p className="errorMessage">{stepForm3ErrorEdit?.OthersPayTextError}</p>}
                                                </Form.Group>
                                                : paymentType === 6 ?
                                                    <>
                                                        <Form.Group className="form-control w-50" controlId="BankName">
                                                            <Form.Label>Bank Name <span className="reqired-star">*</span></Form.Label>
                                                            <div className='group'>
                                                                <Form.Control type="text" value={step3DataEdit.BankName} placeholder="Bank Name" name="BankName" onChange={(e) => handleDonationDataEdit(e)} />
                                                            </div>
                                                            {nextButtonClicked && <p className="errorMessage">{stepForm3ErrorEdit?.BankNameError}</p>}
                                                        </Form.Group>
                                                        <Form.Group className="form-control w-50" controlId="DemandDraftNo">
                                                            <Form.Label>Demand Draft No. <span className="reqired-star">*</span></Form.Label>
                                                            <div className='group'>
                                                                <Form.Control type="text" value={step3DataEdit.DemandDraftNo} placeholder="Demand Draft No." name="DemandDraftNo" onChange={(e) => handleDonationDataEdit(e)} />
                                                            </div>
                                                            {nextButtonClicked && <p className="errorMessage">{stepForm3ErrorEdit?.DemandDraftNoError}</p>}
                                                        </Form.Group>
                                                        <Form.Group className="form-control w-50" controlId="DemandDraftDate">
                                                            <Form.Label>Demand Draft Date. <span className="reqired-star">*</span></Form.Label>
                                                            <div className='group'>
                                                                <Form.Control type="date" value={step3DataEdit.DemandDraftDate} placeholder="Demand Draft Date." name="DemandDraftDate" onChange={(e) => handleDonationDataEdit(e)} />
                                                            </div>
                                                            {nextButtonClicked && <p className="errorMessage">{stepForm3ErrorEdit?.DemandDraftDateError}</p>}
                                                        </Form.Group>
                                                    </> : ""
                            }
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className="Step-form successBlock w-100">
                        <div className="success-checkmark">
                            <div className="check-icon">
                                <span className="icon-line line-tip"></span>
                                <span className="icon-line line-long"></span>
                                <div className="icon-circle"></div>
                                <div className="icon-fix"></div>
                            </div>
                        </div>
                        <h3 className="center">Donation Edited Sucessfully</h3>
                    </div>
                );
            default:
                return null;
        }
    };

    const renderButtonsEdit = () => {
        if (step === 1) {
            return (
                <Modal.Footer>
                    <Button auto className="common-btn" onClick={handleNextEdit}>
                        Next
                    </Button>
                </Modal.Footer>
            );
        } else if (step === 3) {
            return (
                <Modal.Footer>
                    <Button auto className="common-btn light" color="error" onClick={handleBack}>
                        Back
                    </Button>
                </Modal.Footer>
            );
        } else if (step === 4) {
            return (
                ""
            );
        } else {
            return (
                <Modal.Footer>
                    <Button auto className="common-btn light" color="error" onClick={handleBack}>
                        Back
                    </Button>
                    <Button auto className="common-btn" onClick={handleNextEdit}>
                        Next
                    </Button>
                </Modal.Footer>
            );
        }
    };

    // Export Popup
    const [visiblePass, setVisiblePass] = React.useState(false);
    const handlerPass = () => {
        setVisiblePass(true)
    };
    const closeHandlerPass = () => {
        setVisiblePass(false)
        setExportType(1)
    };

    // View Donation Popup
    const [visibleView, setVisibleView] = React.useState(false);
    const handlerView = () => {
        setVisibleView(true)
    };
    const closeHandlerView = () => {
        setVisibleView(false)
    };

    // Export Date Range
    const [exportDate, setExportDate] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);

    // Export Filter
    const [exportFilter, setExportFilter] = useState({
        startDate: "",
        endDate: "",
        filterType: ""
    })

    // Handle Export Type
    const [exportType, setExportType] = useState(1)
    const handleExportType = (e) => {
        if (e.target.checked) {
            setExportType(1)
            setExportFilter({
                ...exportFilter,
                startDate: "",
                endDate: "",
                filterType: ""
            })
        } else {
            setExportType(2)
            setExportFilter({
                ...exportFilter,
                startDate: exportDate[0].startDate.toISOString(),
                endDate: exportDate[0].endDate.toISOString(),
                filterType: filterType
            })
        }
    }

    useEffect(() => {
        if (exportType === 2) {
            setExportFilter({
                ...exportFilter,
                startDate: exportDate[0].startDate.toISOString(),
                endDate: exportDate[0].endDate.toISOString()
            })
        } else {
            setExportFilter({
                ...exportFilter,
                startDate: "",
                endDate: "",
                filterType: ""
            })
        }
    }, [exportDate, exportType])

    // Export List API
    const handleExportList = (e) => {
        e.preventDefault();
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        axios.get(`${getDownloadTrustCSV}/${trustDetail?._id}?filterType=${exportFilter.filterType}&startDate=${exportFilter.startDate}&endDate=${exportFilter.endDate}`)
            .then((res) => {
                const url = `https://${res.data.result}`;
                const link = document.createElement('a');
                link.href = url;
                link.download = url
                link.setAttribute('download', "demo.csv");
                link.click();
                toast.success("Donation list download Sucessfully", toastStyle);
            })
            .catch((err) => {
                toast.warn("Something went wrong please try again !", toastStyle);
            })
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="trust-border">
                <div className="title_text roles-header">
                    <h4>Total Donation : {totalDonation ? totalDonation : 0}</h4>
                    <div className="button-for-trust-actions">
                        <Button variant="contained" className="fillter_btn common-btn" onClick={handler} startIcon={<AiOutlinePlus />} >
                            New Donation
                        </Button>
                        <Button variant="contained" className="fillter_btn common-btn" onClick={handlerPass} startIcon={<CiExport />} >
                            Export List
                        </Button>
                    </div>
                </div>
                <div className="innerusercontent">
                    <div className="Filteruser">
                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                                <React.Fragment>
                                    <Button variant="contained" className='fillter_btn common-btn' {...bindTrigger(popupState)} startIcon={<MdTune />} px={2}>Filter</Button>
                                    <Menu {...bindMenu(popupState)}>
                                        <div className="filter-model">
                                            <div className="filter-buttons">
                                                <Button className='rst-Btn-Lnk btn common-btn light' autoFocus onClick={(e) => handleResetFilter(e)} > Reset </Button>
                                                <Button className='aply-Btn-Lnk btn common-btn dark' autoFocus onClick={(e) => handleDateFilter(e)}> Apply </Button>
                                            </div>
                                        </div>
                                        <div className='HL'></div>
                                        <div className="datePiker">
                                            <div className="filter-type">
                                                <Form.Label>Filter By</Form.Label>
                                                <div className="paymentMethod">
                                                    <div className='radio'>
                                                        <Form.Check type="radio" name="filterType" value={1} checked={filterType === 1 ? true : false} id='date-of-donation' label="Date Of Donation" onChange={(e) => handleFilterType(e)} />
                                                    </div>
                                                    <div className='radio'>
                                                        <Form.Check type="radio" name="filterType" value={2} checked={filterType === 2 ? true : false} id='created-at' label="Created At" onChange={(e) => handleFilterType(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <DateRange
                                                    editableDateInputs={true}
                                                    onChange={item => setState([item.selection])}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={state}
                                                    rangeColors={['linear-gradient(195deg, #49a3f1, #1A73E8)', '#3ecf8e', '#fed14c']}
                                                />
                                            </div>
                                        </div>
                                    </Menu>
                                </React.Fragment>
                            )}
                        </PopupState>
                    </div>
                    <div className='searchbaar'>
                        <div className='serach_icon'>
                            <div className="serach_icon">
                                <CiSearch />
                            </div>
                        </div>
                        <InputBase
                            className="inputsearchbase base-css-trust"
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(e) => handleSearch(e)}
                        />
                    </div>
                </div>
                <MDBox py={3}>
                    <MDBox mt={1}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <MDBox mb={3} >
                                    <Box sx={{ height: 630, width: '100%', }} >
                                        <DataGrid
                                            rows={donationList}
                                            columns={columns}
                                            pageSize={pageState.pageSize}
                                        />
                                    </Box>
                                    <div className='pagination-block'>
                                        <ResultInfo />
                                        <Pagination
                                            loop
                                            color="success"
                                            total={pageState.totalPages}
                                            initialPage={1}
                                            onChange={(page) => handlePageChange(page)}
                                            currentPage={pageState.page}
                                        />
                                    </div>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            </div>

            {/* ADD Donation Model */}
            <Modal closeButton blur preventClose aria-labelledby="modal-title" className="model-with-sidebar" width={1100} open={visible} onClose={closeHandler} >
                <Modal.Header justify="start">
                    <Text id="modal-title" size={24}>
                        Add new<Text b size={24}> Trust </Text>
                    </Text>
                </Modal.Header>
                <div className="AddDataModel">
                    <div className="modelSidebat">
                        <ul>
                            <li><a href="#" className={`step ${step === 1 ? "active" : ""} ${!error2 ? "success" : ""}`} ><span className="mobile-only">1</span><span>Step 1 :</span><span>Donor Information</span></a></li>
                            <li><a href="#" className={`step ${step === 2 ? "active" : ""} ${!error1 ? "success" : ""}`} ><span className="mobile-only">2</span><span>Step 2 :</span><span>Donation Details</span></a></li>
                            <li><a href="#" className={`step ${step === 3 ? "active" : ""} ${!error3 ? "success" : ""}`} ><span className="mobile-only">3</span><span>Step 3 :</span><span>Donation Payment Details</span></a></li>
                        </ul>
                    </div>
                    <div className="modelContent">
                        <Modal.Body>
                            <Row>
                                {renderForm()}
                            </Row>
                        </Modal.Body>
                        {renderButtons()}
                    </div>
                </div>
            </Modal>

            {/* EDIT Donation Model */}
            <Modal closeButton blur preventClose aria-labelledby="modal-title" className="AddDataModel model-with-sidebar" width={1100} open={visibleEdit} onClose={closeHandlerEdit} >
                <Modal.Header justify="start">
                    <Text id="modal-title" size={24}>
                        Edit Donation<Text b size={24}> {`( ${step1DataEdit.receiptNo} )`} </Text>
                    </Text>
                </Modal.Header>
                <div className="AddDataModel">
                    {step !== 4 ?
                        <div className="modelSidebat">
                            <ul>
                                <li><a href="#" onClick={(e) => handleSidebarStep(e, 1)} className={`step ${step === 1 ? "active" : ""} ${!errorE2 ? "success" : ""}`} ><span className="mobile-only">1</span><span>Step 1 :</span><span>Donor Information</span></a></li>
                                <li><a href="#" onClick={(e) => handleSidebarStep(e, 2)} className={`step ${step === 2 ? "active" : ""} ${!errorE1 ? "success" : ""}`} ><span className="mobile-only">2</span><span>Step 2 :</span><span>Donation Details</span></a></li>
                                <li><a href="#" onClick={(e) => handleSidebarStep(e, 3)} className={`step ${step === 3 ? "active" : ""} ${!errorE3 ? "success" : ""}`} ><span className="mobile-only">3</span><span>Step 3 :</span><span>Donation Payment Details</span></a></li>
                            </ul>
                        </div> : ""}
                    <div className={step === 4 ? "modelContent w-100" : "modelContent"}>
                        <Modal.Body>
                            <Row>
                                {renderFormEdit()}
                            </Row>
                        </Modal.Body>
                        {renderButtonsEdit()}
                    </div>
                </div>
                <div className="modelBottom">
                    {step === 4 ?
                        <Button auto className="common-btn light" color="error" onClick={closeHandlerEdit}>
                            Close
                        </Button> :
                        <Button auto className="common-btn" onClick={(e) => handleEditDonation(e)}>
                            Submit
                        </Button>}
                </div>
            </Modal>

            {/* Export Donation List */}
            <Modal closeButton blur preventClose aria-labelledby="modal-title" className="AddDataModel" width={600} open={visiblePass} onClose={closeHandlerPass} >
                <Modal.Header justify="start">
                    <Text id="modal-title" size={24}>
                        Export Donation List of<Text b size={24}>  </Text>
                    </Text>
                </Modal.Header>
                <Modal.Body>
                    <div className="exportType">
                        <Form.Group className="radiobox w-50" controlId="donationType">
                            <Form.Label>Export All Donations</Form.Label>
                            <div className="paymentMethod">
                                <Switch bordered size="lg" color="warning" checked={exportType === 1 ? true : false} onChange={(e) => handleExportType(e)} />
                            </div>
                        </Form.Group>
                    </div>
                    {exportType === 2 ?
                        <>
                            <div className="filter-type">
                                <Form.Label>Filter By</Form.Label>
                                <div className="paymentMethod">
                                    <div className='radio'>
                                        <Form.Check type="radio" name="filterType" value={1} checked={filterType === 1 ? true : false} id='date-of-donation' label="Date Of Donation" onChange={(e) => handleFilterType(e)} />
                                    </div>
                                    <div className='radio'>
                                        <Form.Check type="radio" name="filterType" value={2} checked={filterType === 2 ? true : false} id='created-at' label="Created At" onChange={(e) => handleFilterType(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className="export-list-filter">
                                <DateRange
                                    className="w-100"
                                    editableDateInputs={true}
                                    onChange={item => setExportDate([item.selection])}
                                    moveRangeOnFirstSelection={false}
                                    ranges={exportDate}
                                    rangeColors={['linear-gradient(195deg, #49a3f1, #1A73E8)', '#3ecf8e', '#fed14c']}
                                />
                            </div>
                        </> : ""}
                </Modal.Body>
                <Modal.Footer>
                    <Button auto className="common-btn light" color="error" onClick={closeHandlerPass}>
                        cancel
                    </Button>
                    <Button auto className="common-btn" onClick={(e) => handleExportList(e)}>
                        Export List
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* View Donation List */}
            <Modal closeButton blur scroll aria-labelledby="modal-title" className="AddDataModel" width={1000} open={visibleView} onClose={closeHandlerView} >
                <Modal.Header justify="start">
                    <Text id="modal-title" size={24}>
                        Donation Details<Text b size={24}>  </Text>
                    </Text>
                </Modal.Header>
                <Modal.Body>
                    <div className="view-donation row">
                        <div className="view-donation-col">
                            <div className="view-tital">
                                <h2>Donor Information</h2>
                            </div>
                            <ul>
                                <li>
                                    <span>Donor Name :</span> {viewDonation.DonorName}
                                </li>
                                <li>
                                    <span>Donor Mobile No. :</span> {viewDonation.DonorMobile}
                                </li>
                                <li>
                                    <span>Donor Address :</span> {viewDonation.DonorAddress}
                                </li>
                                <li>
                                    <span>Donor Pincode :</span> {viewDonation.DonorPincode}
                                </li>
                                <li>
                                    <span>Donor ID type :</span> {viewDonation.DonorIdType?.label}
                                </li>
                                <li>
                                    <span>Donor ID No. :</span> {viewDonation.DonorIdNo}
                                </li>
                            </ul>
                        </div>
                        <div className="view-donation-col">
                            <div className="view-tital">
                                <h2>Donation Information</h2>
                            </div>
                            <ul>
                                <li>
                                    <span>Date Of Donation :</span> {viewDonation.DateofDonation}
                                </li>
                                <li>
                                    <span>Donation Receipt No. :</span> {viewDonation.receiptNo}
                                </li>
                                <li>
                                    <span>Donation Type :</span> {viewDonation.DonationType?.label}
                                </li>
                                {viewDonation.DonationType?.value === 3 ?
                                    <li>
                                        <span>Donation Note :</span> {viewDonation.TypeDonation?.DonationNote}
                                    </li> : ""}
                                <li>
                                    <span>Donation Amount :</span> {viewDonation.DonationAmount}
                                </li>
                                <li>
                                    <span>Donation Amount In Words :</span> {viewDonation.DonationAmountInWord}
                                </li>
                            </ul>
                        </div>
                        <div className="view-donation-col">
                            <div className="view-tital">
                                <h2>Donation Payment Information</h2>
                            </div>
                            <ul>
                                <li>
                                    <span>Donation Payment Method :</span> {viewDonation.paymentType?.label}
                                </li>
                                {viewDonation.paymentType?.value === 2 ?
                                    <li>
                                        <span>Donation Kind :</span> {viewDonation?.TransactionDetails}
                                    </li> : viewDonation.paymentType?.value === 3 ?
                                        <>
                                            <li>
                                                <span>Bank Name :</span> {viewDonation.TransactionDetails?.BankName}
                                            </li>
                                            <li>
                                                <span>Cheque No. :</span> {viewDonation.TransactionDetails?.ChequeNo}
                                            </li>
                                            <li>
                                                <span>Cheque Date :</span> {viewDonation.TransactionDetails?.ChequeDate}
                                            </li>
                                        </> : viewDonation.paymentType?.value === 6 ?
                                            <>
                                                <li>
                                                    <span>Bank Name :</span> {viewDonation.TransactionDetails?.BankName}
                                                </li>
                                                <li>
                                                    <span>Demand Draft No. :</span> {viewDonation.TransactionDetails?.DemandDraftNo}
                                                </li>
                                                <li>
                                                    <span>Demand Draft Date :</span> {viewDonation.TransactionDetails?.DemandDraftDate}
                                                </li>
                                            </> : viewDonation.paymentType?.value === 4 ?
                                                <>
                                                    <li>
                                                        <span>Online Transaction type :</span> {viewDonation.onlineTransactionType?.label}
                                                    </li>
                                                    <li>
                                                        <span>Transaction Id :</span> {viewDonation.TransactionDetails?.TransactionId}
                                                    </li>
                                                </> : viewDonation.paymentType?.value === 5 ?
                                                    <li>
                                                        <span>Other Payment Method :</span> {viewDonation.onlineTransactionType?.label}
                                                    </li> : ""
                                }
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button auto className="common-btn light" color="error" onClick={closeHandlerView}>
                        close
                    </Button>
                </Modal.Footer>
            </Modal>


            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </DashboardLayout>
    )
}

export default Trust