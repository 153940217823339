// @mui material components
import './style/Support.css'

// Donation Receipt Generator React components
import MDBox from "components/MDBox";

// Donation Receipt Generator React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Overview page components
import Header from "layouts/profile/components/Header";
import { userToken } from 'Token';
import axios from 'axios';
import { getTrustDetailsPatch } from 'Api';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import mailImg from 'assets/images/mail-dv.png'
import phoneImg from 'assets/images/Phone.png'


function Overview() {
  // Variables
  const [trustDetail, setTrustDetail] = useState({})

  const token = userToken();

  // Add Trust Strp Form
  const [sectionCodes, setSectionCodes] = useState([
    { value: 1, label: 'Section 80G' },
    { value: 2, label: 'Section 35(1)(iia)' },
    { value: 3, label: 'Section 35(1)(ii)' },
    { value: 4, label: 'Section 35(1)(iii)' },
  ])

  // Get Trust Details
  const fetchTrustDetails = () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.patch(getTrustDetailsPatch)
      .then((res) => {

        const temp = sectionCodes.find((ele) => {
          return ele.value === res.data.user.TrustSectionCode
        })
        res.data.user.trustSecCode = temp.label
        setTrustDetail(res.data.user)
      })
  }
  useEffect(() => {
    if(token !== "" && token !== undefined) {
      fetchTrustDetails()
    }
  },[token])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <div className="supportPage">
        <p>If you have any doubts about the panel, or change your Trust Login password. Additionally, if you find any bugs in the panel, please Contact us.</p>
        <div className="contact-info">
          <div className='supportblock'>
            <div className='supportItem'>
              <h1>
                <span className="info-title">
                  <img alt="" src={phoneImg} className="info-icon" />&nbsp;<Link to="tel:+918141832384">+91 81418 32384</Link>
                </span>
              </h1>
            </div>
            <div className='supportItem'>
              <h1>
                <span className="info-title">
                  <img alt="" src={mailImg} className="info-icon" />&nbsp;<Link to="mailto:kartikeybshah@gmail.com">kartikeybshah@gmail.com</Link>
                </span>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
