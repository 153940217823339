import React, { useState } from "react";
import '../Trust/Style/trust.css'
import './Style/Admin.css'
import Button from "@mui/material/Button";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { FormControlLabel, IconButton } from "@material-ui/core";
import { bindMenu } from 'material-ui-popup-state/hooks'
import { bindTrigger } from 'material-ui-popup-state/hooks'
import { CiSearch } from 'react-icons/ci'
import InputBase from '@material-ui/core/InputBase';
import { AiFillEye, AiOutlineEye, AiOutlinePlus } from "react-icons/ai";
import { FaUserEdit } from "react-icons/fa";
import Menu from "@mui/material/Menu";
import { CiExport } from "react-icons/ci"
import { RiLockPasswordFill, RiLockPasswordLine } from "react-icons/ri"
import PopupState from "material-ui-popup-state";
import { MdTune } from 'react-icons/md'
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { addDays, startOfDay } from 'date-fns';
import { Modal, Pagination, Row, Text } from "@nextui-org/react";
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import DefaultImage from '../../assets/images/DefaultProfile.png'
import DefaultSignature from '../../assets/images/DefaultSignature.png'
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined'
import Footer from "layouts/authentication/components/Footer";
import { useEffect } from "react";
import { userToken } from "Token";
import axios from "axios";
import { getTrustList, getImageUpload, getAddTrust } from "Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getTrustDetails } from "Api";
import { getUpdateTrust } from "Api";
import { getTrustUpdatePassword } from "Api";
import { Crypt } from 'hybrid-crypto-js';
import { formatDateFilter } from "layouts/Trust/Errors/Errors";
import { NavLink, useNavigate } from "react-router-dom";
import { getTrustDelete } from "Api";
import { isValidPAN } from "layouts/Trust/Errors/Validation";
import { isValidGST } from "layouts/Trust/Errors/Validation";
import { isValidURN } from "layouts/Trust/Errors/Validation";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { isValidEmailAddress } from "Token";
import { getDeleteTrust } from "Api";


const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
}

function Admin() {
    var crypt = new Crypt();
    const navigate = useNavigate()
    // Variables
    const [trustList, setTrustList] = useState([])
    const token = userToken();
    const [search, setSearch] = useState('')
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);
    const [filter, setFilter] = useState({
        dateStart: "",
        dateEnd: ""
    })

    // Edit Password
    const [editTrustPassword, setEditTrustPassword] = useState({
        _id: "",
        TrustName: "",
    })

    // Add trust
    const [step1Data, setStep1Data] = useState({
        trustLogo: "",
        TrustSign: "",
        TrustName: "",
        TrustCity: ""
    })
    const [step2Data, setStep2Data] = useState({
        TrustEmail: "",
        TrustMobile: "",
        TrustFax: "",
        TrustWebsite: "",
        TrustAddress: "",
        TrustNote: "",
    })
    const [step3Data, setStep3Data] = useState({
        TrustPan: "",
        TrustGST: "",
        TrustSectionCode: "",
        TrustUrnNo: "",
        TrustUrnRegistrationDate: "",
    })
    const [step4Data, setStep4Data] = useState({
        Password: "",
    })
    const [stepData, setStepData] = useState({
        TrustFormNo: "",
        AYStart: "",
        AYStop: "",
        TrustRegnNo: "",
        YearType: "",
        TrustClause: ''
    })

    // Edit Trust
    const [step1DataEdit, setStep1DataEdit] = useState({
        id: "",
        trustLogo: "",
        TrustSign: "",
        TrustName: "",
        TrustCity: "",
        Pass: ""
    })
    const [step2DataEdit, setStep2DataEdit] = useState({
        TrustEmail: "",
        TrustMobile: "",
        TrustFax: "",
        TrustWebsite: "",
        TrustAddress: "",
        TrustNote: "",
    })
    const [step3DataEdit, setStep3DataEdit] = useState({
        TrustPan: "",
        TrustGST: "",
        TrustSectionCode: "",
        TrustUrnNo: "",
        TrustUrnRegistrationDate: "",
    })
    const [step4DataEdit, setStep4DataEdit] = useState({
        Password: "",
    })
    const [stepDataEdit, setStepDataEdit] = useState({
        TrustFormNo: "",
        AYStart: "",
        AYStop: "",
        TrustRegnNo: "",
        YearType: "",
        TrustClause: ""
    })

    const columns = [
        { field: 'srno', headerName: 'ID', width: 90 },
        { field: 'TrustName', headerName: 'Name', width: 350, editable: false, },
        { field: 'totalDonationAmount', headerName: 'Total Donation', width: 350, editable: false, },
        { field: 'formattedDate', headerName: 'Created At', width: 350, editable: false, },
        {
            field: 'action', headerName: 'Action', width: 300, editable: false,
            renderCell: (prevent) => {
                return (
                    <div
                        className="d-flex justify-content-between align-items-center trust"
                        style={{ cursor: "pointer" }}
                    >
                        <MatEdit index={prevent.row?._id} />
                        <MatView className="ViewDonation" index={prevent.row?._id} />
                        <MatChangePass index={prevent.row} />
                        <MatDelete index={prevent.row} />
                    </div>
                );
            },
        }
    ];

    const MatView = ({ index }) => {
        return (
            <FormControlLabel
                control={
                    <NavLink to={`/admin/trust-list/${index}`} color="secondary" aria-label="add an alarm" className="view-donation-of-trust" >
                        <IconButton color="secondary" aria-label="add an alarm" className="view-donation-of-trust" >
                            <AiOutlineEye />
                        </IconButton>
                    </NavLink>
                }
            />
        );
    };

    const MatEdit = ({ index }) => {
        const handleEditClick = () => {
            handlerEdit();
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            axios.get(`${getTrustDetails}/${index}`)
                .then((res) => {
                    const editData = res.data.result
                    if (editData.TrustLogo.includes('http://') || editData.TrustLogo.includes('https://')) {
                        setTrustLogoImage({
                            url: editData.TrustLogo,
                            uploaded: true
                        });
                    } else {
                        setTrustLogoImage({
                            url: `https://${editData.TrustLogo}`,
                            uploaded: true
                        });
                    }
                    setTrustSignatureImage({
                        url: `https://${editData.TrustSign}`,
                        uploaded: true
                    });
                    const decPass = crypt.decrypt(process.env.REACT_APP_PRIVATEKEY, editData.Pass)

                    setStep1DataEdit({
                        ...step1DataEdit,
                        id: editData?._id,
                        trustLogo: editData.TrustLogo,
                        TrustSign: editData.TrustSign,
                        TrustName: editData.TrustName,
                        TrustCity: editData.TrustCity,
                        Pass: decPass && decPass?.message
                    })
                    setStep2DataEdit({
                        ...step2DataEdit,
                        TrustEmail: editData.TrustEmail,
                        TrustMobile: editData.TrustMobile,
                        TrustFax: editData.TrustFax,
                        TrustWebsite: editData.TrustWebsite,
                        TrustAddress: editData.TrustAddress,
                        TrustNote: editData.TrustNote,
                    })
                    setStep3DataEdit({
                        ...step3DataEdit,
                        TrustPan: editData.TrustPan,
                        TrustGST: editData.TrustGST,
                        TrustSectionCode: editData.TrustSectionCode,
                        TrustUrnNo: editData.TrustUrnNo,
                        // TrustUrnRegistrationDate: convertEditDateFormat(editData.TrustUrnRegistrationDate),
                        TrustUrnRegistrationDate: editData.TrustUrnRegistrationDate,
                    })
                    setStepDataEdit({
                        ...stepDataEdit,
                        TrustFormNo: editData.TrustNote.TrustFormNo,
                        TrustClause: editData.TrustNote.TrustClause,
                        AYStart: editData.TrustNote.AYStart,
                        AYStop: editData.TrustNote.AYStop,
                        TrustRegnNo: editData.TrustNote.TrustRegnNo,
                        YearType: editData.TrustNote.YearType,
                    })
                    setStep4DataEdit({
                        ...step4DataEdit,
                        Password: editData.Password
                    })
                    const sectionVal = sectionCodes.find((ele) => { return ele.value == editData.TrustSectionCode })
                    setSectionValue(sectionVal)

                    const newFormNo = formNo.find((ele) => { return ele.label === editData.TrustNote.TrustFormNo })
                    setFormNoValue(newFormNo)

                    const newTrustClause = clause.find((ele) => { return ele.value == editData.TrustNote.TrustClause })
                    setClauseValue(newTrustClause)

                    const newAYStart = ayOptions.find((ele) => { return ele.label === editData.TrustNote.AYStart })
                    setAyStart(newAYStart)

                    const newAYStop = ayOptions.find((ele) => { return ele.label === editData.TrustNote.AYStop })
                    setAyStop(newAYStop)

                    const AYVal = AYType.find((ele) => { return ele.value == editData.TrustNote.YearType })
                    setAYValue(AYVal)
                })
        }

        return (
            <FormControlLabel
                control={
                    <>
                        <IconButton color="secondary" aria-label="add an alarm" onClick={(e) => handleEditClick(e, index)} >
                            <ModeEditOutlineOutlinedIcon />
                        </IconButton>
                    </>
                }
            />
        );
    };

    const MatChangePass = ({ index }) => {
        const handleEditClick = () => {
            handlerPass();
            setEditTrustPassword({
                ...editTrustPassword,
                _id: index?._id,
                TrustName: index.TrustName
            })
        }

        return (
            <FormControlLabel
                control={
                    <>
                        <IconButton color="secondary" aria-label="add an alarm" className="view-donation-of-trust" onClick={(e) => handleEditClick(e)} >
                            <RiLockPasswordLine />
                        </IconButton>
                    </>
                }
            />
        );
    };

    const MatDelete = ({ index, i }) => {
        const [visible, setVisible] = React.useState(false);
        const handler = () => {
            setVisible(true)
        };
        const closeHandler = () => {
            setVisible(false);
            fetchTrustList()
        };
        const handleDeleteDonation = (e) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            axios.delete(`${getDeleteTrust}/${index?._id}`)
                .then((res) => {
                    toast.success(res.data?.message, toastStyle);
                    closeHandler()
                })
                .catch((err) => {
                    toast.error(err.response.data?.message, toastStyle);
                })
        }

        return (
            <FormControlLabel
                control={
                    <>
                        <div className="ActionButtons">
                            <IconButton color="secondary" aria-label="add an alarm" onClick={(e) => handler(e)} className="dyFlextIcon" >
                                <DeleteOutlinedIcon />
                            </IconButton>
                            <div className="delete-popup">
                                <Modal closeButton aria-labelledby="modal-title" className="AddDataModel" width={700} open={visible} onClose={closeHandler} >
                                    <Modal.Header justify="start">
                                        <Text id="modal-title" size={28}>
                                            Are You <Text b size={28}> Sure? </Text>
                                        </Text>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Text id="modal-title" size={28}>
                                            You Want to Delete <Text b size={28}> {index.TrustName} </Text> trust Record?
                                        </Text>
                                        <Text id="modal-title" size={24}>
                                            Do you really want to delete these records? You can't view this in your list anymore if you delete!
                                        </Text>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button auto flat color="error" className="common-btn light" onClick={closeHandler}>
                                            No, Keep it
                                        </Button>
                                        <Button auto className="common-btn" onClick={(e) => handleDeleteDonation(e)}>
                                            Yes, Delete it
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </>
                }
            />
        );
    };

    // Date Filter
    // Handle Filter by
    const [filterType, setFilterType] = useState(1)

    const handleFilterType = (e) => {
        setFilterType(parseInt(e.target.value))
    }
    const handleDateFilter = () => {
        setFilter({
            ...filter,
            dateStart: formatDateFilter(new Date(state[0].startDate)),
            dateEnd: formatDateFilter(new Date(state[0].endDate))
        })
    }

    // Pagination
    const [pageState, setPageState] = useState({
        total: 0,
        totalPages: 0,
        page: 1,
        pageSize: 10
    })
    const handlePageChange = (selectedPage) => {
        setPageState((prevState) => ({ ...prevState, page: selectedPage }));
    };
    const ResultInfo = () => {
        const start = (pageState.page - 1) * pageState.pageSize + 1;
        const end = Math.min(start + pageState.pageSize - 1, pageState.total);

        return (
            <div className="result-info">
                Showing <span>{start}-{end}</span> of <span>{pageState.total}</span> Trusts
            </div>
        );
    };

    // Reset Filter
    const handleResetFilter = () => {
        setFilter({
            dateStart: "",
            dateEnd: ""
        })
        setState([
            {
                startDate: startOfDay(new Date()),
                endDate: addDays(startOfDay(new Date()), 0),
                key: 'selection'
            }
        ]);
    }

    // Search 
    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    // Get All Trust List
    const fetchTrustList = () => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        axios.get(`${getTrustList}?page=${pageState.page}&limit=${pageState.pageSize}&search=${search}&filterType=${filterType}&dateStart=${filter.dateStart}&dateEnd=${filter.dateEnd}`)
            .then((res) => {
                const json = res.data.result;
                setPageState((prevState) => ({ ...prevState, total: json.totalDocs, totalPages: json.totalPages }));

                res.data.result.docs.map((ele, i) => {
                    ele.id = i + 1;
                    ele.srno = (res.data.result.page * 10) + (i + 1) - 10;

                    const dateString = ele.createdAt;
                    ele.formattedDate = new Date(dateString).toLocaleDateString('en-GB');

                })
                setTrustList(res.data.result.docs)
            })
    }
    useEffect(() => {
        fetchTrustList()
    }, [search, filter, pageState.page, pageState.pageSize])

    // Add trust model Variable
    const [visible, setVisible] = React.useState(false);
    const handler = () => {
        setVisible(true)
        setStep(1)
    };
    const closeHandler = () => {
        setVisible(false)
        setTrustLogoImage({
            url: DefaultImage,
            uploaded: false
        })
        setStep1Data({
            trustLogo: "",
            TrustSign: "",
            TrustName: ""
        })
        setStep2Data({
            TrustEmail: "",
            TrustMobile: "",
            TrustFax: "",
            TrustWebsite: "",
            TrustAddress: "",
            TrustNote: "",
        })
        setStep3Data({
            TrustPan: "",
            TrustGST: "",
            TrustSectionCode: "",
            TrustUrnNo: "",
            TrustUrnRegistrationDate: "",
        })
        setStep4Data({
            Password: "",
        })
        setSectionValue([])
        setPassword("")
        setConfirmPassword('')
        setError1(true)
        setError2(true)
        setError3(true)
        setTimeout(() => {
            setStep(1)
        }, 2000)
    };

    // Edit trust model Variables
    const [visibleEdit, setVisibleEdit] = React.useState(false);
    const handlerEdit = () => {
        setVisibleEdit(true)
        setStep(1)
    };
    const closeHandlerEdit = () => {
        setVisibleEdit(false)
        setTrustLogoImage({
            url: DefaultImage,
            uploaded: false
        })
        setStep1DataEdit({
            trustLogo: "",
            TrustSign: "",
            TrustName: ""
        })
        setStep2DataEdit({
            TrustEmail: "",
            TrustMobile: "",
            TrustFax: "",
            TrustWebsite: "",
            TrustAddress: "",
            TrustNote: "",
        })
        setStep3DataEdit({
            TrustPan: "",
            TrustGST: "",
            TrustSectionCode: "",
            TrustUrnNo: "",
            TrustUrnRegistrationDate: "",
        })
        setSectionValue([])
        setPassword("")
        setConfirmPassword("")
        setErrorE1(false)
        setErrorE2(false)
        setErrorE3(false)
        setTimeout(() => {
            setStep(1)
        }, 2000)
    };

    // Edit trust Password model Variables
    const [visiblePass, setVisiblePass] = React.useState(false);
    const handlerPass = () => {
        setVisiblePass(true)
    };
    const closeHandlerPass = () => {
        setVisiblePass(false)
        setConfirmPasswordEdit("")
        setPasswordEdit("")
    };

    const handleUpdatePassword = () => {
        let encryptedPassword = crypt.encrypt(process.env.REACT_APP_KEY, confirmPasswordEdit);

        const PasswordData = new FormData();
        PasswordData.append("password", encryptedPassword)


        if (!errorPass && confirmPasswordEdit !== "") {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            axios.put(`${getTrustUpdatePassword}/${editTrustPassword?._id}`, PasswordData)
                .then((res) => {
                    closeHandlerPass()
                    toast.success(res.data?.message, toastStyle);
                }).catch((err) => {
                    toast.error(err.response.data?.message, toastStyle);
                })
        }
    }

    // Trust Signature
    const uploadedSignature = React.useRef(null);
    const imageUploaderSignature = React.useRef(null);
    const [trustSignatureImage, setTrustSignatureImage] = useState({
        url: DefaultSignature,
        uploaded: false
    });

    const handleSignatureUpload = (e, data) => {
        const [file] = e.target.files;

        if (file) {
            const reader = new FileReader();
            const { current } = uploadedSignature;
            current.file = file;
            reader.onload = e => {
                current.src = e.target.result;
            };
            reader.readAsDataURL(file);
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            const userImage = new FormData();
            userImage.append('Image', file);
            axios.post(getImageUpload, userImage)
                .then((res) => {
                    if (res.data.urlArray[0].includes('http://') || res.data.urlArray[0].includes('https://')) {
                        setTrustSignatureImage({
                            url: res.data.urlArray[0],
                            uploaded: true
                        });
                    } else {
                        setTrustSignatureImage({
                            url: `https://${res.data.urlArray[0]}`,
                            uploaded: true
                        });
                    }
                    if (data === "add") {
                        setStep1Data({
                            ...step1Data,
                            TrustSign: res.data.urlArray[0]
                        });
                    }
                    if (data === "Edit") {
                        setStep1DataEdit({
                            ...step1DataEdit,
                            TrustSign: res.data.urlArray[0]
                        });
                    }
                });
        }
    };

    // Trust Logo
    const uploadedImage = React.useRef(null);
    const imageUploader = React.useRef(null);
    const [trustLogoImage, setTrustLogoImage] = useState({
        url: DefaultImage,
        uploaded: false
    });

    const handleImageUpload = (e, data) => {
        const [file] = e.target.files;

        if (file) {
            const reader = new FileReader();
            const { current } = uploadedImage;
            current.file = file;
            reader.onload = e => {
                current.src = e.target.result;
            };
            reader.readAsDataURL(file);
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            const userImage = new FormData();
            userImage.append('Image', file);
            axios.post(getImageUpload, userImage)
                .then((res) => {
                    if (res.data.urlArray[0].includes('http://') || res.data.urlArray[0].includes('https://')) {
                        setTrustLogoImage({
                            url: res.data.urlArray[0],
                            uploaded: true
                        });
                    } else {
                        setTrustLogoImage({
                            url: `https://${res.data.urlArray[0]}`,
                            uploaded: true
                        });
                    }

                    if (data === "add") {
                        setStep1Data({
                            ...step1Data,
                            trustLogo: res.data.urlArray[0]
                        });
                    }
                    if (data === "Edit") {
                        setStep1DataEdit({
                            ...step1DataEdit,
                            trustLogo: res.data.urlArray[0]
                        });
                    }
                });
        }
    };


    // Add Trust Strp Form
    const [sectionCodes, setSectionCodes] = useState([
        { value: 1, label: 'Section 80G' },
        { value: 2, label: 'Section 35(1)(iia)' },
        { value: 3, label: 'Section 35(1)(ii)' },
        { value: 4, label: 'Section 35(1)(iii)' },
    ])

    const [sectionValue, setSectionValue] = useState([]);
    const handleSectionCode = (e, data) => {
        const sectionVal = sectionCodes.find((ele) => { return ele.value == e.value })
        setSectionValue(sectionVal)
        if (data === "SectionCode") {
            setStep3Data({
                ...step3Data,
                TrustSectionCode: e.value
            })
        }
        if (data === "SectionCodeEdit") {
            setStep3DataEdit({
                ...step3DataEdit,
                TrustSectionCode: e.value
            })
        }
    }
    // AY Or Date For Donation Note
    const [AYType, setAYType] = useState([
        { value: 1, label: 'AY' },
        { value: 2, label: 'Date' },
    ])

    const [AYValue, setAYValue] = useState({ value: 1, label: 'AY' });
    console.log(AYValue);
    const handleAYValue = (e, data) => {
        setAyStart([])
        const AYVal = AYType.find((ele) => { return ele.value == e.value })
        setAYValue(AYVal)
        if (data === "add") {
            setStepData({
                ...stepData,
                YearType: e.value
            })
        }
        if (data === "edit") {
            setStepDataEdit({
                ...stepDataEdit,
                YearType: e.value
            })
        }
    }

    // Add Trust Form No
    const [formNo, setFormNo] = useState([
        { value: 1, label: '10 AC' },
        { value: 2, label: '10 AD' },
    ])

    const [formNoValue, setFormNoValue] = useState([]);
    const handleFormNoCode = (e, data) => {
        const value = formNo.find((ele) => { return ele.value == e.value })
        setFormNoValue(value)

        if (data === "FormNo") {
            setStepData({
                ...stepData,
                TrustFormNo: value.label
            })
        }
        if (data === "FormNoEdit") {
            setStepDataEdit({
                ...stepDataEdit,
                TrustFormNo: value.label
            })
        }
    }

    // Add clause No
    const [clause, setClause] = useState([
        { value: 1, label: 'Clause (i)' },
        { value: 3, label: 'Clause (iii)' },
        { value: 4, label: 'Clause (iv)' },
    ])

    const [clauseValue, setClauseValue] = useState([]);
    const handleClauseCode = (e, data) => {
        const value = clause.find((ele) => { return ele.value == e.value })
        setClauseValue(value)

        if (data === "clause") {
            setStepData({
                ...stepData,
                TrustClause: value.value
            })
        }
        if (data === "clauseEdit") {
            setStepDataEdit({
                ...stepDataEdit,
                TrustClause: value.value
            })
        }
    }

    // AY Years
    const ayOptions = [];
    for (let year = 2000; year <= 2100; year++) {
        const academicYear = `${year}-${(year + 1).toString().slice(-2)}`;
        const option = { value: year - 2000 + 1, label: academicYear };
        ayOptions.push(option);
    }
    const [ayStart, setAyStart] = useState([]);
    const [ayStop, setAyStop] = useState([]);

    const handleAyDate = (e, data) => {
        if (data === "add") {
            setStepData({
                ...stepData,
                AYStart: e.target.value
            })
        }
        if (data === "edit") {
            setStepDataEdit({
                ...stepDataEdit,
                AYStart: e.target.value
            })
        }
    }

    const handleAyYear = (e, data, type) => {
        if (data === "Start") {
            const sectionVal = ayOptions.find((ele) => { return ele.value == e.value })
            setAyStart(sectionVal)
            setStepData({
                ...stepData,
                AYStart: sectionVal.label
            })
        }
        if (data === "Stop") {
            const sectionVal = ayOptions.find((ele) => { return ele.value == e.value })
            setAyStop(sectionVal)
            setStepData({
                ...stepData,
                AYStop: sectionVal.label
            })
        }
        if (type === "Edit") {
            if (data === "Start") {
                const sectionVal = ayOptions.find((ele) => { return ele.value == e.value })
                setAyStart(sectionVal)
                setStepDataEdit({
                    ...stepDataEdit,
                    AYStart: sectionVal.label
                })
            }
            if (data === "Stop") {
                const sectionVal = ayOptions.find((ele) => { return ele.value == e.value })
                setAyStop(sectionVal)
                setStepDataEdit({
                    ...stepDataEdit,
                    AYStop: sectionVal.label
                })
            }
        }
    }

    // Set Errors

    const [step, setStep] = useState(1);
    const [stepForm1Error, setStepForm1Error] = useState({
        logoError: "",
        signError: "",
        cityError: "",
        nameError: ""
    });
    const [stepForm2Error, setStepForm2Error] = useState({
        emailError: "",
        mobileError: "",
        faxError: "",
        websiteError: "",
        addressError: "",
        noteError: "",
    })
    const [stepForm3Error, setStepForm3Error] = useState({
        panError: "",
        GSTError: "",
        sectionCodeError: "",
        urnNoError: "",
        urnRegistrationDateError: "",
    })
    const [stepForm1ErrorEdit, setStepForm1ErrorEdit] = useState({
        logoError: "",
        signError: "",
        cityError: "",
        nameError: ""
    });
    const [stepForm2ErrorEdit, setStepForm2ErrorEdit] = useState({
        emailError: "",
        mobileError: "",
        faxError: "",
        websiteError: "",
        addressError: "",
        noteError: "",
    })
    const [stepForm3ErrorEdit, setStepForm3ErrorEdit] = useState({
        panError: "",
        GSTError: "",
        sectionCodeError: "",
        urnNoError: "",
        urnRegistrationDateError: "",
    })
    const [formError, stepFormError] = useState("")

    // Check Errors
    useEffect(() => {
        if (step === 1) {
            // if (step1Data.trustLogo === "") {
            //     setStepForm1Error((prevState) => ({
            //         ...prevState,
            //         logoError: "Please Upload Trust Logo"
            //     }));
            // } else {
            //     setStepForm1Error((prevState) => ({
            //         ...prevState,
            //         logoError: ""
            //     }));
            // }

            // if (step1Data.TrustSign === "") {
            //     setStepForm1Error((prevState) => ({
            //         ...prevState,
            //         signError: "Please Upload Trust Signature"
            //     }));
            // } else {
            //     setStepForm1Error((prevState) => ({
            //         ...prevState,
            //         signError: ""
            //     }));
            // }

            if (step1Data.TrustName === "") {
                setStepForm1Error((prevState) => ({
                    ...prevState,
                    nameError: "Please Enter Trust Name"
                }));
            } else {
                setStepForm1Error((prevState) => ({
                    ...prevState,
                    nameError: ""
                }));
            }

            if (step1Data.TrustCity === "") {
                setStepForm1Error((prevState) => ({
                    ...prevState,
                    cityError: "Please Enter Trust City"
                }));
            } else {
                setStepForm1Error((prevState) => ({
                    ...prevState,
                    cityError: ""
                }));
            }
        }

        if (step === 2) {
            if (step2Data.TrustEmail === "") {
                setStepForm2Error((prevState) => ({
                    ...prevState,
                    emailError: "Please enter Trust Email"
                }));
            } else if (!isValidEmailAddress(step2Data.TrustEmail)) {
                setStepForm2Error((prevState) => ({
                    ...prevState,
                    emailError: "Please enter valid Email"
                }));
            } else {
                setStepForm2Error((prevState) => ({
                    ...prevState,
                    emailError: ""
                }));
            }

            if (!step2Data.TrustMobile) {
                setStepForm2Error((prevState) => ({
                    ...prevState,
                    mobileError: "Please enter Donor Mobile number"
                }));
            } else if (step2Data.TrustMobile.length !== 10) {
                setStepForm2Error((prevState) => ({
                    ...prevState,
                    mobileError: "Please enter a valid 10-digit Donor Mobile number"
                }));
            } else {
                setStepForm2Error((prevState) => ({
                    ...prevState,
                    mobileError: ""
                }));
            }

            // if (step2Data.TrustFax === "") {
            //     setStepForm2Error((prevState) => ({
            //         ...prevState,
            //         faxError: "Please enter Trust Fax no."
            //     }));
            // } else {
            //     setStepForm2Error((prevState) => ({
            //         ...prevState,
            //         faxError: ""
            //     }));
            // }

            // if (step2Data.TrustWebsite === "") {
            //     setStepForm2Error((prevState) => ({
            //         ...prevState,
            //         websiteError: "Please enter Trust Website"
            //     }));
            // } else {
            //     setStepForm2Error((prevState) => ({
            //         ...prevState,
            //         websiteError: ""
            //     }));
            // }

            if (step2Data.TrustAddress === "") {
                setStepForm2Error((prevState) => ({
                    ...prevState,
                    addressError: "Please enter Trust Address"
                }));
            } else {
                setStepForm2Error((prevState) => ({
                    ...prevState,
                    addressError: ""
                }));
            }

            // if (step2Data.TrustNote === "") {
            //     setStepForm2Error((prevState) => ({
            //         ...prevState,
            //         noteError: "Please enter Trust Note"
            //     }));
            // } else {
            //     setStepForm2Error((prevState) => ({
            //         ...prevState,
            //         noteError: ""
            //     }));
            // }
        }

        if (step === 3) {
            if (step3Data.TrustPan === "") {
                setStepForm3Error((prevState) => ({
                    ...prevState,
                    panError: "Please enter Trust Permanent Account Number (PAN)"
                }));
            } else if (!isValidPAN(step3Data.TrustPan)) {
                setStepForm3Error((prevState) => ({
                    ...prevState,
                    panError: "Please Enter a valid Permanent Account Number (PAN)"
                }));
            } else {
                setStepForm3Error((prevState) => ({
                    ...prevState,
                    panError: ""
                }));
            }

            // if (step3Data.TrustGST === "") {
            //     setStepForm3Error((prevState) => ({
            //         ...prevState,
            //         GSTError: "Please enter Trust GST"
            //     }));
            // } else if (!isValidGST(step3Data.TrustGST)) {
            //     setStepForm3Error((prevState) => ({
            //         ...prevState,
            //         GSTError: "Please Enter a valid GST no."
            //     }));
            // } else {
            //     setStepForm3Error((prevState) => ({
            //         ...prevState,
            //         GSTError: ""
            //     }));
            // }

            if (step3Data.TrustSectionCode === "") {
                setStepForm3Error((prevState) => ({
                    ...prevState,
                    sectionCodeError: "Please enter Trust Section Code"
                }));
            } else {
                setStepForm3Error((prevState) => ({
                    ...prevState,
                    sectionCodeError: ""
                }));
            }

            if (step3Data.TrustUrnNo === "") {
                setStepForm3Error((prevState) => ({
                    ...prevState,
                    urnNoError: "Please enter Trust URN number"
                }));
            } else if (!isValidURN(step3Data.TrustUrnNo)) {
                setStepForm3Error((prevState) => ({
                    ...prevState,
                    urnNoError: "Please Enter a valid Trust URN number"
                }));
            } else {
                setStepForm3Error((prevState) => ({
                    ...prevState,
                    urnNoError: ""
                }));
            }

            if (step3Data.TrustUrnRegistrationDate === "") {
                setStepForm3Error((prevState) => ({
                    ...prevState,
                    urnRegistrationDateError: "Please enter Trust URN Registration Date"
                }));
            } else {
                setStepForm3Error((prevState) => ({
                    ...prevState,
                    urnRegistrationDateError: ""
                }));
            }
        }
        if (step === 4) {
            const { TrustFormNo, AYStart, AYStop, TrustRegnNo, TrustClause } = stepData;
            // const { TrustPan, TrustUrnNo, TrustUrnRegistrationDate } = step3Data;

            if (TrustFormNo === "" || AYStart === "" || AYStop === "" || TrustRegnNo === "" || TrustClause === "") {
                stepFormError("Please enter all data properly");
            } else {
                stepFormError("");
            }
        }

    }, [step, step1Data, step2Data, step3Data, stepData]);

    // Check Errors
    useEffect(() => {
        if (step === 1) {
            // if (step1DataEdit.trustLogo === "") {
            //     setStepForm1ErrorEdit((prevState) => ({
            //         ...prevState,
            //         logoError: "Please Upload Trust Logo"
            //     }));
            // } else {
            //     setStepForm1ErrorEdit((prevState) => ({
            //         ...prevState,
            //         logoError: ""
            //     }));
            // }

            // if (step1DataEdit.TrustSign === "") {
            //     setStepForm1ErrorEdit((prevState) => ({
            //         ...prevState,
            //         signError: "Please Upload Trust SignError"
            //     }));
            // } else {
            //     setStepForm1ErrorEdit((prevState) => ({
            //         ...prevState,
            //         signError: ""
            //     }));
            // }

            if (step1DataEdit.TrustName === "") {
                setStepForm1ErrorEdit((prevState) => ({
                    ...prevState,
                    nameError: "Please Enter Trust Name"
                }));
            } else {
                setStepForm1ErrorEdit((prevState) => ({
                    ...prevState,
                    nameError: ""
                }));
            }

            if (step1DataEdit.TrustCity === "") {
                setStepForm1ErrorEdit((prevState) => ({
                    ...prevState,
                    cityError: "Please Enter Trust Name"
                }));
            } else {
                setStepForm1ErrorEdit((prevState) => ({
                    ...prevState,
                    cityError: ""
                }));
            }
        }

        if (step === 2) {
            if (step2DataEdit.TrustEmail === "") {
                setStepForm2ErrorEdit((prevState) => ({
                    ...prevState,
                    emailError: "Please enter Trust Email"
                }));
            } else if (isValidEmailAddress(step2Data.TrustEmail)) {
                setStepForm2Error((prevState) => ({
                    ...prevState,
                    emailError: "Please enter valid Email"
                }));
            } else {
                setStepForm2ErrorEdit((prevState) => ({
                    ...prevState,
                    emailError: ""
                }));
            }

            if (!step2DataEdit.TrustMobile) {
                setStepForm2ErrorEdit((prevState) => ({
                    ...prevState,
                    mobileError: "Please enter Donor Mobile number"
                }));
            } else if (step2DataEdit.TrustMobile.length !== 10) {
                setStepForm2ErrorEdit((prevState) => ({
                    ...prevState,
                    mobileError: "Please enter a valid 10-digit Donor Mobile number"
                }));
            } else {
                setStepForm2ErrorEdit((prevState) => ({
                    ...prevState,
                    mobileError: ""
                }));
            }

            // if (step2DataEdit.TrustFax === "") {
            //     setStepForm2ErrorEdit((prevState) => ({
            //         ...prevState,
            //         faxError: "Please enter Trust Fax no."
            //     }));
            // } else {
            //     setStepForm2ErrorEdit((prevState) => ({
            //         ...prevState,
            //         faxError: ""
            //     }));
            // }

            // if (step2DataEdit.TrustWebsite === "") {
            //     setStepForm2ErrorEdit((prevState) => ({
            //         ...prevState,
            //         websiteError: "Please enter Trust Website"
            //     }));
            // } else {
            //     setStepForm2ErrorEdit((prevState) => ({
            //         ...prevState,
            //         websiteError: ""
            //     }));
            // }

            if (step2DataEdit.TrustAddress === "") {
                setStepForm2ErrorEdit((prevState) => ({
                    ...prevState,
                    addressError: "Please enter Trust Address"
                }));
            } else {
                setStepForm2ErrorEdit((prevState) => ({
                    ...prevState,
                    addressError: ""
                }));
            }
        }

        if (step === 3) {
            if (step3DataEdit.TrustPan === "") {
                stepFormError("Please enter all data properly");
                setStepForm3ErrorEdit((prevState) => ({
                    ...prevState,
                    panError: "Please enter Trust Permanent Account Number (PAN)"
                }));
            } else if (!isValidPAN(step3DataEdit.TrustPan)) {
                stepFormError("Please enter all data properly");
                setStepForm3ErrorEdit((prevState) => ({
                    ...prevState,
                    panError: "Please Enter a valid Permanent Account Number (PAN)"
                }));
            } else {
                stepFormError("");
                setStepForm3ErrorEdit((prevState) => ({
                    ...prevState,
                    panError: ""
                }));
            }

            // if (step3DataEdit.TrustGST === "") {
            //     setStepForm3ErrorEdit((prevState) => ({
            //         ...prevState,
            //         GSTError: "Please enter Trust GST"
            //     }));
            // } else {
            //     setStepForm3ErrorEdit((prevState) => ({
            //         ...prevState,
            //         GSTError: ""
            //     }));
            // }

            if (step3DataEdit.TrustSectionCode === "") {
                setStepForm3ErrorEdit((prevState) => ({
                    ...prevState,
                    sectionCodeError: "Please enter Trust Section Code"
                }));
            } else {
                setStepForm3ErrorEdit((prevState) => ({
                    ...prevState,
                    sectionCodeError: ""
                }));
            }

            if (step3DataEdit.TrustUrnNo === "") {
                setStepForm3ErrorEdit((prevState) => ({
                    ...prevState,
                    urnNoError: "Please enter Trust URN number"
                }));
            } else if (!isValidURN(step3DataEdit.TrustUrnNo)) {
                setStepForm3ErrorEdit((prevState) => ({
                    ...prevState,
                    urnNoError: "Please Enter a valid Trust URN number"
                }));
            } else {
                setStepForm3ErrorEdit((prevState) => ({
                    ...prevState,
                    urnNoError: ""
                }));
            }

            if (step3DataEdit.TrustUrnRegistrationDate === "") {
                setStepForm3ErrorEdit((prevState) => ({
                    ...prevState,
                    urnRegistrationDateError: "Please enter Trust URN Registration Date"
                }));
            } else {
                setStepForm3ErrorEdit((prevState) => ({
                    ...prevState,
                    urnRegistrationDateError: ""
                }));
            }
        }
        if (step === 4) {
            const { TrustFormNo, AYStart, AYStop, TrustRegnNo, TrustClause } = stepDataEdit;
            // const { TrustPan, TrustUrnNo, TrustUrnRegistrationDate } = step3DataEdit;

            if (TrustFormNo === "" || AYStart === "" || AYStop === "" || TrustRegnNo === "" || TrustClause === "") {
                stepFormError("Please enter all data properly");
            } else {
                stepFormError("");
            }
        }
    }, [step, step1DataEdit, step2DataEdit, step3DataEdit, stepDataEdit]);

    // Add Trust Button
    const [nextButtonClicked, setNextButtonClicked] = useState(false);
    const handleNext = () => {
        setNextButtonClicked(true);
        if (
            (step === 1 &&
                // stepForm1Error.logoError !== "" ||
                // stepForm1Error.signError !== "" ||
                stepForm1Error.cityError !== "" ||
                stepForm1Error.nameError !== "") ||
            (step === 2 &&
                (stepForm2Error.emailError !== "" ||
                    stepForm2Error.mobileError !== "" ||
                    // stepForm2Error.faxError !== "" ||
                    // stepForm2Error.websiteError !== "" ||
                    stepForm2Error.addressError !== "")) ||
            (step === 3 &&
                (stepForm3Error.panError !== "" ||
                    // stepForm3Error.GSTError !== "" ||
                    stepForm3Error.sectionCodeError !== "" ||
                    stepForm3Error.urnNoError !== "" ||
                    stepForm3Error.urnRegistrationDateError !== "")) ||
            (step === 4 &&
                (formError !== ""))
        ) {
            return;
        }

        setStep(step + 1);
        setNextButtonClicked(false);
    };

    const handleBack = () => {
        setStep(step - 1);
    };

    // Edit Button
    const [nextButtonClickedEdit, setNextButtonClickedEdit] = useState(false);
    const [allError, setAllError] = useState(false);
    const handleNextEdit = () => {
        setNextButtonClickedEdit(true);
        if (
            (step === 1 &&
                // stepForm1ErrorEdit.logoError !== "" ||
                // stepForm1ErrorEdit.signError !== "" ||
                stepForm1ErrorEdit.cityError !== "" ||
                stepForm1ErrorEdit.nameError !== "") ||
            (step === 2 &&
                (stepForm2ErrorEdit.emailError !== "" ||
                    stepForm2ErrorEdit.mobileError !== "" ||
                    // stepForm2ErrorEdit.faxError !== "" ||
                    // stepForm2ErrorEdit.websiteError !== "" ||
                    stepForm2ErrorEdit.addressError !== "")) ||
            (step === 3 &&
                (stepForm3ErrorEdit.panError !== "" ||
                    // stepForm3ErrorEdit.GSTError !== "" ||
                    stepForm3ErrorEdit.sectionCodeError !== "" ||
                    stepForm3ErrorEdit.urnNoError !== "" ||
                    stepForm3ErrorEdit.urnRegistrationDateError !== "")) ||
            (step === 4 &&
                (formError !== ""))
        ) {
            setAllError(true)
            return;
        }

        setStep(step + 1);
        setAllError(false)
        setNextButtonClickedEdit(false); // Reset the nextButtonClicked state to false
    };

    const handleBackEdit = () => {
        setStep(step - 1);
    };

    // Get New Trust Data
    const handleNewTrustData = (e, data, name) => {
        if (step === 1) {
            setStep1Data({
                ...step1Data,
                [e.target.name]: e.target.value
            })
        }
        if (step === 2) {
            setStep2Data({
                ...step2Data,
                [e.target.name]: e.target.value
            })
        }
        if (step === 3) {
            setStep3Data({
                ...step3Data,
                [e.target.name]: e.target.value
            })
        }
        if (step === 4) {
            setStep4Data({
                ...step4Data,
                [e.target.name]: e.target.value
            })
        }
        if (data === "NOTE" && name === "REG") {
            setStepData({
                ...stepData,
                TrustRegnNo: e.target.value,
            })
        }
        if (data === "NOTE" && name === "PAN") {
            setStep3Data({
                ...step3Data,
                TrustPan: e.target.value,
            })
        }
        if (data === "NOTE" && name === "URN") {
            setStep3Data({
                ...step3Data,
                TrustUrnNo: e.target.value,
            })
        }
        if (data === "NOTE" && name === "URNDate") {
            setStep3Data({
                ...step3Data,
                TrustUrnRegistrationDate: e.target.value
            })
        }
        if (data === "Edit") {
            if (step === 1) {
                setStep1DataEdit({
                    ...step1DataEdit,
                    [e.target.name]: e.target.value
                })
            }
            if (step === 2) {
                setStep2DataEdit({
                    ...step2DataEdit,
                    [e.target.name]: e.target.value
                })
            }
            if (step === 3) {
                setStep3DataEdit({
                    ...step3DataEdit,
                    [e.target.name]: e.target.value
                })
            }
            if (name === "REG") {
                setStepDataEdit({
                    ...stepDataEdit,
                    TrustRegnNo: e.target.value,
                })
            }
            if (name === "PAN") {
                setStep3DataEdit({
                    ...step3DataEdit,
                    TrustPan: e.target.value,
                })
            }
            if (name === "URN") {
                setStep3DataEdit({
                    ...step3DataEdit,
                    TrustUrnNo: e.target.value,
                })
            }
            if (name === "URNDate") {
                setStep3DataEdit({
                    ...step3DataEdit,
                    TrustUrnRegistrationDate: e.target.value
                })
            }
        }
    }

    // get enterd password
    const [error, setError] = useState(false);
    const [Password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const handlePassword = (e) => {
        const { name, value } = e.target;
        name === 'Password' ? setPassword(value) : setConfirmPassword(value);
        if (name === 'Password' && confirmPassword && value !== confirmPassword) {
            setError(true);
        } else if (name === 'confirmPassword' && Password && value !== Password) {
            setError(true);
        } else {
            setError(false);
        }
    }
    useEffect(() => {
        if (!error) {
            setStep4Data({
                ...step4Data,
                Password: confirmPassword
            })
        }
    }, [error, confirmPassword])

    // Edit PAssword
    const [errorPass, setErrorPass] = useState(false);
    const [PasswordEdit, setPasswordEdit] = useState('');
    const [confirmPasswordEdit, setConfirmPasswordEdit] = useState('');
    const handlePasswordEdit = (e) => {
        const { name, value } = e.target;
        name === 'Password' ? setPasswordEdit(value) : setConfirmPasswordEdit(value);
        if (name === 'Password' && confirmPasswordEdit && value !== confirmPasswordEdit) {
            setErrorPass(true);
        } else if (name === 'confirmPassword' && PasswordEdit && value !== PasswordEdit) {
            setErrorPass(true);
        } else if (confirmPasswordEdit === "" || PasswordEdit === "") {
            setErrorPass(true);
        } else {
            setErrorPass(false);
        }
    }

    // Add Trust API 
    const handleSubmit = () => {
        const newTrustData = new FormData();

        const encPass = crypt.encrypt(process.env.REACT_APP_KEY, step4Data.Password)

        newTrustData.append("trustLogo", step1Data.trustLogo)
        newTrustData.append("TrustSign", step1Data.TrustSign)
        newTrustData.append("TrustName", step1Data.TrustName)
        newTrustData.append("TrustCity", step1Data.TrustCity)
        newTrustData.append("TrustEmail", step2Data.TrustEmail)
        newTrustData.append("TrustMobile", step2Data.TrustMobile)
        newTrustData.append("TrustFax", step2Data.TrustFax)
        newTrustData.append("TrustWebsite", step2Data.TrustWebsite)
        newTrustData.append("TrustAddress", step2Data.TrustAddress)
        newTrustData.append("TrustNote", step2Data.TrustNote)
        newTrustData.append("TrustPan", step3Data.TrustPan)
        newTrustData.append("TrustGST", step3Data.TrustGST)
        newTrustData.append("TrustSectionCode", step3Data.TrustSectionCode)
        newTrustData.append("TrustUrnNo", step3Data.TrustUrnNo)
        newTrustData.append("TrustUrnRegistrationDate", step3Data.TrustUrnRegistrationDate)
        newTrustData.append("Password", encPass)
        newTrustData.append("TrustFormNo", stepData.TrustFormNo)
        newTrustData.append("TrustClause", stepData.TrustClause)
        newTrustData.append("AYStart", stepData.AYStart)
        newTrustData.append("AYStop", stepData.AYStop)
        newTrustData.append("TrustRegnNo", stepData.TrustRegnNo)
        newTrustData.append("YearType", stepData.YearType)

        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        axios.post(getAddTrust, newTrustData)
            .then((res) => {
                setStep(step + 1)
                fetchTrustList()
                toast.success(res.data?.message, toastStyle);
            })
            .catch((err) => {
                toast.error(err.response.data?.message, toastStyle);
            })
    };

    // Edit Trust API
    const handleSubmitEdit = () => {
        const editTrustData = new FormData();
        editTrustData.append("trustLogo", step1DataEdit.trustLogo)
        editTrustData.append("TrustSign", step1DataEdit.TrustSign)
        editTrustData.append("TrustName", step1DataEdit.TrustName)
        editTrustData.append("TrustCity", step1DataEdit.TrustCity)
        editTrustData.append("TrustEmail", step2DataEdit.TrustEmail)
        editTrustData.append("TrustMobile", step2DataEdit.TrustMobile)
        editTrustData.append("TrustFax", step2DataEdit.TrustFax)
        editTrustData.append("TrustWebsite", step2DataEdit.TrustWebsite)
        editTrustData.append("TrustAddress", step2DataEdit.TrustAddress)
        editTrustData.append("TrustNote", step2DataEdit.TrustNote)
        editTrustData.append("TrustPan", step3DataEdit.TrustPan)
        editTrustData.append("TrustGST", step3DataEdit.TrustGST)
        editTrustData.append("TrustSectionCode", step3DataEdit.TrustSectionCode)
        editTrustData.append("TrustUrnNo", step3DataEdit.TrustUrnNo)
        editTrustData.append("TrustUrnRegistrationDate", step3DataEdit.TrustUrnRegistrationDate)
        editTrustData.append("Password", step4DataEdit.Password)
        editTrustData.append("TrustFormNo", stepDataEdit.TrustFormNo)
        editTrustData.append("TrustClause", stepDataEdit.TrustClause)
        editTrustData.append("AYStart", stepDataEdit.AYStart)
        editTrustData.append("AYStop", stepDataEdit.AYStop)
        editTrustData.append("TrustRegnNo", stepDataEdit.TrustRegnNo)
        editTrustData.append("YearType", stepDataEdit.YearType)

        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        if (!allError) {
            axios.put(`${getUpdateTrust}/${step1DataEdit.id}`, editTrustData)
                .then((res) => {
                    setStep(5)
                    fetchTrustList()
                    toast.success(res.data?.message, toastStyle);
                })
                .catch((err) => {
                    toast.error(err.response.data?.message, toastStyle);
                })
        }
    }

    // Add Trust Form
    const renderForm = () => {
        switch (step) {
            case 1:
                return (
                    <div className="Step-form w-100">
                        <div className="Step form-row step-1">
                            <Form.Group controlId="formBasicImage">
                                <Form.Label>Trust Logo</Form.Label>
                                <div className="mt-2">
                                    <input type="file" accept="image/*" onChange={(e) => handleImageUpload(e, "add")} ref={imageUploader} style={{ display: "none" }} />
                                    <div className="TrustProfileLogo" onClick={() => imageUploader.current.click()} >
                                        <img ref={uploadedImage} className="ProfileImg" src={trustLogoImage.uploaded ? trustLogoImage.url : trustLogoImage.url} name="productImage" alt="Trust Logo" />
                                        <p className="ProfileUploadImgTitle"> <ImageSearchOutlinedIcon /> <span>Upload Image</span>{" "} </p>
                                    </div>
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm1Error.logoError}</p>}
                            </Form.Group>
                            <Form.Group controlId="formBasicImage">
                                <Form.Label>Trust Signature</Form.Label>
                                <div className="mt-2">
                                    <input type="file" accept="image/*" onChange={(e) => handleSignatureUpload(e, "add")} ref={imageUploaderSignature} style={{ display: "none" }} />
                                    <div className="TrustProfileLogo" onClick={() => imageUploaderSignature.current.click()} >
                                        <img ref={uploadedSignature} className="ProfileImg" src={trustSignatureImage.uploaded ? trustSignatureImage.url : trustSignatureImage.url} name="productImage" alt="Trust Logo" />
                                        <p className="ProfileUploadImgTitle"> <ImageSearchOutlinedIcon /> <span>Upload Image</span>{" "} </p>
                                    </div>
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm1Error.signError}</p>}
                            </Form.Group>
                            <Form.Group className="form-control w-fill" controlId="TrustName">
                                <Form.Label>Trust Name <span className="reqired-star">*</span></Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Trust Name"
                                        name="TrustName"
                                        value={step1Data.TrustName}
                                        onChange={(e) => handleNewTrustData(e)}
                                    />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm1Error.nameError}</p>}
                            </Form.Group>
                            <Form.Group className="form-control w-fill" controlId="TrustName">
                                <Form.Label>Trust City <span className="reqired-star">*</span></Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Trust City"
                                        name="TrustCity"
                                        value={step1Data.TrustCity}
                                        onChange={(e) => handleNewTrustData(e)}
                                    />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm1Error.cityError}</p>}
                            </Form.Group>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="Step-form w-100">
                        <div className="Step form-row step-2">
                            <Form.Group className="form-control w-50" controlId="TrustEmail">
                                <Form.Label>Trust Email <span className="reqired-star">*</span></Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="email"
                                        placeholder="Trust Email"
                                        name="TrustEmail"
                                        value={step2Data.TrustEmail}
                                        onChange={(e) => handleNewTrustData(e)}
                                    />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm2Error.emailError}</p>}
                            </Form.Group>

                            <Form.Group className="form-control w-50" controlId="TrustMobile">
                                <Form.Label>Trust Mobile no. <span className="reqired-star">*</span></Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="number"
                                        placeholder="Trust Mobile no."
                                        name="TrustMobile"
                                        value={step2Data.TrustMobile}
                                        onChange={(e) => handleNewTrustData(e)}
                                    />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm2Error.mobileError}</p>}
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-2">
                            <Form.Group className="form-control w-50" controlId="TrustFax">
                                <Form.Label>Trust Fax no.</Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="email"
                                        placeholder="Trust Fax no."
                                        name="TrustFax"
                                        value={step2Data.TrustFax}
                                        onChange={(e) => handleNewTrustData(e)}
                                    />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm2Error.faxError}</p>}
                            </Form.Group>

                            <Form.Group className="form-control w-50" controlId="TrustWebsite">
                                <Form.Label>Trust Website</Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Trust Website."
                                        name="TrustWebsite"
                                        value={step2Data.TrustWebsite}
                                        onChange={(e) => handleNewTrustData(e)}
                                    />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm2Error.websiteError}</p>}
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-2">
                            <Form.Group className="form-control w-100" controlId="TrustAddress">
                                <Form.Label>Trust Address <span className="reqired-star">*</span></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Trust Address"
                                    name="TrustAddress"
                                    value={step2Data.TrustAddress}
                                    onChange={(e) => handleNewTrustData(e)}
                                />
                                {nextButtonClicked && <p className="errorMessage">{stepForm2Error.addressError}</p>}
                            </Form.Group>
                        </div>
                        {/* <div className="Step form-row step-2">
                            <Form.Group className="form-control w-100" controlId="TrustNote">
                                <Form.Label>Note of trust</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Note of trust"
                                    name="TrustNote"
                                    value={step2Data.TrustNote}
                                    onChange={(e) => handleNewTrustData(e)}
                                />
                                {nextButtonClicked && <p className="errorMessage">{stepForm2Error.noteError}</p>}
                            </Form.Group>
                        </div> */}
                    </div>
                );
            case 3:
                return (
                    <div className="Step-form w-100">
                        <div className="Step form-row step-3">
                            <Form.Group className="form-control w-50" controlId="TrustPan">
                                <Form.Label>Trust PAN no. <span className="reqired-star">*</span></Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Trust PAN no."
                                        name="TrustPan"
                                        value={step3Data.TrustPan}
                                        onChange={(e) => handleNewTrustData(e)}
                                    />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm3Error.panError}</p>}
                            </Form.Group>

                            <Form.Group className="form-control w-50" controlId="TrustGST">
                                <Form.Label>Trust GST no.</Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Trust GST no."
                                        name="TrustGST"
                                        value={step3Data.TrustGST}
                                        onChange={(e) => handleNewTrustData(e)}
                                    />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm3Error.GSTError}</p>}
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-3">
                            <Form.Group className="form-control w-50" controlId="TrustSectionCode">
                                <Form.Label>Trust Section Code <span className="reqired-star">*</span></Form.Label>
                                <Select
                                    className="customSelect"
                                    closeMenuOnSelect={true}
                                    options={sectionCodes}
                                    value={sectionValue}
                                    name="TrustSectionCode"
                                    onChange={(e) => handleSectionCode(e, "SectionCode")}
                                    placeholder="Trust Section Code"
                                />
                                {nextButtonClicked && <p className="errorMessage">{stepForm3Error.sectionCodeError}</p>}
                            </Form.Group>

                            <Form.Group className="form-control w-50" controlId="TrustUrnNo">
                                <Form.Label>Trust URN no. <span className="reqired-star">*</span></Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Trust URN no."
                                        name="TrustUrnNo"
                                        value={step3Data.TrustUrnNo}
                                        onChange={(e) => handleNewTrustData(e)}
                                    />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm3Error.urnNoError}</p>}
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-3">
                            <Form.Group
                                className="form-control w-50"
                                controlId="TrustUrnRegistrationDate"
                            >
                                <Form.Label>Trust URN Registration Date <span className="reqired-star">*</span></Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="date"
                                        placeholder="Trust Section Code"
                                        name="TrustUrnRegistrationDate"
                                        value={step3Data.TrustUrnRegistrationDate}
                                        max={new Date().toISOString().split('T')[0]}
                                        onChange={(e) => handleNewTrustData(e)}
                                    />
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm3Error.urnRegistrationDateError}</p>}
                            </Form.Group>
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className="Step-form w-100">
                        <div className="Step form-row step-4">
                            <Form.Group className="form-control w-100" controlId="Password">
                                <Form.Label>Trust Note <span className="reqired-star">*</span></Form.Label>
                                <div className="group">
                                    <p>Trust is registered under Bombay Public Trust Act, 1950 vide Regn. No.
                                        <Form.Control
                                            className="w-auto trustNote"
                                            type="text"
                                            placeholder="Regn no."
                                            name="TrustRegnNo"
                                            value={stepData?.TrustRegnNo}
                                            onChange={(e) => handleNewTrustData(e, "NOTE", "REG")}
                                        />
                                        PAN NO.
                                        <Form.Control
                                            className="w-auto trustNote"
                                            type="text"
                                            placeholder="Trust PAN no."
                                            name="TrustPan"
                                            value={step3Data.TrustPan}
                                            disabled
                                            onChange={(e) => handleNewTrustData(e, "NOTE", "PAN")}
                                        />
                                        I.T (Exemptions)
                                        Approval under Form No.
                                        <Select
                                            className="customSelect w-auto trustNote"
                                            closeMenuOnSelect={true}
                                            options={formNo}
                                            value={formNoValue}
                                            name="TrustFormNo"
                                            onChange={(e) => handleFormNoCode(e, "FormNo")}
                                            placeholder="Trust Form No"
                                        />
                                        with URN:
                                        <Form.Control
                                            className="w-auto trustNote"
                                            type="text"
                                            placeholder="Trust URN no."
                                            name="TrustUrnNo"
                                            value={step3Data.TrustUrnNo}
                                            disabled
                                            onChange={(e) => handleNewTrustData(e, "NOTE", "URN")}
                                        />
                                        Granted under section-
                                        <Select
                                            className="customSelect w-auto trustNote"
                                            closeMenuOnSelect={true}
                                            options={clause}
                                            value={clauseValue}
                                            name="TrustClause"
                                            onChange={(e) => handleClauseCode(e, "clause")}
                                            placeholder="Trust Clause"
                                        />
                                        of First proviso to sub-section (5) of
                                        {/* <Select
                                            className="customSelect w-auto trustNote"
                                            closeMenuOnSelect={true}
                                            options={sectionCodes}
                                            value={sectionValue}
                                            name="TrustSectionCode"
                                            onChange={(e) => handleSectionCode(e, "SectionCode")}
                                            disabled
                                            placeholder="Trust Section Code"
                                        /> */}
                                        <Form.Control
                                            className="w-auto trustNote"
                                            type="text"
                                            placeholder="Trust Section Code"
                                            name="TrustUrnRegistrationDate"
                                            value={sectionValue.label}
                                            disabled
                                        />
                                        on
                                        <Form.Control
                                            className="w-auto trustNote"
                                            type="date"
                                            placeholder="Trust Section Code"
                                            name="TrustUrnRegistrationDate"
                                            value={step3Data.TrustUrnRegistrationDate}
                                            max={new Date().toISOString().split('T')[0]}
                                            disabled
                                            onChange={(e) => handleNewTrustData(e, "NOTE", "URNDate")}
                                        />
                                        Valid from
                                        <Select
                                            className="customSelect w-auto trustNote"
                                            closeMenuOnSelect={true}
                                            options={AYType}
                                            value={AYValue}
                                            name="YearType"
                                            onChange={(e) => handleAYValue(e, "add")}
                                            placeholder="Select type"
                                        />
                                        {
                                            AYValue && AYValue.value === 1 ?
                                                <Select
                                                    className="customSelect w-auto trustNote"
                                                    closeMenuOnSelect={true}
                                                    options={ayOptions}
                                                    value={ayStart}
                                                    name="TrustSectionCode"
                                                    onChange={(e) => handleAyYear(e, "Start")}
                                                    placeholder="Select A.Y."
                                                /> : ""
                                        }
                                        {
                                            AYValue && AYValue.value === 2 ?
                                                <Form.Control
                                                    type="date"
                                                    placeholder="Select Date"
                                                    name="AYStart"
                                                    value={stepData.AYStart}
                                                    onChange={(e) => handleAyDate(e, "add")}
                                                /> : ""
                                        }
                                        to A.Y.
                                        <Select
                                            className="customSelect w-auto trustNote"
                                            closeMenuOnSelect={true}
                                            options={ayOptions}
                                            value={ayStop}
                                            name="TrustSectionCode"
                                            onChange={(e) => handleAyYear(e, "Stop")}
                                            placeholder="Select A.Y."
                                        />
                                    </p>
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{formError}</p>}
                            </Form.Group>
                        </div>
                    </div>
                );
            case 5:
                return (
                    <div className="Step-form w-100">
                        <div className="Step form-row step-4">
                            <Form.Group className="form-control w-50" controlId="Password">
                                <Form.Label>Password <span className="reqired-star">*</span></Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Password"
                                        name="Password"
                                        value={Password}
                                        onChange={(e) => handlePassword(e)}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group className="form-control w-50" controlId="confirmPassword">
                                <Form.Label>Confirm Password <span className="reqired-star">*</span></Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Confirm Password"
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(e) => handlePassword(e)}
                                    />
                                </div>
                                {error ? <p className="errorMessage">Password Does Not Match</p> : ""}
                            </Form.Group>
                        </div>
                    </div>
                );
            case 6:
                return (
                    <div className="Step-form w-100">
                        <div className="success-checkmark">
                            <div className="check-icon">
                                <span className="icon-line line-tip"></span>
                                <span className="icon-line line-long"></span>
                                <div className="icon-circle"></div>
                                <div className="icon-fix"></div>
                            </div>
                        </div>
                        <h3 className="center">Trust added Sucessfully</h3>
                    </div>
                );
            default:
                return null;
        }
    };

    // Add Trust Popup Buttons
    const renderButtons = () => {
        if (step === 1) {
            return (
                <Modal.Footer>
                    <Button auto className="common-btn" onClick={handleNext}>
                        Next
                    </Button>
                </Modal.Footer>
            );
        } else if (step === 5) {
            return (
                <Modal.Footer>
                    <Button auto className="common-btn light" color="error" onClick={handleBack}>
                        Back
                    </Button>
                    <Button auto className="common-btn" onClick={handleSubmit}>
                        Add Trust
                    </Button>
                </Modal.Footer>
            );
        } else if (step === 6) {
            return (
                <Modal.Footer>
                    <Button auto className="common-btn light" color="error" onClick={closeHandler}>
                        Close
                    </Button>
                    {/* <Button auto className="common-btn" onClick={handleSubmit}>
                        Download Receipt
                    </Button> */}
                </Modal.Footer>
            );
        } else {
            return (
                <Modal.Footer>
                    <Button auto className="common-btn light" color="error" onClick={handleBack}>
                        Back
                    </Button>
                    <Button auto className="common-btn" onClick={handleNext}>
                        Next
                    </Button>
                </Modal.Footer>
            );
        }
    };

    // Edit Trust Forn
    const renderFormEdit = () => {
        switch (step) {
            case 1:
                return (
                    <div className="Step-form w-100">
                        <div className="Step form-row step-1">
                            <Form.Group controlId="formBasicImage">
                                <Form.Label>Trust Logo</Form.Label>
                                <div className="mt-2">
                                    <input type="file" accept="image/*" onChange={(e) => handleImageUpload(e, "Edit")} ref={imageUploader} style={{ display: "none" }} />
                                    <div className="TrustProfileLogo" onClick={() => imageUploader.current.click()} >
                                        <img ref={uploadedImage} className="ProfileImg" src={trustLogoImage.uploaded ? trustLogoImage.url : trustLogoImage.url} name="productImage" alt="Trust Logo" />
                                        <p className="ProfileUploadImgTitle"> <ImageSearchOutlinedIcon /> <span>Upload Image</span>{" "} </p>
                                    </div>
                                </div>
                                {nextButtonClickedEdit && <p className="errorMessage">{stepForm1ErrorEdit.logoError}</p>}
                            </Form.Group>
                            <Form.Group controlId="formBasicImage">
                                <Form.Label>Trust Signature</Form.Label>
                                <div className="mt-2">
                                    <input type="file" accept="image/*" onChange={(e) => handleSignatureUpload(e, "Edit")} ref={imageUploaderSignature} style={{ display: "none" }} />
                                    <div className="TrustProfileLogo" onClick={() => imageUploaderSignature.current.click()} >
                                        <img ref={uploadedSignature} className="ProfileImg" src={trustSignatureImage.uploaded ? trustSignatureImage.url : trustSignatureImage.url} name="productImage" alt="Trust Logo" />
                                        <p className="ProfileUploadImgTitle"> <ImageSearchOutlinedIcon /> <span>Upload Image</span>{" "} </p>
                                    </div>
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{stepForm1ErrorEdit.signError}</p>}
                            </Form.Group>
                            <Form.Group className="form-control w-fill" controlId="TrustName">
                                <Form.Label>Trust Name <span className="reqired-star">*</span></Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Trust Name"
                                        name="TrustName"
                                        value={step1DataEdit.TrustName}
                                        onChange={(e) => handleNewTrustData(e, "Edit")}
                                    />
                                </div>
                                {nextButtonClickedEdit && <p className="errorMessage">{stepForm1ErrorEdit.nameError}</p>}
                            </Form.Group>
                            <Form.Group className="form-control w-fill" controlId="TrustName">
                                <Form.Label>Trust City <span className="reqired-star">*</span></Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Trust City"
                                        name="TrustCity"
                                        value={step1DataEdit.TrustCity}
                                        onChange={(e) => handleNewTrustData(e, "Edit")}
                                    />
                                </div>
                                {nextButtonClickedEdit && <p className="errorMessage">{stepForm1ErrorEdit.cityError}</p>}
                            </Form.Group>
                            <Form.Group className="form-control w-fill" controlId="TrustName">
                                <Form.Label>Trust Password</Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Trust Password"
                                        name="TrustCity"
                                        disabled
                                        value={step1DataEdit.Pass}
                                        onChange={(e) => handleNewTrustData(e, "Edit")}
                                    />
                                </div>
                                {nextButtonClickedEdit && <p className="errorMessage">{stepForm1ErrorEdit.cityError}</p>}
                            </Form.Group>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="Step-form w-100">
                        <div className="Step form-row step-2">
                            <Form.Group className="form-control w-50" controlId="TrustEmail">
                                <Form.Label>Trust Email <span className="reqired-star">*</span></Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="email"
                                        placeholder="Trust Email"
                                        name="TrustEmail"
                                        value={step2DataEdit.TrustEmail}
                                        onChange={(e) => handleNewTrustData(e, "Edit")}
                                    />
                                </div>
                                {nextButtonClickedEdit && <p className="errorMessage">{stepForm2ErrorEdit.emailError}</p>}
                            </Form.Group>

                            <Form.Group className="form-control w-50" controlId="TrustMobile">
                                <Form.Label>Trust Mobile no. <span className="reqired-star">*</span></Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="number"
                                        placeholder="Trust Mobile no."
                                        name="TrustMobile"
                                        value={step2DataEdit.TrustMobile}
                                        onChange={(e) => handleNewTrustData(e, "Edit")}
                                    />
                                </div>
                                {nextButtonClickedEdit && <p className="errorMessage">{stepForm2ErrorEdit.mobileError}</p>}
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-2">
                            <Form.Group className="form-control w-50" controlId="TrustFax">
                                <Form.Label>Trust Fax no.</Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="email"
                                        placeholder="Trust Fax no."
                                        name="TrustFax"
                                        value={step2DataEdit.TrustFax}
                                        onChange={(e) => handleNewTrustData(e, "Edit")}
                                    />
                                </div>
                                {nextButtonClickedEdit && <p className="errorMessage">{stepForm2ErrorEdit.faxError}</p>}
                            </Form.Group>

                            <Form.Group className="form-control w-50" controlId="TrustWebsite">
                                <Form.Label>Trust Website</Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Trust Website."
                                        name="TrustWebsite"
                                        value={step2DataEdit.TrustWebsite}
                                        onChange={(e) => handleNewTrustData(e, "Edit")}
                                    />
                                </div>
                                {nextButtonClickedEdit && <p className="errorMessage">{stepForm2ErrorEdit.websiteError}</p>}
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-2">
                            <Form.Group className="form-control w-100" controlId="TrustAddress">
                                <Form.Label>Trust Address <span className="reqired-star">*</span></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Trust Address"
                                    name="TrustAddress"
                                    value={step2DataEdit.TrustAddress}
                                    onChange={(e) => handleNewTrustData(e, "Edit")}
                                />
                                {nextButtonClickedEdit && <p className="errorMessage">{stepForm2ErrorEdit.addressError}</p>}
                            </Form.Group>
                        </div>
                        {/* <div className="Step form-row step-2">
                            <Form.Group className="form-control w-100" controlId="TrustNote">
                                <Form.Label>Note of trust <span className="reqired-star">*</span></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Note of trust"
                                    name="TrustNote"
                                    value={step2DataEdit.TrustNote}
                                    onChange={(e) => handleNewTrustData(e, "Edit")}
                                />
                                {nextButtonClickedEdit && <p className="errorMessage">{stepForm2ErrorEdit.noteError}</p>}
                            </Form.Group>
                        </div> */}
                    </div>
                );
            case 3:
                return (
                    <div className="Step-form w-100">
                        <div className="Step form-row step-3">
                            <Form.Group className="form-control w-50" controlId="TrustPan">
                                <Form.Label>Trust PAN no. <span className="reqired-star">*</span></Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Trust PAN no."
                                        name="TrustPan"
                                        value={step3DataEdit.TrustPan}
                                        onChange={(e) => handleNewTrustData(e, "Edit")}
                                    />
                                </div>
                                {nextButtonClickedEdit && <p className="errorMessage">{stepForm3ErrorEdit.panError}</p>}
                            </Form.Group>

                            <Form.Group className="form-control w-50" controlId="TrustGST">
                                <Form.Label>Trust GST no.</Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Trust GST no."
                                        name="TrustGST"
                                        value={step3DataEdit.TrustGST}
                                        onChange={(e) => handleNewTrustData(e, "Edit")}
                                    />
                                </div>
                                {nextButtonClickedEdit && <p className="errorMessage">{stepForm3ErrorEdit.GSTError}</p>}
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-3">
                            <Form.Group className="form-control w-50" controlId="TrustSectionCode">
                                <Form.Label>Trust Section Code <span className="reqired-star">*</span></Form.Label>
                                <Select
                                    className="customSelect"
                                    closeMenuOnSelect={true}
                                    options={sectionCodes}
                                    value={sectionValue}
                                    name="TrustSectionCode"
                                    onChange={(e) => handleSectionCode(e, "SectionCodeEdit")}
                                    placeholder="Trust Section Code"
                                />
                                {nextButtonClickedEdit && <p className="errorMessage">{stepForm3ErrorEdit.sectionCodeError}</p>}
                            </Form.Group>

                            <Form.Group className="form-control w-50" controlId="TrustUrnNo">
                                <Form.Label>Trust URN no. <span className="reqired-star">*</span></Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Trust URN no."
                                        name="TrustUrnNo"
                                        value={step3DataEdit.TrustUrnNo}
                                        onChange={(e) => handleNewTrustData(e, "Edit")}
                                    />
                                </div>
                                {nextButtonClickedEdit && <p className="errorMessage">{stepForm3ErrorEdit.urnNoError}</p>}
                            </Form.Group>
                        </div>
                        <div className="Step form-row step-3">
                            <Form.Group
                                className="form-control w-50"
                                controlId="TrustUrnRegistrationDate"
                            >
                                <Form.Label>Trust URN Registration Date <span className="reqired-star">*</span></Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="date"
                                        placeholder="Trust Section Code"
                                        name="TrustUrnRegistrationDate"
                                        value={step3DataEdit.TrustUrnRegistrationDate}
                                        onChange={(e) => handleNewTrustData(e, "Edit")}
                                    />
                                </div>
                                {nextButtonClickedEdit && <p className="errorMessage">{stepForm3ErrorEdit.urnRegistrationDateError}</p>}
                            </Form.Group>
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className="Step-form w-100">
                        <div className="Step form-row step-4">
                            <Form.Group className="form-control w-100" controlId="Password">
                                <Form.Label>Trust Note <span className="reqired-star">*</span></Form.Label>
                                <div className="group">
                                    <p>Trust is registered under Bombay Public Trust Act, 1950 vide Regn. No.
                                        <Form.Control
                                            className="w-auto trustNote"
                                            type="text"
                                            placeholder="Regn no."
                                            name="TrustRegnNo"
                                            value={stepDataEdit.TrustRegnNo}
                                            onChange={(e) => handleNewTrustData(e, "Edit", "REG")}
                                        />
                                        PAN NO.
                                        <Form.Control
                                            className="w-auto trustNote"
                                            type="text"
                                            placeholder="Trust PAN no."
                                            name="TrustPan"
                                            value={step3DataEdit.TrustPan}
                                            disabled
                                            onChange={(e) => handleNewTrustData(e, "Edit", "PAN")}
                                        />
                                        I.T (Exemptions)
                                        Approval under Form No.
                                        <Select
                                            className="customSelect w-auto trustNote"
                                            closeMenuOnSelect={true}
                                            options={formNo}
                                            value={formNoValue}
                                            name="TrustFormNo"
                                            onChange={(e) => handleFormNoCode(e, "FormNoEdit")}
                                            placeholder="Trust Form No"
                                        />
                                        with URN:
                                        <Form.Control
                                            className="w-auto trustNote"
                                            type="text"
                                            placeholder="Trust URN no."
                                            name="TrustUrnNo"
                                            value={step3DataEdit.TrustUrnNo}
                                            disabled
                                            onChange={(e) => handleNewTrustData(e, "Edit", "URN")}
                                        />
                                        Granted under section-
                                        <Select
                                            className="customSelect w-auto trustNote"
                                            closeMenuOnSelect={true}
                                            options={clause}
                                            value={clauseValue}
                                            name="TrustClause"
                                            onChange={(e) => handleClauseCode(e, "clauseEdit")}
                                            placeholder="Trust Clause"
                                        />
                                        of First proviso to sub-section (5) of
                                        <Select
                                            className="customSelect w-auto trustNote"
                                            closeMenuOnSelect={true}
                                            options={sectionCodes}
                                            value={sectionValue}
                                            name="TrustSectionCode"
                                            disabled
                                            onChange={(e) => handleSectionCode(e, "SectionCode")}
                                            placeholder="Trust Section Code"
                                        />
                                        on
                                        <Form.Control
                                            className="w-auto trustNote"
                                            type="date"
                                            placeholder="Trust Section Code"
                                            name="TrustUrnRegistrationDate"
                                            value={step3DataEdit.TrustUrnRegistrationDate}
                                            max={new Date().toISOString().split('T')[0]}
                                            disabled
                                            onChange={(e) => handleNewTrustData(e, "Edit", "URNDate")}
                                        />
                                        Valid from
                                        <Select
                                            className="customSelect w-auto trustNote"
                                            closeMenuOnSelect={true}
                                            options={AYType}
                                            value={AYValue}
                                            name="YearType"
                                            onChange={(e) => handleAYValue(e, "edit")}
                                            placeholder="Select Type"
                                        />
                                        {
                                            AYValue && AYValue.value === 1 ?
                                                <Select
                                                    className="customSelect w-auto trustNote"
                                                    closeMenuOnSelect={true}
                                                    options={ayOptions}
                                                    value={ayStart}
                                                    name="TrustSectionCode"
                                                    onChange={(e) => handleAyYear(e, "Start", "Edit")}
                                                    placeholder="AY start"
                                                /> : ""
                                        }
                                        {
                                            AYValue && AYValue.value === 2 ?
                                                <Form.Control
                                                    className=" w-auto"
                                                    type="date"
                                                    placeholder="Select Date"
                                                    name="AYStart"
                                                    value={stepDataEdit.AYStart}
                                                    onChange={(e) => handleAyDate(e, "edit")}
                                                /> : ""
                                        }
                                        {
                                            AYValue && AYValue.value === 1 ?
                                                "Year" : "Date"
                                        }
                                        &nbsp;to A.Y.
                                        <Select
                                            className="customSelect w-auto trustNote"
                                            closeMenuOnSelect={true}
                                            options={ayOptions}
                                            value={ayStop}
                                            name="TrustSectionCode"
                                            onChange={(e) => handleAyYear(e, "Stop", "Edit")}
                                            placeholder="AY End Year"
                                        />
                                    </p>
                                </div>
                                {nextButtonClicked && <p className="errorMessage">{formError}</p>}
                            </Form.Group>
                        </div>
                    </div>
                );
            case 5:
                return (
                    <div className="Step-form successBlock w-100">
                        <div className="success-checkmark">
                            <div className="check-icon">
                                <span className="icon-line line-tip"></span>
                                <span className="icon-line line-long"></span>
                                <div className="icon-circle"></div>
                                <div className="icon-fix"></div>
                            </div>
                        </div>
                        <h3 className="center">Trust Edited Sucessfully</h3>
                    </div>
                );
            default:
                return null;
        }
    };

    // Edit Trust Popup Buttons
    const renderButtonsEdit = () => {
        if (step === 1) {
            return (
                <Modal.Footer>
                    <Button auto className="common-btn" onClick={handleNextEdit}>
                        Next
                    </Button>
                </Modal.Footer>
            );
        } else if (step === 4) {
            return (
                <Modal.Footer>
                    <Button auto className="common-btn light" color="error" onClick={handleBackEdit}>
                        Back
                    </Button>
                </Modal.Footer>
            );
        } else if (step === 5) {
            return (
                ""
            );
        } else {
            return (
                <Modal.Footer>
                    <Button auto className="common-btn light" color="error" onClick={handleBackEdit}>
                        Back
                    </Button>
                    <Button auto className="common-btn" onClick={handleNextEdit}>
                        Next
                    </Button>
                </Modal.Footer>
            );
        }
    };

    // Step Sidebar
    const handleSidebarStep = (e, value) => {
        setStep(value);
    };

    // Step Sidebar Error Add Trust
    const [error1, setError1] = useState(true);
    const [error2, setError2] = useState(true);
    const [error3, setError3] = useState(true);
    const [error4, setError4] = useState(true);

    useEffect(() => {
        const updateStepFormError = (step, error, setError, data) => {
            let hasError = Object.values(data).some((value) => value === "" || value === undefined);
            if (data.TrustFax === "" || data.TrustWebsite === "") {
                hasError = false
            }
            setError(hasError);
        };

        if (step === 1) {
            updateStepFormError(step, error1, setError1, step1Data);
        } else if (step === 2) {
            updateStepFormError(step, error2, setError2, step2Data);
        } else if (step === 3) {
            updateStepFormError(step, error3, setError3, step3Data);
        } else if (step === 4) {
            updateStepFormError(step, error4, setError4, stepData);
        }
    }, [step, step1Data, step2Data, step3Data]);

    // Step Sidebar Error Edit Trust
    const [errorE1, setErrorE1] = useState(false);
    const [errorE2, setErrorE2] = useState(false);
    const [errorE3, setErrorE3] = useState(false);
    const [errorE4, setErrorE4] = useState(false);

    useEffect(() => {
        const updateStepFormError = (step, error, setError, data) => {
            let hasError = Object.values(data).some((value) => value === "" || value === undefined);
            if (data.TrustFax === "" || data.TrustWebsite === "") {
                hasError = false
            }
            setError(hasError);
        };

        updateStepFormError(step, errorE1, setErrorE1, step1DataEdit);
        updateStepFormError(step, errorE2, setErrorE2, step2DataEdit);
        updateStepFormError(step, errorE3, setErrorE3, step3DataEdit);
        updateStepFormError(step, errorE4, setErrorE4, stepDataEdit);
    }, [step, step1DataEdit, step2DataEdit, step3DataEdit]);


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="trust-border">
                <div className="title_text roles-header">
                    <h4>Trust</h4>
                    <div className="button-for-trust-actions">
                        <Button variant="contained" className="fillter_btn common-btn" onClick={handler} startIcon={<AiOutlinePlus />} >
                            Add Trust
                        </Button>
                        <Button variant="contained" className="fillter_btn common-btn" startIcon={<CiExport />} >
                            Export List
                        </Button>
                    </div>
                </div>
                <div className="innerusercontent">
                    <div className="Filteruser">
                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                                <React.Fragment>
                                    <Button variant="contained" className='fillter_btn common-btn' {...bindTrigger(popupState)} startIcon={<MdTune />} px={2}>Filter</Button>
                                    <Menu {...bindMenu(popupState)}>
                                        <div className="filter-model">
                                            <div className="filter-buttons">
                                                <Button className='rst-Btn-Lnk btn common-btn light' autoFocus onClick={(e) => handleResetFilter(e)} > Reset </Button>
                                                <Button className='aply-Btn-Lnk btn common-btn dark' autoFocus onClick={(e) => handleDateFilter(e)}> Apply </Button>
                                            </div>
                                        </div>
                                        <div className='HL'></div>
                                        <div className="datePiker">
                                            <div className="filter-type">
                                                <Form.Label>Filter By</Form.Label>
                                                <div className="paymentMethod">
                                                    <div className='radio'>
                                                        <Form.Check type="radio" name="filterType" value={1} checked={filterType === 1 ? true : false} id='date-of-donation' label="Date Of Donation" onChange={(e) => handleFilterType(e)} />
                                                    </div>
                                                    <div className='radio'>
                                                        <Form.Check type="radio" name="filterType" value={2} checked={filterType === 2 ? true : false} id='created-at' label="Created At" onChange={(e) => handleFilterType(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div >
                                                <DateRange
                                                    editableDateInputs={true}
                                                    onChange={item => setState([item.selection])}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={state}
                                                    rangeColors={['linear-gradient(195deg, #49a3f1, #1A73E8)', '#3ecf8e', '#fed14c']}
                                                />
                                            </div>
                                        </div>
                                    </Menu>
                                </React.Fragment>
                            )}
                        </PopupState>
                    </div>
                    <div className='searchbaar'>
                        <div className='serach_icon'>
                            <div className="serach_icon">
                                <CiSearch />
                            </div>
                        </div>
                        <InputBase
                            className="inputsearchbase base-css-trust"
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(e) => handleSearch(e)}
                        />
                    </div>
                </div>
                <MDBox py={3}>
                    <MDBox mt={1}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <MDBox mb={3} >
                                    <Box sx={{ height: 630, width: '100%', }} >
                                        <DataGrid
                                            rows={trustList}
                                            columns={columns}
                                            pageSize={pageState.pageSize}
                                        />
                                    </Box>
                                    <div className='pagination-block'>
                                        <ResultInfo />
                                        <Pagination
                                            loop
                                            color="success"
                                            total={pageState.totalPages}
                                            initialPage={1}
                                            onChange={(page) => handlePageChange(page)}
                                            currentPage={pageState.page}
                                        />
                                    </div>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            </div>

            {/* add Trust Model */}
            <Modal closeButton blur preventClose aria-labelledby="modal-title" className="model-with-sidebar" width={1100} open={visible} onClose={closeHandler} >
                <Modal.Header justify="start">
                    <Text id="modal-title" size={24}>
                        Add new<Text b size={24}> Trust</Text>
                    </Text>
                </Modal.Header>
                <div className="AddDataModel">
                    <div className="modelSidebat">
                        <ul>
                            <li><a href="#" value={1} className={`step ${step === 1 ? "active" : ""} ${error1 ? "success" : ""}`} ><span className="mobile-only">1</span><span>Step 1 :</span><span>Trust Name and Logo</span></a></li>
                            <li><a href="#" value={2} className={`step ${step === 2 ? "active" : ""} ${!error2 ? "success" : ""}`} ><span className="mobile-only">2</span><span>Step 2 :</span><span>Contact Information</span></a></li>
                            <li><a href="#" value={3} className={`step ${step === 3 ? "active" : ""} ${!error3 ? "success" : ""}`} ><span className="mobile-only">3</span><span>Step 3 :</span><span>Registration Details</span></a></li>
                            <li><a href="#" value={4} className={`step ${step === 4 ? "active" : ""} ${!error4 ? "success" : ""}`} ><span className="mobile-only">4</span><span>Step 4 :</span><span>Trust Note</span></a></li>
                            <li><a href="#" value={5} className={`step ${step === 5 ? "active" : ""} ${step > 4 && !error ? "success" : ""}`} ><span className="mobile-only">5</span><span>Step 5 :</span><span>Set Password</span></a></li>
                        </ul>
                    </div>
                    <div className="modelContent">
                        <Modal.Body>
                            <Row>
                                {renderForm()}
                            </Row>
                        </Modal.Body>
                        {renderButtons()}
                    </div>
                </div>
            </Modal>

            {/* Edit Trust Model */}
            <Modal closeButton blur preventClose aria-labelledby="modal-title" className="model-with-sidebar" width={1100} open={visibleEdit} onClose={closeHandlerEdit} >
                <Modal.Header justify="start">
                    <Text id="modal-title" size={24}>
                        Edit<Text b size={24}> Trust </Text>
                    </Text>
                </Modal.Header>
                <div className="AddDataModel">
                    {step !== 5 ?
                        <div className="modelSidebat">
                            <ul>
                                <li><a href="#" value={1} onClick={(e) => handleSidebarStep(e, 1)} className={`step ${step === 1 ? "active" : ""} ${!errorE1 ? "success" : ""} ${errorE1 ? "error" : ""}`}><span className="mobile-only">1</span><span>Step 1 :</span><span>Trust Name and Logo</span></a></li>
                                <li><a href="#" value={2} onClick={(e) => handleSidebarStep(e, 2)} className={`step ${step === 2 ? "active" : ""} ${!errorE2 ? "success" : ""} ${errorE2 ? "error" : ""}`}><span className="mobile-only">2</span><span>Step 2 :</span><span>Contact Information</span></a></li>
                                <li><a href="#" value={3} onClick={(e) => handleSidebarStep(e, 3)} className={`step ${step === 3 ? "active" : ""} ${!errorE3 ? "success" : ""} ${errorE3 ? "error" : ""}`}><span className="mobile-only">3</span><span>Step 3 :</span><span>Registration Details</span></a></li>
                                <li><a href="#" value={4} onClick={(e) => handleSidebarStep(e, 4)} className={`step ${step === 4 ? "active" : ""} ${errorE4 ? "success" : ""} ${errorE3 ? "error" : ""}`}><span className="mobile-only">4</span><span>Step 4 :</span><span>Trust Note</span></a></li>
                            </ul>
                        </div> : ""}
                    <div className={step === 5 ? "modelContent w-100" : "modelContent"}>
                        <Modal.Body>
                            <Row>
                                {renderFormEdit()}
                            </Row>
                        </Modal.Body>
                        {renderButtonsEdit()}
                    </div>
                </div>
                <div className="modelBottom">
                    {step === 5 ?
                        <Button auto className="common-btn light" color="error" onClick={closeHandlerEdit}>
                            Close
                        </Button> :
                        <Button auto className="common-btn" onClick={handleSubmitEdit}>
                            Edit Trust
                        </Button>}
                </div>
            </Modal>

            {/* Chamge Trust Password Model */}
            <Modal closeButton blur preventClose aria-labelledby="modal-title" className="AddDataModel" width={600} open={visiblePass} onClose={closeHandlerPass} >
                <Modal.Header justify="start">
                    <Text id="modal-title" size={24}>
                        Change Password for <Text b size={24}> {editTrustPassword.TrustName} </Text>
                    </Text>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <div className="Step form-row step-4 w-100">
                            <Form.Group className="form-control w-100" controlId="Password">
                                <Form.Label>Password</Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Password"
                                        name="Password"
                                        value={PasswordEdit}
                                        onChange={(e) => handlePasswordEdit(e)}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group className="form-control w-100" controlId="confirmPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <div className="group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Confirm Password"
                                        name="confirmPassword"
                                        value={confirmPasswordEdit}
                                        onChange={(e) => handlePasswordEdit(e)}
                                    />
                                </div>
                                {errorPass ? <p className="errorMessage">Password Does Not Match</p> : ""}
                            </Form.Group>
                        </div>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button auto className="common-btn light" color="error" onClick={closeHandlerPass}>
                        cancel
                    </Button>
                    <Button auto className="common-btn" onClick={handleUpdatePassword}>
                        Update Password
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </DashboardLayout>
    )
}

export default Admin