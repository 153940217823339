import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Donation Receipt Generator React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Donation Receipt Generator React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/DefaultProfile.png";
import backgroundImage from "assets/images/bg-profile.jpeg";
import { userToken } from "Token";
import { getTrustDetailsPatch } from "Api";
import axios from "axios";

function Header({ children }) {
  // Variables
  const [trustDetail, setTrustDetail] = useState({})
  const [trustLogo, settrustLogo] = useState(burceMars)

  const token = userToken();


  // Get Trust Details
  const fetchTrustDetails = () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.patch(getTrustDetailsPatch)
      .then((res) => {
        if (res.data.user.TrustLogo !== "") {
          if (res.data.user.TrustLogo.includes('http://') || res.data.user.TrustLogo.includes('https://')) {
            settrustLogo(`https://${res.data.user.TrustLogo}`)
          } else {
            settrustLogo(`${res.data.user.TrustLogo}`)
          }
        }
        setTrustDetail(res.data.user)
      })
  }
  useEffect(() => {
    if (token !== "" && token !== undefined) {
      fetchTrustDetails()
    }
  }, [token])

  return (
    <MDBox position="relative" mb={5}>
      {/* <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      /> */}
      <Card className="ProfileCard" sx={{ position: "relative", py: 2, px: 2, }} >
        <Grid className="ProfileImage" container spacing={3} alignItems="center">
          <Grid className="image" item>
            <MDAvatar src={trustLogo} alt="profile-image" size="xxxl" shadow="sm" />
          </Grid>
          <Grid className="details" item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h2" fontWeight="medium">
                {trustDetail.TrustName}
              </MDTypography>
              <MDTypography variant="h4" color="text" fontWeight="regular">
                {trustDetail.TrustAddress}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
