import React, { useState, useEffect } from "react";
import './Style/trust.css'
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { FormControlLabel, IconButton } from "@material-ui/core";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { bindMenu } from 'material-ui-popup-state/hooks'
import { bindTrigger } from 'material-ui-popup-state/hooks'
import { CiSearch } from 'react-icons/ci'
import InputBase from '@material-ui/core/InputBase';
import { AiOutlineEye, AiOutlinePlus } from "react-icons/ai";
import Menu from "@mui/material/Menu";
import { CiExport } from "react-icons/ci"
import PopupState from "material-ui-popup-state";
import { MdTune } from 'react-icons/md'
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { addDays, startOfDay } from 'date-fns';
import { Modal, Pagination, Row, Switch, Text } from "@nextui-org/react";
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { userToken } from "Token";
import { getTrustDonationList } from "Api";
import axios from "axios";
import { getTrustDetailsPatch } from "Api";
import { AddDonationErrors, EditDonationErrors, formatDateEditDonation, formatDateFilter, formatDateForAddDonation, numberToWords } from "./Errors/Errors";
import { getAddDonation } from "Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getTrustDonationDetail } from "Api";
import { getUpdateDonation } from "Api";
import { getTrustDonationListAdmin } from "Api";
import { NavLink, useParams } from "react-router-dom";
import { getDownloadTrustCSV } from "Api";
import { getDownloadAdminCSV } from "Api";
import { getAdminarchiveId } from "Token";
import { getDonationDetailsForAdmin } from "Api";
import { BsDownload } from "react-icons/bs";
import { getDownloadDonationReceiptAdmin } from "Api";
import { getDeleteDonationReceiptAdmin } from "Api";
import { imageUrl } from "Api";
import { saveAs } from 'file-saver';

const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
}

function Trust() {
    const token = userToken();
    const archiveId = getAdminarchiveId();
    const trustId = useParams()
    const columns = [
        { field: 'srno', headerName: 'ID', width: 90 },
        { field: 'DonorName', headerName: 'Donor name', width: 250, editable: false, },
        { field: 'DonationAmount', headerName: 'Amount', width: 200, editable: false, },
        { field: 'DateofDonation', headerName: 'Date of donation', width: 250, editable: false, },
        { field: 'formattedDate', headerName: 'Created At', width: 250, editable: false, },
        { field: 'DonationType', headerName: 'Donation Type', width: 200, editable: false, },
        { field: 'PaymentType', headerName: 'Payment Type', width: 200, editable: false, },
        {
            field: 'action', headerName: 'Action', with: 150, editable: false,
            renderCell: (prevent) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }} >
                        <MatView index={prevent.row} />
                        <MatDownload index={prevent.row} />
                    </div>
                );
            },
        }
    ];

    const [viewDonation, setViewDonation] = useState({})
    const MatView = ({ index }) => {
        const handleViewClick = (e) => {
            handlerView();

            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            axios.get(`${getDonationDetailsForAdmin}/${index?._id}`)
                .then((res) => {
                    let ele = res.data.result;
                    if (typeof ele.TypeDonation == "object") {
                        ele.DonationType = donationTypes.find((item) => {
                            return item.value == (parseInt(ele.TypeDonation.DonationType))
                        })
                    } else {
                        ele.DonationType = donationTypes.find((item) => {
                            return item.value == (parseInt(ele.TypeDonation))
                        })
                    }
                    ele.Donor = DonorIdTypes.find((item) => {
                        return item.value == ele.DonorIdType
                    })
                    ele.paymentType = transactionTypes.find((item) => {
                        return item.value === ele.TransactionType
                    })
                    ele.onlineTransactionType = onlineTransactionType.find((item) => {
                        return item.value == ele.TransactionDetails?.OnlineTransactionType
                    })
                    ele.DonorIdType = DonorIdTypes.find((item) => {
                        return item.value == ele.DonorIdType
                    })
                    setViewDonation(res.data.result)
                })
        }
        return (
            <FormControlLabel
                control={
                    <IconButton color="secondary" aria-label="add an alarm" onClick={(e) => handleViewClick(e)} >
                        <AiOutlineEye />
                    </IconButton>
                }
            />
        );
    };

    const MatDownload = ({ index }) => {
        const handleViewClick = () => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

            axios.get(`${getDownloadDonationReceiptAdmin}/${index?._id}`)
                .then((res) => {
                    const url = `${imageUrl}/docs/${res.data.filename}`;
                    const fileName = `${index.receiptNo}.pdf`;
                    saveAs(url , fileName)
                    axios.get(`${getDeleteDonationReceiptAdmin}/${res.data.filename}`)
                })
        };


        return (
            <FormControlLabel
                control={
                    <>
                        <IconButton color="secondary" aria-label="add an alarm" onClick={(e) => handleViewClick(e)} >
                            <BsDownload />
                        </IconButton>
                    </>
                }
            />
        );
    };

    // View Donation Popup
    const [visibleView, setVisibleView] = React.useState(false);
    const handlerView = () => {
        setVisibleView(true)
    };
    const closeHandlerView = () => {
        setVisibleView(false)
    };

    // Export Popup
    const [visiblePass, setVisiblePass] = React.useState(false);
    const handlerPass = () => {
        setVisiblePass(true)
    };
    const closeHandlerPass = () => {
        setVisiblePass(false)
        setExportType(1)
    };

    // Variables
    const [donationList, setDonationList] = useState([])
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState({
        dateStart: "",
        dateEnd: ""
    })

    // Date Filter
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);

    const handleDateFilter = () => {
        setFilter({
            ...filter,
            dateStart: formatDateFilter(new Date(state[0].startDate)),
            dateEnd: formatDateFilter(new Date(state[0].endDate))
        })
    }

    // Pagination
    const [pageState, setPageState] = useState({
        total: 0,
        totalPages: 0,
        page: 1,
        pageSize: 10
    })
    const handlePageChange = (selectedPage) => {
        setPageState((prevState) => ({ ...prevState, page: selectedPage }));
    };
    const ResultInfo = () => {
        const start = (pageState.page - 1) * pageState.pageSize + 1;
        const end = Math.min(start + pageState.pageSize - 1, pageState.total);

        return (
            <div className="result-info">
                Showing <span>{start}-{end}</span> of <span>{pageState.total}</span> Trusts
            </div>
        );
    };

    // Reset Filter
    const handleResetFilter = () => {
        setFilter({
            dateStart: "",
            dateEnd: ""
        })
        setState([
            {
                startDate: startOfDay(new Date()),
                endDate: addDays(startOfDay(new Date()), 0),
                key: 'selection'
            }
        ]);
    }

    // Search 
    const handleSearch = (e) => {
        setSearch(e.target.value)
    }
    const [totalDonation, setTotalDonation] = useState(0)

    // Get All Donation List
    const fetchdonationList = () => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        axios.get(`${getTrustDonationListAdmin}/${trustId.id}?ArchiveId=${archiveId}&page=${pageState.page}&limit=${pageState.pageSize}&search=${search}&filterType=${filterType}&dateStart=${filter.dateStart}&dateEnd=${filter.dateEnd}`)
            .then((res) => {
                const json = res.data.result;
                setPageState((prevState) => ({ ...prevState, total: json.totalDocs, totalPages: json.totalPages }));

                setTotalDonation(json.totolDonation)
                res.data.result.docs.map((ele, i) => {
                    ele.id = i + 1;
                    ele.srno = (res.data.result.page * 10) + (i + 1) - 10;
                    const donationType = donationTypes.find((item) => {
                        if (typeof ele.TypeDonation == "object") {
                            return item.value == ele.TypeDonation.DonationType
                        } else {
                            return item.value == ele.TypeDonation
                        }
                    })
                    ele.DonationType = donationType?.label

                    const transactionType = transactionTypes.find((item) => {
                        return item.value == ele.TransactionType
                    })
                    ele.PaymentType = transactionType?.label
                    ele.formattedDate = formatDateForAddDonation(ele.createdAt);

                })
                setDonationList(res.data.result.docs)
            })
    }
    useEffect(() => {
        if (trustId.id != undefined) {
            fetchdonationList()
        }
    }, [search, filter, pageState.page, pageState.pageSize, trustId, archiveId])

    // Handle Filter by
    const [filterType, setFilterType] = useState(1)

    const handleFilterType = (e) => {
        setFilterType(parseInt(e.target.value))
    }

    // handle payment type
    const transactionTypes = [
        { value: 1, label: 'Cash' },
        { value: 2, label: 'Kind' },
        { value: 3, label: 'Cheque' },
        { value: 4, label: 'Online transaction' },
        { value: 5, label: 'Others' },
    ]

    // Handle Online Transaction Type
    const onlineTransactionType = [
        { value: 1, label: 'UPI' },
        { value: 2, label: 'NEFT' },
        { value: 3, label: 'RTGS' },
        { value: 4, label: 'IMPS' },
        { value: 5, label: 'ECS' },
        { value: 6, label: 'MICR' },
        { value: 7, label: 'Others' },
    ]

    // Handle Donation Type
    const donationTypes = [
        { value: 1, label: 'Specific grant' },
        { value: 2, label: 'Corpus' },
        { value: 3, label: 'Others' },
    ]

    // Handle Donor ID Type
    const [DonorIdTypes, setDonorIdTypes] = useState([
        { value: '1', label: 'Permanent Account Number' },
        { value: '2', label: 'Aadhaar Number' },
        { value: '3', label: 'Tax Identification Number' },
        { value: '4', label: 'Passport number' },
        { value: '5', label: "Elector's photo identity number" },
        { value: '6', label: 'Driving License number' },
        { value: '7', label: 'Ration card number' },
    ])

    // Export Date Range
    const [exportDate, setExportDate] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);

    // Export Filter
    const [exportFilter, setExportFilter] = useState({
        startDate: "",
        endDate: "",
        filterType: ""
    })

    // Handle Export Type
    const [exportType, setExportType] = useState(1)
    const handleExportType = (e) => {
        if (e.target.checked) {
            setExportType(1)
            setExportFilter({
                ...exportFilter,
                startDate: "",
                endDate: "",
                filterType: ""
            })
        } else {
            setExportType(2)
            setExportFilter({
                ...exportFilter,
                startDate: exportDate[0].startDate.toISOString(),
                endDate: exportDate[0].endDate.toISOString(),
                filterType: filterType
            })
        }
    }

    useEffect(() => {
        if (exportType === 2) {
            setExportFilter({
                ...exportFilter,
                startDate: exportDate[0].startDate.toISOString(),
                endDate: exportDate[0].endDate.toISOString()
            })
        } else {
            setExportFilter({
                ...exportFilter,
                startDate: "",
                endDate: "",
                filterType: ""
            })
        }
    }, [exportDate, exportType])

    // Export List API
    const handleExportList = (e) => {
        e.preventDefault();
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        axios.get(`${getDownloadAdminCSV}/${trustId.id}?filterType=${exportFilter.filterType}&startDate=${exportFilter.startDate}&endDate=${exportFilter.endDate}`)
            .then((res) => {
                const url = res.data.result;
                const link = document.createElement('a');
                link.href = url;
                link.download = url
                link.setAttribute('download', "demo.csv");
                link.click();
            })
            .catch((err) => {
                toast.warn("Something went wrong please try again !", toastStyle);
            })
    }



    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="trust-border">
                <div className="title_text roles-header">
                    <h4>Total Donation : {totalDonation}</h4>
                    <div className="button-for-trust-actions">
                        <NavLink className='common-btn' to={`/admin/archive/${trustId.id}`}>Archive List</NavLink>
                        <NavLink className='common-btn' to={`/admin/trust-detail/${trustId.id}`}>View Details</NavLink>
                        <Button variant="contained" onClick={handlerPass} className="fillter_btn common-btn" startIcon={<CiExport />} >
                            Export List
                        </Button>
                    </div>

                </div>
                <div className="innerusercontent">
                    <div className="Filteruser">
                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                                <React.Fragment>
                                    <Button variant="contained" className='fillter_btn common-btn' {...bindTrigger(popupState)} startIcon={<MdTune />} px={2}>Filter</Button>
                                    <Menu {...bindMenu(popupState)}>
                                        <div className="filter-model">
                                            <div className="filter-buttons">
                                                <Button className='rst-Btn-Lnk btn common-btn light' autoFocus onClick={(e) => handleResetFilter(e)} > Reset </Button>
                                                <Button className='aply-Btn-Lnk btn common-btn dark' autoFocus onClick={(e) => handleDateFilter(e)}> Apply </Button>
                                            </div>
                                        </div>
                                        <div className='HL'></div>
                                        <div className="datePiker">
                                            <div className="filter-type">
                                                <Form.Label>Filter By</Form.Label>
                                                <div className="paymentMethod">
                                                    <div className='radio'>
                                                        <Form.Check type="radio" name="filterType" value={1} checked={filterType === 1 ? true : false} id='date-of-donation' label="Date Of Donation" onChange={(e) => handleFilterType(e)} />
                                                    </div>
                                                    <div className='radio'>
                                                        <Form.Check type="radio" name="filterType" value={2} checked={filterType === 2 ? true : false} id='created-at' label="Created At" onChange={(e) => handleFilterType(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <DateRange
                                                    editableDateInputs={true}
                                                    onChange={item => setState([item.selection])}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={state}
                                                    rangeColors={['linear-gradient(195deg, #49a3f1, #1A73E8)', '#3ecf8e', '#fed14c']}
                                                />
                                            </div>
                                        </div>
                                    </Menu>
                                </React.Fragment>
                            )}
                        </PopupState>
                    </div>
                    <div className='searchbaar'>
                        <div className='serach_icon'>
                            <div className="serach_icon">
                                <CiSearch />
                            </div>
                        </div>
                        <InputBase
                            className="inputsearchbase base-css-trust"
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(e) => handleSearch(e)}
                        />
                    </div>
                </div>
                <MDBox py={3}>
                    <MDBox mt={1}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <MDBox mb={3} >
                                    <Box sx={{ height: 630, width: '100%', }} >
                                        <DataGrid
                                            rows={donationList}
                                            columns={columns}
                                            pageSize={pageState.pageSize}
                                        />
                                    </Box>
                                    <div className='pagination-block'>
                                        <ResultInfo />
                                        <Pagination
                                            loop
                                            color="success"
                                            total={pageState.totalPages}
                                            initialPage={1}
                                            onChange={(page) => handlePageChange(page)}
                                            currentPage={pageState.page}
                                        />
                                    </div>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            </div>

            {/* Export Donation List */}
            <Modal closeButton blur preventClose aria-labelledby="modal-title" className="AddDataModel" width={600} open={visiblePass} onClose={closeHandlerPass} >
                <Modal.Header justify="start">
                    <Text id="modal-title" size={24}>
                        Export Donation List of<Text b size={24}>  </Text>
                    </Text>
                </Modal.Header>
                <Modal.Body>
                    <div className="exportType">
                        <Form.Group className="radiobox w-50" controlId="donationType">
                            <Form.Label>Export All Donations</Form.Label>
                            <div className="paymentMethod">
                                <Switch bordered size="lg" color="warning" checked={exportType === 1 ? true : false} onChange={(e) => handleExportType(e)} />
                            </div>
                        </Form.Group>
                    </div>
                    {exportType === 2 ?
                        <>
                            <div className="filter-type">
                                <Form.Label>Filter By</Form.Label>
                                <div className="paymentMethod">
                                    <div className='radio'>
                                        <Form.Check type="radio" name="filterType" value={1} checked={filterType === 1 ? true : false} id='date-of-donation' label="Date Of Donation" onChange={(e) => handleFilterType(e)} />
                                    </div>
                                    <div className='radio'>
                                        <Form.Check type="radio" name="filterType" value={2} checked={filterType === 2 ? true : false} id='created-at' label="Created At" onChange={(e) => handleFilterType(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className="export-list-filter">
                                <DateRange
                                    className="w-100"
                                    editableDateInputs={true}
                                    onChange={item => setExportDate([item.selection])}
                                    moveRangeOnFirstSelection={false}
                                    ranges={exportDate}
                                    rangeColors={['linear-gradient(195deg, #49a3f1, #1A73E8)', '#3ecf8e', '#fed14c']}
                                />
                            </div>
                        </> : ""}
                </Modal.Body>
                <Modal.Footer>
                    <Button auto className="common-btn light" color="error" onClick={closeHandlerPass}>
                        cancel
                    </Button>
                    <Button auto className="common-btn" onClick={(e) => handleExportList(e)}>
                        Export List
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* View Donation List */}
            <Modal closeButton blur scroll aria-labelledby="modal-title" className="AddDataModel" width={1000} open={visibleView} onClose={closeHandlerView} >
                <Modal.Header justify="start">
                    <Text id="modal-title" size={24}>
                        Donation Details<Text b size={24}>  </Text>
                    </Text>
                </Modal.Header>
                <Modal.Body>
                    <div className="view-donation row">
                        <div className="view-donation-col">
                            <div className="view-tital">
                                <h2>Donor Information</h2>
                            </div>
                            <ul>
                                <li>
                                    <span>Donor Name :</span> {viewDonation.DonorName}
                                </li>
                                <li>
                                    <span>Donor Mobile No. :</span> {viewDonation.DonorMobile}
                                </li>
                                <li>
                                    <span>Donor Address :</span> {viewDonation.DonorAddress}
                                </li>
                                <li>
                                    <span>Donor ID type :</span> {viewDonation.DonorIdType?.label}
                                </li>
                                <li>
                                    <span>Donor ID No. :</span> {viewDonation.DonorIdNo}
                                </li>
                            </ul>
                        </div>
                        <div className="view-donation-col">
                            <div className="view-tital">
                                <h2>Donation Information</h2>
                            </div>
                            <ul>
                                <li>
                                    <span>Date Of Donation :</span> {viewDonation.DateofDonation}
                                </li>
                                <li>
                                    <span>Donation Receipt No. :</span> {viewDonation.receiptNo}
                                </li>
                                <li>
                                    <span>Donation Type :</span> {viewDonation.DonationType?.label}
                                </li>
                                {viewDonation.DonationType?.value === 3 ?
                                    <li>
                                        <span>Donation Note :</span> {viewDonation.TypeDonation?.DonationNote}
                                    </li> : ""}
                                <li>
                                    <span>Donation Amount :</span> {viewDonation.DonationAmount}
                                </li>
                                <li>
                                    <span>Donation Amount In Words :</span> {viewDonation.DonationAmountInWord}
                                </li>
                            </ul>
                        </div>
                        <div className="view-donation-col">
                            <div className="view-tital">
                                <h2>Donation Payment Information</h2>
                            </div>
                            <ul>
                                <li>
                                    <span>Donation Payment Method :</span> {viewDonation.paymentType?.label}
                                </li>
                                {viewDonation.paymentType?.value === 2 ?
                                    <li>
                                        <span>Donation Kind :</span> {viewDonation?.TransactionDetails}
                                    </li> : viewDonation.paymentType?.value === 3 ?
                                        <>
                                            <li>
                                                <span>Bank Name :</span> {viewDonation.TransactionDetails?.BankName}
                                            </li>
                                            <li>
                                                <span>Cheque No. :</span> {viewDonation.TransactionDetails?.ChequeNo}
                                            </li>
                                            <li>
                                                <span>Cheque Date :</span> {viewDonation.TransactionDetails?.ChequeDate}
                                            </li>
                                        </> : viewDonation.paymentType?.value === 4 ?
                                            <>
                                                <li>
                                                    <span>Online Transaction type :</span> {viewDonation.onlineTransactionType?.label}
                                                </li>
                                                <li>
                                                    <span>Transaction Id :</span> {viewDonation.TransactionDetails?.TransactionId}
                                                </li>
                                            </> : viewDonation.paymentType?.value === 5 ?
                                                <li>
                                                    <span>Other Payment Method :</span> {viewDonation.onlineTransactionType?.label}
                                                </li> : ""
                                }
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button auto className="common-btn light" color="error" onClick={closeHandlerView}>
                        close
                    </Button>
                    <Button auto className="common-btn" >
                        Download Receipt
                    </Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </DashboardLayout>
    )
}

export default Trust