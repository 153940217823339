// @mui material components
import './style/Profile.css'

// Donation Receipt Generator React components
import MDBox from "components/MDBox";

// Donation Receipt Generator React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Overview page components
import Header from "layouts/profile/components/Header";
import { userToken } from 'Token';
import axios from 'axios';
import { getTrustDetailsPatch } from 'Api';
import React, { useEffect, useState } from 'react';
import MDAvatar from 'components/MDAvatar';
import { Card, Grid } from '@mui/material';
import MDTypography from 'components/MDTypography';
import burceMars from "assets/images/DefaultProfile.png";
import MDButton from 'components/MDButton';
import { Button, FormControlLabel, IconButton } from '@material-ui/core';
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { getTrustDetails } from 'Api';
import { getImageUpload } from 'Api';
import DefaultImage from 'assets/images/DefaultProfile.png'
import { Form } from 'react-bootstrap';
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined'
import Select from 'react-select';
import { Modal, Row, Text } from '@nextui-org/react';
import { getUpdateTrust } from 'Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getEditTrust } from 'Api';
import { Crypt } from 'hybrid-crypto-js';
import { getChangeTrustpassword } from 'Api';
import { convertEditDateFormat } from 'layouts/Trust/Errors/Validation';
import { newformatDate } from 'layouts/Trust/Errors/Validation';

const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}


function Overview() {
  var crypt = new Crypt();
  // Variables
  const [trustDetail, setTrustDetail] = useState()

  const token = userToken();

  // Add Trust Strp Form
  const [sectionCodes, setSectionCodes] = useState([
    { value: 1, label: 'Section 80G' },
    { value: 2, label: 'Section 35(1)(iia)' },
    { value: 3, label: 'Section 35(1)(ii)' },
    { value: 4, label: 'Section 35(1)(iii)' },
  ])

  const [trustLogo, settrustLogo] = useState(burceMars)

  // Get Trust Details
  const fetchTrustDetails = () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.patch(getTrustDetailsPatch)
      .then((res) => {
        if (res.data.user.TrustLogo !== "") {
          if (res.data.user.TrustLogo.includes('http://') || res.data.user.TrustLogo.includes('https://')) {
            settrustLogo(`${res.data.user.TrustLogo}`)
          } else {
            settrustLogo(`https://${res.data.user.TrustLogo}`)
          }
        }
        console.log(res.data.user.TrustLogo);
        const temp = sectionCodes.find((ele) => {
          return ele.value === res.data.user.TrustSectionCode
        })
        res.data.user.TrustNote.TrustNoteSection = temp.label
        res.data.user.trustSecCode = temp.label

        const editData = res.data.user
        if (editData.TrustLogo.includes('http://') || editData.TrustLogo.includes('https://')) {
          setTrustLogoImage({
            url: `${editData.TrustLogo}`,
            uploaded: true
          });
        } else {
          setTrustLogoImage({
            url: `https://${editData.TrustLogo}`,
            uploaded: true
          });
          editData.TrustLogo = `https://${editData.TrustLogo}`;
        }

        if (editData.TrustSign.includes('http://') || editData.TrustSign.includes('https://')) {
          setTrustSignatureImage({
            url: `${editData.TrustSign}`,
            uploaded: true
          });
        } else {
          setTrustSignatureImage({
            url: `https://${editData.TrustSign}`,
            uploaded: true
          });
          editData.TrustSign = `https://${editData.TrustSign}`;
        }
        setStep1DataEdit({
          ...step1DataEdit,
          id: editData?._id,
          trustLogo: editData.TrustLogo,
          TrustSign: editData.TrustSign,
          TrustName: editData.TrustName,
          TrustCity: editData.TrustCity,
        })
        setStep2DataEdit({
          ...step2DataEdit,
          TrustEmail: editData.TrustEmail,
          TrustMobile: editData.TrustMobile,
          TrustFax: editData.TrustFax,
          TrustWebsite: editData.TrustWebsite,
          TrustAddress: editData.TrustAddress,
          TrustNote: editData.TrustNote,
        })
        setStep3DataEdit({
          ...step3DataEdit,
          TrustPan: editData.TrustPan,
          TrustGST: editData.TrustGST,
          TrustSectionCode: editData.TrustSectionCode,
          TrustUrnNo: editData.TrustUrnNo,
          // TrustUrnRegistrationDate: newformatDate(editData.TrustUrnRegistrationDate),
          TrustUrnRegistrationDate: editData.TrustUrnRegistrationDate,
        })
        setStepDataEdit({
          ...stepDataEdit,
          TrustFormNo: editData.TrustNote.TrustFormNo,
          AYStart: editData.TrustNote.AYStart,
          AYStop: editData.TrustNote.AYStop,
          TrustRegnNo: editData.TrustNote.TrustRegnNo,
          YearType: editData.TrustNote.YearType,
        })
        setStep4DataEdit({
          ...step4DataEdit,
          Password: editData.Password
        })
        const sectionVal = sectionCodes.find((ele) => { return ele.value == editData.TrustSectionCode })
        setSectionValue(sectionVal)

        const newFormNo = formNo.find((ele) => { return ele.label === editData.TrustNote.TrustFormNo })
        setFormNoValue(newFormNo)

        const newTrustClause = clause.find((ele) => { return ele.value == editData.TrustNote.TrustClause })
        if (newTrustClause) {
          res.data.user.TrustNote.TrustClauseName = newTrustClause?.label;
          setClauseValue(newTrustClause)
        }

        const newAYStart = ayOptions.find((ele) => { return ele.label === editData.TrustNote.AYStart })
        setAyStart(newAYStart)

        const newAYStop = ayOptions.find((ele) => { return ele.label === editData.TrustNote.AYStop })
        setAyStop(newAYStop)

        const AYVal = AYType.find((ele) => { return ele.value == editData.TrustNote.YearType })
        setAYValue(AYVal)

        setTrustDetail(res.data.user)
      })
  }
  useEffect(() => {
    if (token !== "" && token !== undefined) {
      fetchTrustDetails()
    }
  }, [token])

  // Edit Trust
  const [step1DataEdit, setStep1DataEdit] = useState({
    id: "",
    trustLogo: "",
    TrustSign: "",
    TrustName: "",
    TrustCity: "",
  })
  const [step2DataEdit, setStep2DataEdit] = useState({
    TrustEmail: "",
    TrustMobile: "",
    TrustFax: "",
    TrustWebsite: "",
    TrustAddress: "",
    TrustNote: "",
  })
  const [step3DataEdit, setStep3DataEdit] = useState({
    TrustPan: "",
    TrustGST: "",
    TrustSectionCode: "",
    TrustUrnNo: "",
    TrustUrnRegistrationDate: "",
  })
  const [step4DataEdit, setStep4DataEdit] = useState({
    Password: "",
  })
  const [stepDataEdit, setStepDataEdit] = useState({
    TrustFormNo: "",
    AYStart: "",
    AYStop: "",
    TrustRegnNo: "",
    YearType: ""
  })

  // Errors
  const [stepForm1ErrorEdit, setStepForm1ErrorEdit] = useState({
    logoError: "",
    signError: "",
    cityError: "",
    nameError: ""
  });
  const [stepForm2ErrorEdit, setStepForm2ErrorEdit] = useState({
    emailError: "",
    mobileError: "",
    faxError: "",
    websiteError: "",
    addressError: "",
    noteError: "",
  })
  const [stepForm3ErrorEdit, setStepForm3ErrorEdit] = useState({
    panError: "",
    GSTError: "",
    sectionCodeError: "",
    urnNoError: "",
    urnRegistrationDateError: "",
  })
  const [formError, stepFormError] = useState("")


  // Trust Signature
  const uploadedSignature = React.useRef(null);
  const imageUploaderSignature = React.useRef(null);
  const [trustSignatureImage, setTrustSignatureImage] = useState({
    url: DefaultImage,
    uploaded: false
  });

  const handleSignatureUpload = (e, data) => {
    const [file] = e.target.files;

    if (file) {
      const reader = new FileReader();
      const { current } = uploadedSignature;
      current.file = file;
      reader.onload = e => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      const userImage = new FormData();
      userImage.append('Image', file);
      axios.post(getImageUpload, userImage)
        .then((res) => {
          if (res.data.urlArray[0].includes('http://') || res.data.urlArray[0].includes('https://')) {
            setTrustSignatureImage({
              url: res.data.urlArray[0],
              uploaded: true
            });
          } else {
            setTrustSignatureImage({
              url: `https://${res.data.urlArray[0]}`,
              uploaded: true
            });
          }
          if (data === "Edit") {
            setStep1DataEdit({
              ...step1DataEdit,
              TrustSign: res.data.urlArray[0]
            });
          }
        });
    }
  };

  // Trust Logo
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const [trustLogoImage, setTrustLogoImage] = useState({
    url: DefaultImage,
    uploaded: false
  });

  const handleImageUpload = (e, data) => {
    const [file] = e.target.files;

    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = e => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      const userImage = new FormData();
      userImage.append('Image', file);
      axios.post(getImageUpload, userImage)
        .then((res) => {
          if (res.data.urlArray[0].includes('http://') || res.data.urlArray[0].includes('https://')) {
            setTrustLogoImage({
              url: res.data.urlArray[0],
              uploaded: true
            });
          } else {
            setTrustLogoImage({
              url: `https://${res.data.urlArray[0]}`,
              uploaded: true
            });
          }
          if (data === "Edit") {
            setStep1DataEdit({
              ...step1DataEdit,
              trustLogo: res.data.urlArray[0]
            });
          }
        });
    }
  };

  // Steps
  const [step, setStep] = useState(1);


  // Edit trust model Variables
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const handlerEdit = () => {
    fetchTrustDetails()
    setVisibleEdit(true)
    setStep(1)
  };
  const closeHandlerEdit = () => {
    setVisibleEdit(false)
    setTrustLogoImage({
      url: DefaultImage,
      uploaded: false
    })
    setStep1DataEdit({
      trustLogo: "",
      TrustSign: "",
      TrustName: ""
    })
    setStep2DataEdit({
      TrustEmail: "",
      TrustMobile: "",
      TrustFax: "",
      TrustWebsite: "",
      TrustAddress: "",
      TrustNote: "",
    })
    setStep3DataEdit({
      TrustPan: "",
      TrustGST: "",
      TrustSectionCode: "",
      TrustUrnNo: "",
      TrustUrnRegistrationDate: "",
    })
    setSectionValue([])
    setErrorE1(false)
    setErrorE2(false)
    setErrorE3(false)
    setTimeout(() => {
      setStep(1)
    }, 2000)
  };

  // Handle Section Code
  const [sectionValue, setSectionValue] = useState([]);
  const handleSectionCode = (e, data) => {
    const sectionVal = sectionCodes.find((ele) => { return ele.value == e.value })
    setSectionValue(sectionVal)
    if (data === "SectionCodeEdit") {
      setStep3DataEdit({
        ...step3DataEdit,
        TrustSectionCode: e.value
      })
    }
  }

  // Add Trust Form No
  const [formNo, setFormNo] = useState([
    { value: 1, label: '10 AC' },
    { value: 2, label: '10 AD' },
  ])

  const [formNoValue, setFormNoValue] = useState([]);
  const handleFormNoCode = (e, data) => {
    const value = formNo.find((ele) => { return ele.value == e.value })
    setFormNoValue(value)
    if (data === "FormNoEdit") {
      setStepDataEdit({
        ...stepDataEdit,
        TrustFormNo: value.label
      })
    }
  }

  // Add clause No
  const [clause, setClause] = useState([
    { value: 1, label: 'Clause (i)' },
    { value: 3, label: 'Clause (iii)' },
    { value: 4, label: 'Clause (iv)' },
  ])

  const [clauseValue, setClauseValue] = useState([]);
  const handleClauseCode = (e, data) => {
    const value = clause.find((ele) => { return ele.value == e.value })
    setClauseValue(value)

    if (data === "clauseEdit") {
      setStepDataEdit({
        ...stepDataEdit,
        TrustClause: value.value
      })
    }
  }

  // AY Years
  const ayOptions = [];
  for (let year = 2000; year <= 2100; year++) {
    const academicYear = `${year}-${(year + 1).toString().slice(-2)}`;
    const option = { value: year - 2000 + 1, label: academicYear };
    ayOptions.push(option);
  }
  const [ayStart, setAyStart] = useState([]);
  const [ayStop, setAyStop] = useState([]);

  const handleAyDate = (e, data) => {
    if (data === "edit") {
      setStepDataEdit({
        ...stepDataEdit,
        AYStart: e.target.value
      })
    }
  }
  const handleAyYear = (e, data, type) => {
    if (type === "Edit") {
      if (data === "Start") {
        const sectionVal = ayOptions.find((ele) => { return ele.value == e.value })
        setAyStart(sectionVal)
        setStepDataEdit({
          ...stepDataEdit,
          AYStart: sectionVal.label
        })
      }
      if (data === "Stop") {
        const sectionVal = ayOptions.find((ele) => { return ele.value == e.value })
        setAyStop(sectionVal)
        setStepDataEdit({
          ...stepDataEdit,
          AYStop: sectionVal.label
        })
      }
    }
  }

  // AY Or Date For Donation Note
  const [AYType, setAYType] = useState([
    { value: 1, label: 'AY' },
    { value: 2, label: 'Date' },
  ])

  const [AYValue, setAYValue] = useState({ value: 1, label: 'AY' });
  const handleAYValue = (e, data) => {
    setAyStart([])
    const AYVal = AYType.find((ele) => { return ele.value === e.value })
    setAYValue(AYVal)
    if (data === "edit") {
      setStepDataEdit({
        ...stepDataEdit,
        YearType: e?.value
      })
    }
  }

  // Step Sidebar Error Edit Trust
  const [errorE1, setErrorE1] = useState(false);
  const [errorE2, setErrorE2] = useState(false);
  const [errorE3, setErrorE3] = useState(false);
  const [errorE4, setErrorE4] = useState(false);

  useEffect(() => {
    const updateStepFormError = (step, error, setError, data) => {
      let hasError = Object.values(data).some((value) => value === "" || value === undefined);
      if (data.TrustFax === "" || data.TrustWebsite === "") {
        hasError = false
      }
      setError(hasError);
    };

    updateStepFormError(step, errorE1, setErrorE1, step1DataEdit);
    updateStepFormError(step, errorE2, setErrorE2, step2DataEdit);
    updateStepFormError(step, errorE3, setErrorE3, step3DataEdit);
    updateStepFormError(step, errorE4, setErrorE4, stepDataEdit);
  }, [step, step1DataEdit, step2DataEdit, step3DataEdit]);

  // Edit Button
  const [nextButtonClickedEdit, setNextButtonClickedEdit] = useState(false);
  const [allError, setAllError] = useState(false);
  const handleNextEdit = () => {
    setNextButtonClickedEdit(true);
    if (
      (step === 1 &&
        stepForm1ErrorEdit.logoError !== "" ||
        stepForm1ErrorEdit.signError !== "" ||
        stepForm1ErrorEdit.cityError !== "" ||
        stepForm1ErrorEdit.nameError !== "") ||
      (step === 2 &&
        (stepForm2ErrorEdit.emailError !== "" ||
          stepForm2ErrorEdit.mobileError !== "" ||
          // stepForm2ErrorEdit.faxError !== "" ||
          // stepForm2ErrorEdit.websiteError !== "" ||
          stepForm2ErrorEdit.addressError !== "")) ||
      (step === 3 &&
        (stepForm3ErrorEdit.panError !== "" ||
          // stepForm3ErrorEdit.GSTError !== "" ||
          stepForm3ErrorEdit.sectionCodeError !== "" ||
          stepForm3ErrorEdit.urnNoError !== "" ||
          stepForm3ErrorEdit.urnRegistrationDateError !== "")) ||
      (step === 4 &&
        (formError !== ""))
    ) {
      setAllError(true)
      return;
    }

    setStep(step + 1);
    setAllError(false)
    setNextButtonClickedEdit(false); // Reset the nextButtonClicked state to false
  };

  const handleBackEdit = () => {
    setStep(step - 1);
  };

  // Get New Trust Data
  const handleNewTrustData = (e, data, name) => {
    if (data === "Edit") {
      if (step === 1) {
        setStep1DataEdit({
          ...step1DataEdit,
          [e.target.name]: e.target.value
        })
      }
      if (step === 2) {
        setStep2DataEdit({
          ...step2DataEdit,
          [e.target.name]: e.target.value
        })
      }
      if (step === 3) {
        setStep3DataEdit({
          ...step3DataEdit,
          [e.target.name]: e.target.value
        })
      }
      if (name === "REG") {
        setStepDataEdit({
          ...stepDataEdit,
          TrustRegnNo: e.target.value,
        })
      }
      if (name === "PAN") {
        setStep3DataEdit({
          ...step3DataEdit,
          TrustPan: e.target.value,
        })
      }
      if (name === "URN") {
        setStep3DataEdit({
          ...step3DataEdit,
          TrustUrnNo: e.target.value,
        })
      }
      if (name === "URNDate") {
        setStep3DataEdit({
          ...step3DataEdit,
          TrustUrnRegistrationDate: e.target.value
        })
      }
    }
  }


  // Edit Trust Forn
  const renderFormEdit = () => {
    switch (step) {
      case 1:
        return (
          <div className="Step-form w-100">
            <div className="Step form-row step-1">
              <Form.Group controlId="formBasicImage">
                <Form.Label>Trust Logo <span className="reqired-star">*</span></Form.Label>
                <div className="mt-2">
                  <input type="file" accept="image/*" onChange={(e) => handleImageUpload(e, "Edit")} ref={imageUploader} style={{ display: "none" }} />
                  <div className="TrustProfileLogo" onClick={() => imageUploader.current.click()} >
                    <img ref={uploadedImage} className="ProfileImg" src={trustLogoImage.uploaded ? trustLogoImage.url : trustLogoImage.url} name="productImage" alt="Trust Logo" />
                    <p className="ProfileUploadImgTitle"> <ImageSearchOutlinedIcon /> <span>Upload Image</span>{" "} </p>
                  </div>
                </div>
                {nextButtonClickedEdit && <p className="errorMessage">{stepForm1ErrorEdit.logoError}</p>}
              </Form.Group>
              <Form.Group controlId="formBasicImage">
                <Form.Label>Trust Signature <span className="reqired-star">*</span></Form.Label>
                <div className="mt-2">
                  <input type="file" accept="image/*" onChange={(e) => handleSignatureUpload(e, "Edit")} ref={imageUploaderSignature} style={{ display: "none" }} />
                  <div className="TrustProfileLogo" onClick={() => imageUploaderSignature.current.click()} >
                    <img ref={uploadedSignature} className="ProfileImg" src={trustSignatureImage.uploaded ? trustSignatureImage.url : trustSignatureImage.url} name="productImage" alt="Trust Logo" />
                    <p className="ProfileUploadImgTitle"> <ImageSearchOutlinedIcon /> <span>Upload Image</span>{" "} </p>
                  </div>
                </div>
                {nextButtonClickedEdit && <p className="errorMessage">{stepForm1ErrorEdit.signError}</p>}
              </Form.Group>
              <Form.Group className="form-control w-fill" controlId="TrustName">
                <Form.Label>Trust Name <span className="reqired-star">*</span></Form.Label>
                <div className="group">
                  <Form.Control
                    type="text"
                    placeholder="Trust Name"
                    name="TrustName"
                    value={step1DataEdit.TrustName}
                    onChange={(e) => handleNewTrustData(e, "Edit")}
                  />
                </div>
                {nextButtonClickedEdit && <p className="errorMessage">{stepForm1ErrorEdit.nameError}</p>}
              </Form.Group>
              <Form.Group className="form-control w-fill" controlId="TrustName">
                <Form.Label>Trust City <span className="reqired-star">*</span></Form.Label>
                <div className="group">
                  <Form.Control
                    type="text"
                    placeholder="Trust City"
                    name="TrustCity"
                    value={step1DataEdit.TrustCity}
                    onChange={(e) => handleNewTrustData(e, "Edit")}
                  />
                </div>
                {nextButtonClickedEdit && <p className="errorMessage">{stepForm1ErrorEdit.cityError}</p>}
              </Form.Group>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="Step-form w-100">
            <div className="Step form-row step-2">
              <Form.Group className="form-control w-50" controlId="TrustEmail">
                <Form.Label>Trust Email <span className="reqired-star">*</span></Form.Label>
                <div className="group">
                  <Form.Control
                    type="email"
                    placeholder="Trust Email"
                    name="TrustEmail"
                    value={step2DataEdit.TrustEmail}
                    onChange={(e) => handleNewTrustData(e, "Edit")}
                  />
                </div>
                {nextButtonClickedEdit && <p className="errorMessage">{stepForm2ErrorEdit.emailError}</p>}
              </Form.Group>

              <Form.Group className="form-control w-50" controlId="TrustMobile">
                <Form.Label>Trust Mobile no. <span className="reqired-star">*</span></Form.Label>
                <div className="group">
                  <Form.Control
                    type="number"
                    placeholder="Trust Mobile no."
                    name="TrustMobile"
                    value={step2DataEdit.TrustMobile}
                    onChange={(e) => handleNewTrustData(e, "Edit")}
                  />
                </div>
                {nextButtonClickedEdit && <p className="errorMessage">{stepForm2ErrorEdit.mobileError}</p>}
              </Form.Group>
            </div>
            <div className="Step form-row step-2">
              <Form.Group className="form-control w-50" controlId="TrustFax">
                <Form.Label>Trust Fax no.</Form.Label>
                <div className="group">
                  <Form.Control
                    type="email"
                    placeholder="Trust Fax no."
                    name="TrustFax"
                    value={step2DataEdit.TrustFax}
                    onChange={(e) => handleNewTrustData(e, "Edit")}
                  />
                </div>
                {nextButtonClickedEdit && <p className="errorMessage">{stepForm2ErrorEdit.faxError}</p>}
              </Form.Group>

              <Form.Group className="form-control w-50" controlId="TrustWebsite">
                <Form.Label>Trust Website</Form.Label>
                <div className="group">
                  <Form.Control
                    type="text"
                    placeholder="Trust Website."
                    name="TrustWebsite"
                    value={step2DataEdit.TrustWebsite}
                    onChange={(e) => handleNewTrustData(e, "Edit")}
                  />
                </div>
                {nextButtonClickedEdit && <p className="errorMessage">{stepForm2ErrorEdit.websiteError}</p>}
              </Form.Group>
            </div>
            <div className="Step form-row step-2">
              <Form.Group className="form-control w-100" controlId="TrustAddress">
                <Form.Label>Trust Address <span className="reqired-star">*</span></Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Trust Address"
                  name="TrustAddress"
                  value={step2DataEdit.TrustAddress}
                  onChange={(e) => handleNewTrustData(e, "Edit")}
                />
                {nextButtonClickedEdit && <p className="errorMessage">{stepForm2ErrorEdit.addressError}</p>}
              </Form.Group>
            </div>
            {/* <div className="Step form-row step-2">
                        <Form.Group className="form-control w-100" controlId="TrustNote">
                            <Form.Label>Note of trust <span className="reqired-star">*</span></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Note of trust"
                                name="TrustNote"
                                value={step2DataEdit.TrustNote}
                                onChange={(e) => handleNewTrustData(e, "Edit")}
                            />
                            {nextButtonClickedEdit && <p className="errorMessage">{stepForm2ErrorEdit.noteError}</p>}
                        </Form.Group>
                    </div> */}
          </div>
        );
      case 3:
        return (
          <div className="Step-form w-100">
            <div className="Step form-row step-3">
              <Form.Group className="form-control w-50" controlId="TrustPan">
                <Form.Label>Trust PAN no. <span className="reqired-star">*</span></Form.Label>
                <div className="group">
                  <Form.Control
                    type="text"
                    placeholder="Trust PAN no."
                    name="TrustPan"
                    value={step3DataEdit.TrustPan}
                    onChange={(e) => handleNewTrustData(e, "Edit")}
                  />
                </div>
                {nextButtonClickedEdit && <p className="errorMessage">{stepForm3ErrorEdit.panError}</p>}
              </Form.Group>

              <Form.Group className="form-control w-50" controlId="TrustGST">
                <Form.Label>Trust GST no.</Form.Label>
                <div className="group">
                  <Form.Control
                    type="text"
                    placeholder="Trust GST no."
                    name="TrustGST"
                    value={step3DataEdit.TrustGST}
                    onChange={(e) => handleNewTrustData(e, "Edit")}
                  />
                </div>
                {nextButtonClickedEdit && <p className="errorMessage">{stepForm3ErrorEdit.GSTError}</p>}
              </Form.Group>
            </div>
            <div className="Step form-row step-3">
              <Form.Group className="form-control w-50" controlId="TrustSectionCode">
                <Form.Label>Trust Section Code <span className="reqired-star">*</span></Form.Label>
                <Select
                  className="customSelect"
                  closeMenuOnSelect={true}
                  options={sectionCodes}
                  value={sectionValue}
                  name="TrustSectionCode"
                  onChange={(e) => handleSectionCode(e, "SectionCodeEdit")}
                  placeholder="Trust Section Code"
                />
                {nextButtonClickedEdit && <p className="errorMessage">{stepForm3ErrorEdit.sectionCodeError}</p>}
              </Form.Group>

              <Form.Group className="form-control w-50" controlId="TrustUrnNo">
                <Form.Label>Trust URN no. <span className="reqired-star">*</span></Form.Label>
                <div className="group">
                  <Form.Control
                    type="text"
                    placeholder="Trust URN no."
                    name="TrustUrnNo"
                    value={step3DataEdit.TrustUrnNo}
                    onChange={(e) => handleNewTrustData(e, "Edit")}
                  />
                </div>
                {nextButtonClickedEdit && <p className="errorMessage">{stepForm3ErrorEdit.urnNoError}</p>}
              </Form.Group>
            </div>
            <div className="Step form-row step-3">
              <Form.Group
                className="form-control w-50"
                controlId="TrustUrnRegistrationDate"
              >
                <Form.Label>Trust URN Registration Date <span className="reqired-star">*</span></Form.Label>
                <div className="group">
                  <Form.Control
                    type="date"
                    placeholder="Trust Section Code"
                    name="TrustUrnRegistrationDate"
                    value={step3DataEdit.TrustUrnRegistrationDate}
                    onChange={(e) => handleNewTrustData(e, "Edit")}
                  />
                </div>
                {nextButtonClickedEdit && <p className="errorMessage">{stepForm3ErrorEdit.urnRegistrationDateError}</p>}
              </Form.Group>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="Step-form w-100">
            <div className="Step form-row step-4">
              <Form.Group className="form-control w-100" controlId="Password">
                <Form.Label>Trust Note <span className="reqired-star">*</span></Form.Label>
                <div className="group">
                  <p>Trust is registered under Bombay Public Trust Act, 1950 vide Regn. No.
                    <Form.Control
                      className="w-auto trustNote"
                      type="text"
                      placeholder="Regn no."
                      name="TrustRegnNo"
                      value={stepDataEdit.TrustRegnNo}
                      onChange={(e) => handleNewTrustData(e, "Edit", "REG")}
                    />
                    PAN NO.
                    <Form.Control
                      className="w-auto trustNote"
                      type="text"
                      placeholder="Trust PAN no."
                      name="TrustPan"
                      value={step3DataEdit.TrustPan}
                      onChange={(e) => handleNewTrustData(e, "Edit", "PAN")}
                    />
                    I.T (Exemptions)
                    Approval under Form No.
                    <Select
                      className="customSelect w-auto trustNote"
                      closeMenuOnSelect={true}
                      options={formNo}
                      value={formNoValue}
                      name="TrustFormNo"
                      onChange={(e) => handleFormNoCode(e, "FormNoEdit")}
                      placeholder="Trust Form No"
                    />
                    with URN:
                    <Form.Control
                      className="w-auto trustNote"
                      type="text"
                      placeholder="Trust URN no."
                      name="TrustUrnNo"
                      value={step3DataEdit.TrustUrnNo}
                      onChange={(e) => handleNewTrustData(e, "Edit", "URN")}
                    />
                    Granted under section-
                    <Select
                      className="customSelect w-auto trustNote"
                      closeMenuOnSelect={true}
                      options={clause}
                      value={clauseValue}
                      name="TrustClause"
                      onChange={(e) => handleClauseCode(e, "clauseEdit")}
                      placeholder="Trust Clause"
                    />
                    of First proviso to sub-section (5) of
                    <Select
                      className="customSelect w-auto trustNote"
                      closeMenuOnSelect={true}
                      options={sectionCodes}
                      value={sectionValue}
                      name="TrustSectionCode"
                      onChange={(e) => handleSectionCode(e, "SectionCode")}
                      placeholder="Trust Section Code"
                    />
                    on
                    <Form.Control
                      className="w-auto trustNote"
                      type="date"
                      placeholder="Trust Section Code"
                      name="TrustUrnRegistrationDate"
                      value={step3DataEdit.TrustUrnRegistrationDate}
                      max={new Date().toISOString().split('T')[0]}
                      onChange={(e) => handleNewTrustData(e, "Edit", "URNDate")}
                    />
                    Valid from
                    <Select
                      className="customSelect w-auto trustNote"
                      closeMenuOnSelect={true}
                      options={AYType}
                      value={AYValue}
                      name="YearType"
                      onChange={(e) => handleAYValue(e, "edit")}
                      placeholder="AY Type"
                    />
                    {
                      AYValue && AYValue.value === 1 ?
                        <Select
                          className="customSelect w-auto trustNote"
                          closeMenuOnSelect={true}
                          options={ayOptions}
                          value={ayStart}
                          name="TrustSectionCode"
                          onChange={(e) => handleAyYear(e, "Start", "Edit")}
                          placeholder="AY start"
                        /> :
                        <Form.Control
                          className=" w-auto"
                          type="date"
                          placeholder="AY Date"
                          name="AYStart"
                          value={stepDataEdit.AYStart}
                          onChange={(e) => handleAyDate(e, "edit")}
                        />
                    }
                    &nbsp;to A.Y.
                    <Select
                      className="customSelect w-auto trustNote"
                      closeMenuOnSelect={true}
                      options={ayOptions}
                      value={ayStop}
                      name="TrustSectionCode"
                      onChange={(e) => handleAyYear(e, "Stop", "Edit")}
                      placeholder="AY End Year"
                    />
                  </p>
                </div>
                {nextButtonClickedEdit && <p className="errorMessage">{formError}</p>}
              </Form.Group>
            </div>
          </div>
        );
      case 5:
        return (
          <div className="Step-form successBlock w-100">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <h3 className="center">Trust Edited Sucessfully</h3>
          </div>
        );
      default:
        return null;
    }
  };

  // Edit Trust Popup Buttons
  const renderButtonsEdit = () => {
    if (step === 1) {
      return (
        <Modal.Footer>
          <Button auto className="common-btn" onClick={handleNextEdit}>
            Next
          </Button>
        </Modal.Footer>
      );
    } else if (step === 4) {
      return (
        <Modal.Footer>
          <Button auto className="common-btn light" color="error" onClick={handleBackEdit}>
            Back
          </Button>
        </Modal.Footer>
      );
    } else if (step === 5) {
      return (
        ""
      );
    } else {
      return (
        <Modal.Footer>
          <Button auto className="common-btn light" color="error" onClick={handleBackEdit}>
            Back
          </Button>
          <Button auto className="common-btn" onClick={handleNextEdit}>
            Next
          </Button>
        </Modal.Footer>
      );
    }
  };

  // Step Sidebar
  const handleSidebarStep = (e, value) => {
    setStep(value);
  };

  // Edit Trust API
  const handleSubmitEdit = () => {
    const editTrustData = new FormData();
    editTrustData.append("trustLogo", step1DataEdit.trustLogo)
    editTrustData.append("TrustSign", step1DataEdit.TrustSign)
    editTrustData.append("TrustName", step1DataEdit.TrustName)
    editTrustData.append("TrustCity", step1DataEdit.TrustCity)
    editTrustData.append("TrustEmail", step2DataEdit.TrustEmail)
    editTrustData.append("TrustMobile", step2DataEdit.TrustMobile)
    editTrustData.append("TrustFax", step2DataEdit.TrustFax)
    editTrustData.append("TrustWebsite", step2DataEdit.TrustWebsite)
    editTrustData.append("TrustAddress", step2DataEdit.TrustAddress)
    editTrustData.append("TrustNote", step2DataEdit.TrustNote)
    editTrustData.append("TrustPan", step3DataEdit.TrustPan)
    editTrustData.append("TrustGST", step3DataEdit.TrustGST)
    editTrustData.append("TrustSectionCode", step3DataEdit.TrustSectionCode)
    editTrustData.append("TrustUrnNo", step3DataEdit.TrustUrnNo)
    editTrustData.append("TrustUrnRegistrationDate", step3DataEdit.TrustUrnRegistrationDate)
    editTrustData.append("Password", step4DataEdit.Password)
    editTrustData.append("TrustFormNo", stepDataEdit.TrustFormNo)
    editTrustData.append("TrustClause", stepDataEdit.TrustClause)
    editTrustData.append("AYStart", stepDataEdit.AYStart)
    editTrustData.append("AYStop", stepDataEdit.AYStop)
    editTrustData.append("TrustRegnNo", stepDataEdit.TrustRegnNo)
    editTrustData.append("YearType", stepDataEdit.YearType)

    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    if (!allError) {
      axios.put(`${getEditTrust}/${step1DataEdit.id}`, editTrustData)
        .then((res) => {
          setStep(5)
          fetchTrustDetails()
          toast.success(res.data.message, toastStyle);
        })
        .catch((err) => {
          toast.error(err.response.data.message, toastStyle);
        })
    }
  }

  // Edit Password
  const [editTrustPassword, setEditTrustPassword] = useState({
    _id: "",
    TrustName: "",
  })

  // Edit PAssword
  const [errorPass, setErrorPass] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [PasswordEdit, setPasswordEdit] = useState('');
  const [confirmPasswordEdit, setConfirmPasswordEdit] = useState('');
  const handlePasswordEdit = (e, type) => {
    const { name, value } = e.target;
    if (type === 'pass') {
      name === 'Password' ? setPasswordEdit(value) : setConfirmPasswordEdit(value);
      if (name === 'Password' && confirmPasswordEdit && value !== confirmPasswordEdit) {
        setErrorPass(true);
      } else if (name === 'confirmPassword' && PasswordEdit && value !== PasswordEdit) {
        setErrorPass(true);
      } else if (confirmPasswordEdit === "" || PasswordEdit === "") {
        setErrorPass(true);
      } else {
        setErrorPass(false);
      }
    }

    if (type === 'old') {
      setOldPassword(value)
    }
  }


  // Edit trust Password model Variables
  const [visiblePass, setVisiblePass] = React.useState(false);
  const handlerPass = () => {
    setVisiblePass(true)
  };
  const closeHandlerPass = () => {
    setVisiblePass(false)
    setConfirmPasswordEdit("")
    setPasswordEdit("")
  };

  const handlePassEditClick = (index) => {
    handlerPass();
    setEditTrustPassword({
      ...editTrustPassword,
      _id: index?._id,
      TrustName: index.TrustName
    })
  }

  const handleUpdatePassword = () => {
    let encryptedPassword = crypt.encrypt(process.env.REACT_APP_KEY, confirmPasswordEdit);
    let encryptedOldPassword = crypt.encrypt(process.env.REACT_APP_KEY, oldPassword);

    const PasswordData = new FormData();
    PasswordData.append("oldPassword", encryptedOldPassword)
    PasswordData.append("password", encryptedPassword)

    if (!errorPass && confirmPasswordEdit !== "") {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios.put(`${getChangeTrustpassword}/${trustDetail?._id}`, PasswordData)
        .then((res) => {
          closeHandlerPass()
          toast.success(res.data?.message, toastStyle);
        }).catch((err) => {
          toast.error(err.response.data?.message, toastStyle);
        })
    }
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Card className="ProfileCard" container alignItems="center" sx={{ position: "relative", py: 2, px: 2, }} >
          <Grid className="ProfileImage" container spacing={3} alignItems="center" item>
            <Grid className="image" item>
              <MDAvatar src={trustLogo} alt="profile-image" size="xxxl" shadow="sm" />
            </Grid>
            <Grid className="trust-details" item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h2" fontWeight="medium">
                  {trustDetail?.TrustName}
                </MDTypography>
                <MDTypography variant="h4" className="address" color="text" fontWeight="regular">
                  {trustDetail?.TrustAddress}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid className="action" item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <Button variant="contained" className="fillter_btn common-btn" onClick={handlerEdit} startIcon={<ModeEditOutlineOutlinedIcon />} >
                Edit Trust
              </Button><br /><br />
              <Button variant="contained" className="fillter_btn common-btn" onClick={() => handlePassEditClick(trustDetail)} startIcon={<ModeEditOutlineOutlinedIcon />} >
                Edit Password
              </Button>
            </MDBox>
          </Grid>
        </Card>
        <MDBox mb={2} />
        {/* <Header> */}
        <div className="profile">
          <div className="profile-section">
            <div className="field-group">
              <h2>Registration Details</h2>
              <div className="field">
                <label>PAN no. :</label>
                <p>{trustDetail?.TrustPan}</p>
              </div>
              <div className="field">
                <label>GST No. :</label>
                <p>{trustDetail?.TrustGST}</p>
              </div>
              <div className="field">
                <label>section code :</label>
                <p>{trustDetail?.trustSecCode}</p>
              </div>
              <div className="field">
                <label>URN no. :</label>
                <p>{trustDetail?.TrustUrnNo}</p>
              </div>
              <div className="field">
                <label>URN Reg. Date :</label>
                <p>{newformatDate(trustDetail?.TrustUrnRegistrationDate)}</p>
              </div>
            </div>
          </div>
          <div className="profile-section">
            <div className="field-group">
              <h2>Contact Information</h2>
              <div className="field">
                <label>mobile no. :</label>
                <p>{trustDetail?.TrustMobile}</p>
              </div>
              <div className="field">
                <label>Email :</label>
                <p>{trustDetail?.TrustEmail}</p>
              </div>
              <div className="field">
                <label>Website :</label>
                <p>{trustDetail?.TrustWebsite}</p>
              </div>
              <div className="field">
                <label>Fax No.:</label>
                <p>{trustDetail?.TrustFax}</p>
              </div>
            </div>
          </div>
          <div className="profile-section note-section">
            <div className="field">
              {console.log(trustDetail)}
              <h2>Note</h2>
              <p>Trust is registered under Bombay Public Trust Act, 1950 vide <b>Regn. No. {trustDetail?.TrustNote?.TrustRegnNo} PAN NO. <span>{trustDetail?.TrustNote.TrustNotePan}</span></b> I.T (Exemptions) Approval under Form No. <span>{trustDetail?.TrustNote.TrustFormNo}</span> with <b>URN: <span>{trustDetail?.TrustNote.TrustNoteUrn}</span></b> Granted under Section-{trustDetail?.TrustNote?.TrustClauseName} of First proviso to <b>sub-section (5) of <span>{trustDetail?.TrustNote.TrustNoteSection}</span></b> on {newformatDate(trustDetail?.TrustUrnRegistrationDate)}. Valid from <b><span>{trustDetail?.TrustNote?.YearType == 1 ? "A.Y." : ""}</span> <span>{trustDetail?.TrustNote?.YearType == 1 ? trustDetail?.TrustNote.AYStart : newformatDate(trustDetail?.TrustNote.AYStart)}</span> to A.Y. <span>{trustDetail?.TrustNote.AYStop}</span></b></p>

            </div>
          </div>
        </div>

        {/* </Header> */}
        <Footer />
      </DashboardLayout>
      {/* Edit Trust Model */}
      <Modal closeButton blur preventClose aria-labelledby="modal-title" className="model-with-sidebar" width={1100} open={visibleEdit} onClose={closeHandlerEdit} >
        <Modal.Header justify="start">
          <Text id="modal-title" size={24}>
            Edit<Text b size={24}> Trust </Text>
          </Text>
        </Modal.Header>
        <div className="AddDataModel">
          {step !== 5 ?
            <div className="modelSidebat">
              <ul>
                <li><a href="#" value={1} onClick={(e) => handleSidebarStep(e, 1)} className={`step ${step === 1 ? "active" : ""} ${!errorE1 ? "success" : ""} ${errorE1 ? "error" : ""}`}><span className="mobile-only">1</span><span>Step 1 :</span><span>Trust Name and Logo</span></a></li>
                <li><a href="#" value={2} onClick={(e) => handleSidebarStep(e, 2)} className={`step ${step === 2 ? "active" : ""} ${!errorE2 ? "success" : ""} ${errorE2 ? "error" : ""}`}><span className="mobile-only">2</span><span>Step 2 :</span><span>Contact Information</span></a></li>
                <li><a href="#" value={3} onClick={(e) => handleSidebarStep(e, 3)} className={`step ${step === 3 ? "active" : ""} ${!errorE3 ? "success" : ""} ${errorE3 ? "error" : ""}`}><span className="mobile-only">3</span><span>Step 3 :</span><span>Registration Details</span></a></li>
                <li><a href="#" value={4} onClick={(e) => handleSidebarStep(e, 4)} className={`step ${step === 4 ? "active" : ""} ${!errorE4 ? "success" : ""} ${errorE3 ? "error" : ""}`}><span className="mobile-only">4</span><span>Step 4 :</span><span>Trust Note</span></a></li>
              </ul>
            </div> : ""}
          <div className={step === 5 ? "modelContent w-100" : "modelContent"}>
            <Modal.Body>
              <Row>
                {renderFormEdit()}
              </Row>
            </Modal.Body>
            {renderButtonsEdit()}
          </div>
        </div>
        <div className="modelBottom">
          {step === 5 ?
            <Button auto className="common-btn light" color="error" onClick={closeHandlerEdit}>
              Close
            </Button> :
            <Button auto className="common-btn" onClick={handleSubmitEdit}>
              Edit Trust
            </Button>}
        </div>
      </Modal>

      {/* Chamge Trust Password Model */}
      <Modal closeButton blur preventClose aria-labelledby="modal-title" className="AddDataModel" width={600} open={visiblePass} onClose={closeHandlerPass} >
        <Modal.Header justify="start">
          <Text id="modal-title" size={24}>
            Change Password for <Text b size={24}> {editTrustPassword.TrustName} </Text>
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="Step form-row step-4 w-100">
              <Form.Group className="form-control w-100" controlId="Password">
                <Form.Label>Old Password</Form.Label>
                <div className="group">
                  <Form.Control
                    type="text"
                    placeholder="Old Password"
                    name="oldPassword"
                    value={oldPassword}
                    onChange={(e) => handlePasswordEdit(e, "old")}
                  />
                </div>
              </Form.Group>

              <Form.Group className="form-control w-100" controlId="Password">
                <Form.Label>Password</Form.Label>
                <div className="group">
                  <Form.Control
                    type="text"
                    placeholder="Password"
                    name="Password"
                    value={PasswordEdit}
                    onChange={(e) => handlePasswordEdit(e, "pass")}
                  />
                </div>
              </Form.Group>

              <Form.Group className="form-control w-100" controlId="confirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <div className="group">
                  <Form.Control
                    type="text"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    value={confirmPasswordEdit}
                    onChange={(e) => handlePasswordEdit(e, "pass")}
                  />
                </div>
                {errorPass ? <p className="errorMessage">Password Does Not Match</p> : ""}
              </Form.Group>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button auto className="common-btn light" color="error" onClick={closeHandlerPass}>
            cancel
          </Button>
          <Button auto className="common-btn" onClick={handleUpdatePassword}>
            Update Password
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default Overview;
