// @mui material components
import Grid from "@mui/material/Grid";

// Donation Receipt Generator React components
import MDBox from "components/MDBox";

// Donation Receipt Generator React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { getTrustList } from "Api";
import { userToken } from "Token";
import { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, FormControlLabel, IconButton } from "@mui/material";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaUserEdit } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { AiFillEye, AiOutlineOrderedList } from "react-icons/ai";
import { DataGrid } from "@mui/x-data-grid";
import { CiExport } from "react-icons/ci";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;

  const columns = [
    { field: 'srno', headerName: 'ID', width: 90 },
    { field: 'TrustName', headerName: 'Name', width: 350, editable: false, },
    { field: 'totalDonationAmount', headerName: 'Total Donation', width: 350, editable: false, },
    { field: 'formattedDate', headerName: 'Created At', width: 350, editable: false, },
    {
      field: 'action', headerName: 'Action', width: 300, editable: false,
      renderCell: (prevent) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center trust"
            style={{ cursor: "pointer" }}
          >
            <MatView className="ViewDonation" index={prevent.row?._id} />
          </div>
        );
      },
    }
  ];

  const MatView = ({ index }) => {
    return (
      <FormControlLabel
        control={
          <NavLink to={`/admin/trust-list/${index}`} color="secondary" aria-label="add an alarm" className="view-donation-of-trust" >
            <IconButton color="secondary" aria-label="add an alarm" className="view-donation-of-trust" >
              <AiFillEye />
            </IconButton>
          </NavLink>
        }
      />
    );
  };

  const token = userToken()
  const [trustList, setTrustList] = useState([])
  const [totalTrust, setTotalTrust] = useState("")

  // Get All Trust List
  const fetchTrustList = () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.get(`${getTrustList}`)
      .then((res) => {
        setTotalTrust(res.data.result.totalDocs)

        res.data.result.docs.map((ele, i) => {
          ele.id = i + 1;
          ele.srno = (res.data.result.page * 10) + (i + 1) - 10;

          const dateString = ele.createdAt;
          ele.formattedDate = new Date(dateString).toLocaleDateString('en-GB');

        })
        setTrustList(res.data.result.docs)
      })
  }
  useEffect(() => {
    fetchTrustList()
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox mb={1.5} className="font-xl">
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Total Trusts"
                count={totalTrust}
              />
            </MDBox>
          </Grid>
        </Grid>

        <MDBox mb={3} >
          <div className="title_text roles-header dashboard-tital">
            <h4>Recent Trust</h4>
            <div className="button-for-trust-actions">
              <NavLink to="/admin/trust-list" variant="contained" className="fillter_btn common-btn" startIcon={<AiOutlineOrderedList />} >
                View All
              </NavLink>
            </div>
          </div>
          <Box sx={{ height: 580, width: '100%', }} >
            <DataGrid
              rows={trustList}
              columns={columns}
              pageSize={10}
            />
          </Box>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
