
// Donation Receipt Generator React layouts
import Dashboard from "./layouts/dashboard";
import AdminDashboard from "./layouts/AdminDashboard";
import Profile from "./layouts/profile";
import SignIn from "./layouts/authentication/sign-in";
import TrustLogin from "./layouts/TrustLogin/sign-in";
import Trust from "layouts/Trust";
import Admin from "layouts/Admin";
import TrustList from "layouts/AdminTrustList";
import Support from "layouts/support";
import Home from "layouts/home";
import ArchiveDonation from "layouts/ArchiveDonation";
import DeletedDonation from "layouts/DeletedTrust";
import AdminArchiveDonation from "layouts/AdminArchiveDonation";
import AdminTrustProfile from "layouts/AdminTrustProfile";


// @mui icons
import Icon from "@mui/material/Icon";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AdminAuth from "PrivateRoute/AdminAuth";
import TrustAuth from "PrivateRoute/TrustAuth";
import { useState } from "react";
import { useEffect } from "react";
import { BiArchiveIn } from "react-icons/bi"
import { FaReceipt } from "react-icons/fa";
import { MdOutlineFolderDelete } from "react-icons/md";


const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/",
    component: <Home />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/admin/login",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/trust/login",
    component: <TrustLogin />,
  },
  {
    type: "ADMIN",
    name: "Dashboard",
    key: "admindashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/admin/dashboard",
    component: <AdminAuth page={<AdminDashboard />} />,
  },
  {
    type: "ADMIN",
    name: "Trust List",
    key: "admintrust-list",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/admin/trust-list",
    component: <AdminAuth page={<Admin />} />,
  },
  {
    type: "collapse",
    name: "Admin Trust List",
    key: "admintrust-list",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/admin/trust-list/:id",
    component: <AdminAuth page={<TrustList />} />,
  },
  {
    type: "collapse",
    name: "Admin Trust Detail",
    key: "admintrust-list",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/admin/trust-detail/:id",
    component: <AdminAuth page={<AdminTrustProfile />} />,
  },
  {
    type: "collapse",
    name: "Admin Trust List",
    key: "admintrust-list",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/admin/archive/:id",
    component: <AdminAuth page={<AdminArchiveDonation />} />,
  },
  {
    type: "TRUST",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <TrustAuth page={<Dashboard />} />,
  },
  {
    type: "TRUST",
    name: "Donation List",
    key: "donation-list",
    icon: <FaReceipt />,
    route: "/donation-list",
    component: <TrustAuth page={<Trust />} />,
  },
  {
    type: "TRUST",
    name: "Archive List",
    key: "archive",
    icon: <BiArchiveIn />,
    route: "/archive",
    component: <TrustAuth page={<ArchiveDonation />} />,
  },
  {
    type: "TRUST",
    name: "Deleted List",
    key: "deleted",
    icon: <MdOutlineFolderDelete />,
    route: "/deleted",
    component: <TrustAuth page={<DeletedDonation />} />,
  },
  {
    type: "TRUST",
    name: "Profile",
    key: "trustprofile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/trust/profile",
    component: <TrustAuth page={<Profile />} />,
  },
  {
    type: "TRUST",
    name: "Support",
    key: "support",
    icon: <SupportAgentIcon />,
    route: "/support",
    component: <TrustAuth page={<Support />} />,
  },
];

export default routes;
