// react-router-dom components
import { Link, useLocation, useParams } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Donation Receipt Generator React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { userToken } from "Token";
import { getUserType } from "Token";
import { getTrustDetailsPatch } from "Api";
import axios from "axios";
import { useEffect, useState } from "react";
import { getTrustDetails } from "Api";
import { getTrustDonationListAdmin } from "Api";

function Breadcrumbs({ icon, title, route, light }) {
  const routes = route.slice(0, -1);


  // Variables
  const [trustDetail, setTrustDetail] = useState({})
  const [trustName, setTrustName] = useState("")
  const [trustLogo, settrustLogo] = useState("https://i.pravatar.cc/150?u=a042581f4e29026704d")

  const token = userToken();
  const userType = getUserType();

  const [headerHeading, setHeaderHeading] = useState("")

  const location = useLocation();
  const { pathname } = location;

  const trustId = useParams();

  // Get All Donation List
  const fetchdonationList = () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.get(`${getTrustDonationListAdmin}/${trustId.id}`)
      .then((res) => {
        setTrustName(res.data.result.trustName)
      })
  } 


  // Get Trust Details
  const fetchTrustDetails = () => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.patch(getTrustDetailsPatch)
      .then((res) => {
        if (res.data.user.TrustLogo !== "") {
          settrustLogo(`https://${res.data.user.TrustLogo}`)
        }
        setTrustDetail(res.data.user)
      })
  }
  useEffect(() => {
    if(token !== "" && token !== undefined) {
      if (userType === "TRUST") {
        fetchTrustDetails();
      } else if (userType === "ADMIN" && trustId.id !== undefined) {
        fetchdonationList();
        if (pathname === '/admin/dashboard') {
          setHeaderHeading('Dashboard');
        } else if (pathname === '/admin/trust-list') {
          setHeaderHeading('Trust List');
        } else if (pathname === `/admin/trust-list/${trustId?.id}`) {
          setHeaderHeading(trustName);
        } else if (pathname === `/admin/archive/${trustId?.id}`) {
          setHeaderHeading(trustName);
        } else {
          setHeaderHeading('Unknown Page');
        }
      }
    }
  }, [userType, pathname, trustId?.id, trustName, token]);

  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MDTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h4"
        color={light ? "white" : "dark"}
        noWrap
      >
        {userType === "ADMIN" ?
          headerHeading : userType === "TRUST" ?
            trustDetail.TrustName : ""
        }
      </MDTypography>
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
