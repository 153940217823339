import { useState, useEffect, useMemo } from "react";
import './App.css';

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Donation Receipt Generator React components
import MDBox from "components/MDBox";

// Donation Receipt Generator React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Donation Receipt Generator React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Donation Receipt Generator React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Donation Receipt Generator React routes
import routes from "routes";

// Donation Receipt Generator React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // get User Type
  const [userType, setUserType] = useState("")
  useEffect(() => {
    if (localStorage.getItem("type") != null) {
      setUserType(localStorage.getItem('type'));
    } else if (sessionStorage.getItem('type') != null) {
      setUserType(sessionStorage.getItem('type'))
    }
  }, [])

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const [isHomePage, setIsHomePage] = useState(true);

  useEffect(() => {
    const checkHomePage = () => {
      setIsHomePage(pathname === '/');
    };

    checkHomePage();
    window.addEventListener('popstate', checkHomePage);

    return () => {
      window.removeEventListener('popstate', checkHomePage);
    };

  }, []);


  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }, []
  )

  return !loading ?
    (direction === "rtl" ? (
      <CacheProvider value={rtlCache}>
        <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              {/* {isHomePage ? "" : */}
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName="Trust"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              {/* // } */}
              <Configurator />
              {/* {configsButton} */}
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            {getRoutes(routes)}
            {
              userType === "TRUST" ?
                <Route path="*" element={<Navigate to="/dashboard" />} /> :
                userType === "ADMIN" ?
                  <Route path="*" element={<Navigate to="/admin/dashboard" />} /> :
                  <>
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                  </>
            }
            <Route path="/admin" element={<Navigate to="/admin/dashboard" />} />
            <Route path="/trust-list" element={<Navigate to="/admin/trust-list" />} />
          </Routes>
        </ThemeProvider>
      </CacheProvider>
    ) : (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            {/* {isHomePage ? "" : */}
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Donation Receipt Generator"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            {/* } */}
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          {
            userType === "TRUST" ?
              <Route path="*" element={<Navigate to="/dashboard" />} /> :
              userType === "ADMIN" ?
                <Route path="*" element={<Navigate to="/admin/dashboard" />} /> :
                <>
                  <Route path="/" element={<Navigate to="/dashboard" />} />
                  <Route path="/admin" element={<Navigate to="/admin/dashboard" />} />
                </>
          }
        </Routes>
      </ThemeProvider>
    )) : (
      <div className="preloader">
        <div className="loader"></div>
      </div>
    )
}
