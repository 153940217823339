import { getTrustList } from 'Api';
import axios from 'axios';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function AdminAuth({ page }) {
    const navigate = useNavigate();

    let token;
    if (localStorage.getItem("adminToken") != null) {
        token = localStorage.getItem('adminToken');
    } else if (sessionStorage.getItem('adminToken') != null) {
        token = sessionStorage.getItem('adminToken')
    }

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        axios.get(getTrustList)
            .then((res) => {

            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    sessionStorage.clear();
                    navigate("/admin/login");
                }
            });
    }, [token])

    return token ? page : navigate('/admin/login');
}

export default AdminAuth